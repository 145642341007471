.team-container
  .card-block
    padding: 10px
    min-height: 160px

  .grid-item
    img.product-logo 
      height: 20px  
    float: left
    padding: 0
    background: #ffffff
    margin-bottom: 20px
    width: calc((100% - 50px) / 3)
    @media (max-width: 768px)
      width: calc((100% - 40px) / 2)
    @media (max-width: 600px)
      width: calc((100% - 20px))  

    .card-block
      p.card-text-fixed-height  
        height: 12rem
        overflow: hidden

      a.team-member-email
        text-decoration: none  

    .text-more-info
      position: absolute
      right: 10px
      bottom: 10px

section.profile-card
  .image-wrapper
    padding: 0
    img 
      height: auto
      width: 100% 
  .profile-wrapper  
    background-color: #ffffff
    margin-left: 20px
    margin-right: -21px
    @include media-breakpoint-down(sm)
      margin-left: unset
      margin-right: unset
  .profile-wrapper.unset-left-margin    
    margin-left: unset
    margin-right: unset

.team-add-image-copyright
  position: relative 
  .image-copyright    
    position: absolute
    transform: rotate(-90deg) translate(100%)
    right: 1em
    transform-origin: right
    font-size: .8em
    bottom: 0
    color: $white

