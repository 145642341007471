.jp-contact-con
  display: grid
  gap: 3rem
  
.jp-contact-info
  padding: 3rem
  background-color: $red
  color: $white
  display: flex
  flex-direction: column
  gap: 2rem
  height: fit-content
  width: 100%

.jp-contact-info p
  margin: 0

.jp-contact-form input,
.jp-contact-form textarea
  background-color: $light-grey


@media (min-width: $md)
  .jp-contact-con
    grid-template-columns: 3fr 1.33fr
  