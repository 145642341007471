.btn
  @include media-breakpoint-down(xs)
    white-space: normal
  +font-size(1.6)
  border-radius: 0
  border: 0
  outline: 0
  padding: .35em .8em
  background-color: transparent
  text-decoration: none

  &.btn-lg
    font-size: 1.2em
    padding: .5em 1em

  &.btn-default
    +font-size(1.4)
    color: $default
    text-transform: uppercase
    padding: .25em.5em
    margin: .5em -.25em
    letter-spacing: .1em
    &:hover, &:active, &:focus, &:active:focus
      color: $lightred
    @include media-breakpoint-down(md)
      +font-size(1.2)
    @include media-breakpoint-down(xs)
      +font-size(1.4)

  &.btn-default-neg
    +font-size(1.4)
    color: $white
    padding: .25em.5em
    margin: .5em -.25em
    text-transform: uppercase
    letter-spacing: .1em
    &:hover, &:active, &:focus, &:active:focus
      color: $lightgrey
    @include media-breakpoint-down(md)
      +font-size(1.2)
    @include media-breakpoint-down(xs)
      +font-size(1.4)

  &.btn-primary
    color: $default
    padding: 0
    &:hover, &:active, &:focus, &:active:focus
      color: $lightred
      background-color: transparent

  &.btn-secondary
    color: $white
    background-color: $red
    &:hover, &:active, &:focus, &:active:focus
      background-color: lighten($lightred, 10%)
      color: $white

  &.btn-tertiary
    color: $white
    background-color: $lightred
    &:hover, &:active, &:focus, &:active:focus
      background-color: lighten($lightred, 10%)
      color: $white
  
  &.btn-success
    color: $white
    background-color: $black
    &:hover, &:active, &:focus, &:active:focus
      background-color: lighten($black, 10%)
      color: $white

  &:focus
    box-shadow: none
  &:active
    box-shadow: 0 0 0 0.1em rgba($red, .9) !important

  &.btn-video
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    -ms-transform: translate(-50%, -50%)
    cursor: pointer
