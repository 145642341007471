.slider
  color: $white
  margin-bottom: 0
  opacity: 1

  @include media-breakpoint-down(lg)
    .default-slide-3
      opacity: 0.85

  div.default-slide-3
    @include media-breakpoint-down(lg) 
      margin-bottom: -15rem
      background-color: #E2001A
      color: #ffffff
      padding: 2rem
      min-height: 20rem
      margin-left: 15px
      margin-right: 15px
      h1, h2 
        font-size: 25px
      a 
        color: #ffffff
  .row
    @include media-breakpoint-down(lg) 
      margin-left: 0px
      margin-right: 0px
    position: relative
    .textbox
      width: 100%
      opacity: 0.85
      background-color: #E2001A
      color: #ffffff
      position: absolute
      min-height: 10rem
      padding: 3rem
      &.protrude-textbox
        margin-bottom: -6rem
      @include media-breakpoint-down(lg)
        margin-bottom: -19rem
      .box-logo
        width: auto
        padding-bottom: 1.5rem
        max-width: 90%
        filter: brightness(0) invert(1)
      @include media-breakpoint-down(lg)
        position: inherit
        padding: 2rem
        margin-bottom: -19rem
      a, .btn.btn-primary        
        color: #fff
      .box-label
        position: absolute
        right: 0
        background-color: #444444
        padding: .5rem
        color: white
        top: 1rem  
        font-size: 1.4rem
    .textbox-new
      .col-xl-6
        max-width: 100%  
        padding: 2rem
      @include media-breakpoint-down(lg)         
        .col-xl-6
          background-color: #E2001A
          color: #ffffff
          max-width: 100%
          h1, h2 
            font-size: 25px
            color: #ffffff
          a 
            color: #ffffff  
        width: 100%
        opacity: 0.85
        color: #ffffff
        position: absolute
        min-height: 20rem
        padding: 3rem
        .box-logo
          width: 6rem
          padding-bottom: 1.5rem
        @include media-breakpoint-down(lg)
          position: inherit
          padding: 2rem
          margin-bottom: -17rem
        a, .btn.btn-primary        
          color: #fff
        .box-label
          position: absolute
          right: 0
          background-color: #444444
          padding: .5rem
          color: white
          top: 1rem  
          font-size: 1.4rem  
    h3
      margin-bottom: $margin * 2
      @include media-breakpoint-down(lg)
        margin-bottom: 0
  .owl-carousel
    z-index: 0
    .owl-stage
      display: flex
      min-height: 100%      
      .owl-item
        display: flex
        min-height: 100%
        .default-slide-2
          background-position: 0 100%!important
          background-size: contain !important
          background-repeat: no-repeat
          &.overlay
            &:before
              content: ' '
              position: absolute
              left: 0
              right: 0
              bottom: 0
              top: 0
              background-image: unset !important 

        .item
          margin-bottom: 3rem
          min-height: 60vh
          display: flex
          align-items: flex-end
          position: relative
          width: 100%
          background:
            color: $red
            size: cover
          &.protrude-box 
            margin-bottom: 6rem  
          @include media-breakpoint-down(lg)
            min-height: 40vh
            margin:
              bottom: 12rem
          @include media-breakpoint-down(xs)
            margin:
              bottom: 22rem
          @media (max-width: 333px)
            margin:
              bottom: 45rem
          .container
            @include media-breakpoint-down(lg)
              padding: 0
            //   max-width: 100%
            //   width: 100%
            .col-xl-6
              @include media-breakpoint-down(lg)
                height: 200px
                
          &.top
            background-position: top
          &.center
            background-position: center
          &.bottom
            background-position: bottom
          &.overlay
            &:before
              content: ' '
              position: absolute
              left: 0
              right: 0
              bottom: 0
              top: 0
              background-image: linear-gradient(to bottom,rgba(0,0,0,.1),rgba($default,.5))
              mix-blend-mode: normal
          &.finderslide
            position: relative
            &:before
              content: ' '
              position: absolute
              left: 0
              top: 0
              right: 0
              bottom: 0
              background-image: radial-gradient(circle, rgba(0,0,0,0.0), rgba(0,0,0,0.8) 180%)

            align-items: center
            .container, .row
              height: 100%
              .img
                padding-bottom: 0
                img
                  @include media-breakpoint-up(sm)
                    bottom: -3rem
                    max-height: 100%
                    position: absolute
                    width: auto
                  @include media-breakpoint-down(lg)
                    margin-top: 20px
                    margin-bottom: 20px
              .text
                align-self: center
                div
                  font-size: 3rem
            &.light, &.light a.btn
              color: $white
            &.dark, &.dark a.btn
              color: $default
          .btn
            @include media-breakpoint-down(lg)
              text-align: left

    .owl-stage-outer
      //overflow: visible // Required for overflowing .textbox
    .owl-nav
      padding: 0
      background-color: yellowgreen
      [class*=owl-]:hover
        background: transparent
      @include media-breakpoint-down(lg)
        display: none
      .owl-prev, .owl-next
        position: absolute
        top: 50%
        transform: translateY(-50%)
        display: block
        height: 40px
        width: 40px
      .owl-prev
        right: 96%
        background-image: url('../../../img/pfeil-links.svg')
        background-color: transparent
        @include media-breakpoint-down(lg)
          right: 93%
        @include media-breakpoint-down(lg)
          display: none
      .owl-next
        left: 96%
        background-image: url('../../../img/pfeil-rechts.svg')
        background-color: transparent
        @include media-breakpoint-down(lg)
          left: 93%
        @include media-breakpoint-down(lg)
          display: none
    .owl-dots
      position: absolute
      bottom: 9rem
      left: 50%
      transform: translateX(-50%)
      @include media-breakpoint-down(lg)
        display: none
      &.disabled
        display: block
        @include media-breakpoint-down(lg)
          display: none
      .owl-dot
        &.active span
          background-color: $white
        &:focus
          outline: 0
        span
          border-radius: 0
          background-color: transparent
          border: 1px solid $white
          width: 1rem
          height: 1rem
          margin: .5rem
          @include media-breakpoint-down(lg)
            margin: 2rem 1rem
          &:hover
            background-color: $white
  &.highlighted    
    img.catlogo
      filter: invert(100%) brightness(1000%)
      max-width: 200px
