#news
  display: grid
  gap: 2rem
  margin-top: 6rem

@media (min-width: $sm)
  #news
    grid-template-columns: 1fr 1fr

@media (min-width: $md)
  #news
    grid-template-columns: 1fr 1fr 1fr

@media (min-width: $lg)
  #news
    grid-template-columns: 1fr 1fr 1fr 1fr