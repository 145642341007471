.testimonial-card
  position: relative

.testimonial-image
  display: block
  .fill-picture 
    position: relative
    aspect-ratio: 1 / 1.1
    max-width: 340px
    margin-bottom: -4rem

.testimonial-content-con
  background-color: $light-grey
  padding: 2rem 3rem
  display: flex
  flex-direction: column
  gap: 2rem
  padding-top: 6rem

.testimonial-testimonial
  font-weight: 600

.testimonial-employee
  display: flex
  flex-direction: column

.testimonial-employee-name
  font-weight: 600

.testimonials-prev-btn,
.testimonials-next-btn
  display: none

@media (min-width: $md)
  .testimonial-image
    display: initial
    position: absolute
    left: 0
    top: 0
    bottom: 0
    margin: auto
    z-index: 33
    width: calc(6% + 4rem)
    max-height: 80%
    aspect-ratio: 1 / 1.1

  .testimonial-content-con
    position: relative
    margin-left: auto
    width: 94%
    padding: 3rem 6rem

  .testimonials-prev-btn,
  .testimonials-next-btn
    display: initial
    width: fit-content

  .testimonials-prev-btn
    left: -5rem
    transform: rotate(180deg)

  .testimonials-next-btn
    right: -5rem