.cs-con
  display: flex
  flex-direction: column
  gap: 2rem

.cs-content-con
  display: flex
  flex-direction: column
  gap: 3rem
  margin-bottom: 3rem
  width: 100%

.cs-title
  margin: 0

.cs-freetext ul
  list-style: disc

.cs-social-icons
  display: flex
  gap: 2rem

.cs-image-con
  display: flex
  flex-direction: column
  gap: 1rem

.cs-image-con img
  object-fit: contain
  max-width: 100%
  height: 100%

.cs-image-con p
  margin: 0

@media (min-width: $md)
  .cs-con
    flex-direction: row
    flex-flow: var(--flexFlow)
    gap: 4rem

  .cs-content-con
    margin-bottom: 0

  .cs-title
    margin-bottom: 1rem
