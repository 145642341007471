@font-face {
  font-family: "Graphik-Web";
  src: url(../fonts/Graphik-Bold-Web.eot) format("embedded-opentype"),url(../fonts/Graphik-Bold-Web.woff) format("woff"),url(../fonts/Graphik-Bold-Web.woff2) format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Web";
  src: url(../fonts/Graphik-BoldItalic-Web.eot) format("embedded-opentype"),url(../fonts/Graphik-BoldItalic-Web.woff) format("woff"),url(../fonts/Graphik-BoldItalic-Web.woff2) format("woff2");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Web";
  src: url(../fonts/Graphik-RegularItalic-Web.eot) format("embedded-opentype"),url(../fonts/Graphik-RegularItalic-Web.woff) format("woff"),url(../fonts/Graphik-RegularItalic-Web.woff2) format("woff2");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Web";
  src: url(../fonts/Graphik-Regular-Web.eot) format("embedded-opentype"),url(../fonts/Graphik-Regular-Web.woff) format("woff"),url(../fonts/Graphik-Regular-Web.woff2) format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Plantin-Web";
  src: url(../fonts/360D0A_0_0.eot) format("embedded-opentype"),url(../fonts/360D0A_0_0.woff) format("woff"),url(../fonts/360D0A_0_0.woff2) format("woff2"),url(../fonts/360D0A_0_0.ttf) format("truetype");
  font-weight: 400;
  font-display: swap;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Graphik-Web",sans-serif;
  color: #3f3f3f;
  -webkit-font-smoothing: antialiased;
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 1.45;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  font-weight: 700;
  margin-bottom: 1.2rem;
  font-size: 20px;
  font-size: 2rem;
}

h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1,
h2 {
  font-size: 52px;
  font-size: 5.2rem;
  line-height: 1.1667;
  margin: 3rem 0 2rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

h1.apply-h2-style {
  font-size: 40px;
  font-size: 4rem;
}

@media (max-width: 991px) {
  h1.apply-h2-style {
    font-size: 30px;
    font-size: 3rem;
    margin: 2rem 0 1rem;
  }
}

h2 {
  font-size: 40px;
  font-size: 4rem;
}

@media (max-width: 991px) {
  h2 {
    font-size: 30px;
    font-size: 3rem;
    margin: 2rem 0 1rem;
  }
}

h3 {
  font-size: 26px;
  font-size: 2.6rem;
}

@media (max-width: 991px) {
  h3 {
    font-size: 20px;
    font-size: 2rem;
  }
}

h3.line {
  display: flex;
  position: relative;
  z-index: 1;
}

h3.line:after {
  border-top: 2px solid #e64155;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

@media (max-width: 767.98px) {
  h3.line:after {
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    width: 100%;
    position: relative;
    display: block;
    margin-top: 1rem;
  }
}

h3.line span {
  background: #f3f3f3;
  float: none !important;
  font-size: inherit !important;
  padding: 0 15px 0 0 !important;
}

h4 {
  font-size: 20px;
  font-size: 2rem;
}

h4.line {
  display: flex;
  position: relative;
  z-index: 1;
}

h4.line:after {
  border-top: 1px solid #e64155;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
}

@media (max-width: 767.98px) {
  h4.line:after {
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    width: 100%;
    position: relative;
    display: block;
    margin-top: 1rem;
  }
}

h4.line span {
  background: #f3f3f3;
  float: none !important;
  font-size: inherit !important;
  padding: 0 15px 0 0 !important;
}

h5 {
  font-size: 20px;
  font-size: 2rem;
}

h6 {
  font-size: 20px;
  font-size: 2rem;
}

q {
  font-size: 36px;
  font-size: 3.6rem;
  line-height: 1.2;
  font-weight: 600;
}

cite {
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: 400;
}

a {
  color: #e2001a;
  text-decoration: underline;
}

a:hover {
  color: #e64155;
}

.lead {
  font-size: 1.2em;
  font-weight: 400;
}

.spacey {
  letter-spacing: .1em;
}

.card {
  display: inline-block;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@media (max-width: 767.98px) {
  .help-content h1 {
    font-size: 30px;
    font-size: 3rem;
  }
}

#content404 {
  background-color: #e2001a;
}

#content404 a {
  color: #fff;
}

#content404 p {
  margin: 25px auto;
}

#content404 p img {
  max-width: 100%;
}

#content404 p:first-of-type {
  margin-top: 50px;
}

#content404 p:last-of-type {
  margin-bottom: 50px;
}

html {
  min-height: 100%;
}

html body {
  min-height: 100%;
  background-color: #f3f3f3;
  margin: 2rem;
}

@media (max-width: 991.98px) {
  html body {
    margin: 1rem;
  }
}

html body section {
  margin-bottom: 3rem;
}

#wrap-main {
  min-height: 100%;
}

main {
  overflow: auto;
}

.help-content main {
  overflow: unset;
}

body:before {
  content: "";
  height: 100%;
  float: left;
  width: 0;
  margin-top: -32767px;
}

#help-body {
  margin-bottom: 0px;
}

.slider {
  margin-bottom: 8rem;
  color: #fff;
}

.slider .boxslide picture {
  position: absolute;
}

.slider .header-image-copyright {
  position: absolute;
  transform: rotate(-90deg) translate(100%, 0%);
  right: 1em;
  transform-origin: right;
  font-size: .8em;
  bottom: 0;
}

@media (max-width: 991.98px) {
  .slider .header-image-copyright {
    bottom: 10rem;
  }
}

.slider .row {
  position: relative;
}

.slider .row .textbox {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  color: #3f3f3f;
  padding: 3rem;
}

@media (max-width: 991.98px) {
  .slider .row .textbox {
    position: inherit;
    padding: 2rem;
  }
}

.slider .row h3 {
  margin-bottom: 4rem;
}

@media (max-width: 991.98px) {
  .slider .row h3 {
    margin-bottom: 0;
  }
}

.slider .owl-carousel {
  z-index: 0;
}

.slider .owl-carousel .owl-stage {
  display: block !important;
  height: auto !important;
  height: 100%;
  min-height: 100%;
}

.slider .owl-carousel .owl-stage .owl-item {
  display: flex;
  height: auto !important;
  height: 100%;
  min-height: 100%;
}

.slider .owl-carousel .owl-stage .owl-item .item {
  margin-bottom: 4rem;
  min-height: 30vw;
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
}

.slider .owl-carousel .owl-stage .owl-item .item.bg-color {
  background-color: #e64155;
}

.slider .owl-carousel .owl-stage .owl-item .finderslide .header-image-copyright {
  position: absolute;
  transform: rotate(-90deg) translate(100%, 0%);
  right: 15rem;
  transform-origin: right;
}

@media (max-width: 991.98px) {
  .slider .owl-carousel .owl-stage .owl-item .finderslide .header-image-copyright {
    bottom: 10rem;
    right: 1.5rem;
  }
}

.slider .owl-carousel .owl-stage .owl-item .finderslide.item {
  align-items: center;
}

.slider .owl-carousel .owl-stage .owl-item .finderslide.item .container,
.slider .owl-carousel .owl-stage .owl-item .finderslide.item .row {
  height: 100%;
}

.slider .owl-carousel .owl-stage .owl-item .finderslide.item .container .img,
.slider .owl-carousel .owl-stage .owl-item .finderslide.item .row .img {
  padding-bottom: 0;
}

@media (min-width: 576px) {
  .slider .owl-carousel .owl-stage .owl-item .finderslide.item .container .img img,
  .slider .owl-carousel .owl-stage .owl-item .finderslide.item .row .img img {
    bottom: -25px;
    max-height: 100%;
    position: absolute;
    width: auto;
  }
}

@media (max-width: 575.98px) {
  .slider .owl-carousel .owl-stage .owl-item .finderslide.item .container .img img,
  .slider .owl-carousel .owl-stage .owl-item .finderslide.item .row .img img {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.slider .owl-carousel .owl-stage .owl-item .finderslide.item .container .text,
.slider .owl-carousel .owl-stage .owl-item .finderslide.item .row .text {
  align-self: center;
}

.slider .owl-carousel .owl-nav {
  margin: -3rem;
  padding: 0;
  background-color: #9acd32;
}

@media (max-width: 991.98px) {
  .slider .owl-carousel .owl-nav {
    display: none;
  }
}

.slider .owl-carousel .owl-nav .owl-prev,
.slider .owl-carousel .owl-nav .owl-next {
  margin-top: -2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  height: 40px;
  width: 40px;
}

.slider .owl-carousel .owl-nav .owl-prev {
  right: 96%;
  background-image: url("../img/pfeil-links.svg");
  background-color: rgba(0,0,0,0);
}

.slider .owl-carousel .owl-nav .owl-next {
  left: 96%;
  background-image: url("../img/pfeil-rechts.svg");
  background-color: rgba(0,0,0,0);
}

.slider .owl-carousel .owl-dot.active span {
  background-color: #fff;
}

.slider .owl-carousel .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

@media (max-width: 767.98px) {
  .slider .owl-carousel .owl-dot span {
    margin: 2rem 1rem;
  }
}

.slider .owl-carousel .owl-dot span:hover {
  background-color: #fff;
}

@media (max-width: 991.98px) {
  .slider .owl-carousel .owl-dots {
    display: none;
  }
}

.slider .owl-carousel .owl-dots.disabled {
  display: block;
}

@media (max-width: 991.98px) {
  .slider .owl-carousel .owl-dots.disabled {
    display: none;
  }
}

#header2 {
  color: #fff;
  background-color: rgba(0,0,0,0);
}

#header2 .owl-carousel {
  z-index: 0;
}

#header2 .owl-carousel .item {
  align-items: stretch;
  margin-bottom: 4rem;
}

#header2 .owl-carousel .item h1,
#header2 .owl-carousel .item h2 {
  font-size: 40px;
  font-size: 4rem;
  padding-right: 8rem;
}

@media (max-width: 991.98px) {
  #header2 .owl-carousel .item h1,
  #header2 .owl-carousel .item h2 {
    font-size: 30px;
    font-size: 3rem;
    text-shadow: 0 4px 2px rgba(0,0,0,.1);
  }
}

#header2 .owl-carousel .box-alert {
  background-color: #e2001a;
  width: 35%;
  float: left;
  padding: 6rem 8rem;
  position: relative;
}

#header2 .owl-carousel .box-alert .alert-message {
  position: absolute;
  bottom: 6rem;
}

#header2 .owl-carousel .box-alert .alert-message .fa-exclamation-square {
  font-size: 60px;
  font-size: 6rem;
  margin-bottom: 2rem;
}

#header2 .owl-carousel .box-info {
  background-color: #949494;
  width: 65%;
  float: right;
  padding: 6rem 8rem;
  position: relative;
}

#header2 .owl-carousel .box-info .box-message {
  position: absolute;
  bottom: 0;
}

#header2 .owl-carousel .box-info .box-message .row {
  position: relative;
  padding-bottom: 2rem;
}

#header2 .owl-carousel .box-info .box-message .row .textbox {
  background-color: #fff;
  color: #3f3f3f;
  padding: 2.4rem;
  position: absolute;
  bottom: -2rem;
  right: 8rem;
}

@media (max-width: 991.98px) {
  #header2 .owl-carousel .box-info .box-message .row .textbox {
    position: inherit;
    padding: 2rem;
    margin-bottom: -6rem;
  }
}

#header2 .owl-carousel .box-info .box-message .row .textbox h3 {
  margin-bottom: 0;
}

#header2 .owl-carousel .box-info .box-message .row .textbox .box-label {
  position: absolute;
  right: 0;
  background-color: #444;
  padding: .2rem;
  color: #fff;
  top: 1rem;
  font-size: 1.4rem;
}

#header2 .owl-carousel .owl-nav {
  display: inherit;
  margin: -3rem;
  padding: 0;
}

@media (max-width: 991.98px) {
  #header2 .owl-carousel .owl-nav {
    display: none;
  }
}

#header2 .owl-carousel .owl-nav .owl-prev,
#header2 .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  height: 40px;
}

#header2 .owl-carousel .owl-nav .owl-prev {
  right: 96%;
}

#header2 .owl-carousel .owl-nav .owl-next {
  left: 96%;
}

#header2 .owl-carousel .owl-dot.active span {
  background-color: #fff;
}

#header2 .owl-carousel .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

#header2 .owl-carousel .owl-dot span:hover {
  background-color: #fff;
}

@media (max-width: 767.98px) {
  #header2 .owl-carousel .owl-dots {
    position: static;
    display: none;
  }
}

#header2 .owl-carousel .owl-dots.disabled {
  display: block;
}

@media (max-width: 991.98px) {
  #header2 .owl-carousel .owl-dots.disabled {
    display: none;
  }
}

#header3 {
  color: #fff;
  background-color: rgba(0,0,0,0);
}

#header3 .owl-carousel {
  z-index: 0;
}

#header3 .owl-carousel .item {
  align-items: stretch;
}

#header3 .owl-carousel .item h1,
#header3 .owl-carousel .item h2 {
  font-size: 40px;
  font-size: 4rem;
  padding-right: 8rem;
}

@media (max-width: 991.98px) {
  #header3 .owl-carousel .item h1,
  #header3 .owl-carousel .item h2 {
    font-size: 30px;
    font-size: 3rem;
  }
}

#header3 .owl-carousel .item .box-left {
  background-color: #3f3f3f;
  width: 50%;
  float: left;
  padding: 6rem 8rem;
  position: relative;
}

@media (max-width: 991.98px) {
  #header3 .owl-carousel .item .box-left {
    padding: 4rem;
  }
}

@media (max-width: 575.98px) {
  #header3 .owl-carousel .item .box-left {
    padding: 2rem;
  }
}

#header3 .owl-carousel .item .box-left .box-content {
  position: absolute;
  bottom: 6rem;
}

@media (max-width: 575.98px) {
  #header3 .owl-carousel .item .box-left .box-content {
    bottom: 0;
  }
}

@media (max-width: 575.98px) {
  #header3 .owl-carousel .item .box-left .box-content h1,
  #header3 .owl-carousel .item .box-left .box-content h2 {
    font-size: 20px;
    font-size: 2rem;
  }
}

#header3 .owl-carousel .item .box-right {
  background-color: #949494;
  width: 50%;
  float: right;
  padding: 6rem 8rem;
  position: relative;
}

@media (max-width: 991.98px) {
  #header3 .owl-carousel .item .box-right {
    padding: 4rem;
  }
}

@media (max-width: 575.98px) {
  #header3 .owl-carousel .item .box-right {
    padding: 2rem;
  }
}

#header3 .owl-carousel .item .box-right .box-content {
  position: absolute;
  bottom: 6rem;
}

@media (max-width: 575.98px) {
  #header3 .owl-carousel .item .box-right .box-content {
    bottom: 0;
  }
}

@media (max-width: 575.98px) {
  #header3 .owl-carousel .item .box-right .box-content h1,
  #header3 .owl-carousel .item .box-right .box-content h2 {
    font-size: 20px;
    font-size: 2rem;
  }
}

#header3 .owl-carousel .owl-stage-outer {
  overflow: hidden;
}

#header3 .owl-carousel .owl-nav {
  display: inherit;
  margin: -3rem;
  padding: 0;
}

@media (max-width: 991.98px) {
  #header3 .owl-carousel .owl-nav {
    display: none;
  }
}

#header3 .owl-carousel .owl-nav .owl-prev,
#header3 .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  height: 40px;
}

#header3 .owl-carousel .owl-nav .owl-prev {
  right: 96%;
}

#header3 .owl-carousel .owl-nav .owl-next {
  left: 96%;
}

#header3 .owl-carousel .owl-dot.active span {
  background-color: #fff;
}

#header3 .owl-carousel .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

#header3 .owl-carousel .owl-dot span:hover {
  background-color: #fff;
}

@media (max-width: 767.98px) {
  #header3 .owl-carousel .owl-dots {
    position: static;
    display: none;
  }
}

#header3 .owl-carousel .owl-dots.disabled {
  display: block;
}

@media (max-width: 991.98px) {
  #header3 .owl-carousel .owl-dots.disabled {
    display: none;
  }
}

#header4 a {
  color: #3f3f3f;
  text-decoration: none;
}

#header4 a .card {
  border-radius: 0;
  position: relative;
  border: none;
}

#header4 a img {
  height: 100%;
  width: 100%;
}

#header4 a .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  margin: 2rem 0;
  color: #949494;
}

#header4 a .card-footer .date {
  float: left;
}

#header4 a .card-footer .comments {
  float: right;
}

@media (max-width: 991.98px) {
  #header4 a .card-footer .comments {
    float: left;
    margin-right: 1rem;
  }
}

#header4 a .badge {
  position: absolute;
  top: -32px;
  right: 0;
  padding: 1rem;
  border-radius: 0;
}

#header4 a .badge.badge-primary {
  background-color: #e2001a;
  text-transform: uppercase;
  font-weight: 500;
}

.events-box {
  background-color: #545454;
  color: #fff;
  padding: 40px;
  width: 320px;
}

.events-box h4 {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
}

.events-box h4:first-of-type:before {
  content: url(../img/seminare.svg);
  margin-right: 10px;
}

.events-box h4:last-of-type:before {
  content: url(../img/webinare.svg);
  margin-right: 10px;
}

.events-box .event {
  border-bottom: 1px solid #fff;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.events-box .event:last-of-type {
  border: none;
}

.events-box .event h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 0;
}

.events-box .event h5 span {
  font-size: 12px;
  line-height: 16px;
}

#header1 {
  min-height: 60vh;
}

#header1 .item {
  min-height: 60vh;
}

#header1 picture {
  width: 100%;
  height: 100%;
  display: flex;
}

#header1 picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
}

body footer {
  background-color: #3f3f3f;
  padding: 4rem;
  color: #fff;
  position: relative;
  margin-bottom: 20px;
  clear: both;
}

body footer p.footer-p {
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  body footer {
    height: auto;
    padding: 2rem;
    padding-top: 4rem;
  }
}

body footer .row {
  position: relative;
}

body footer .row .brand-text {
  text-transform: uppercase;
  font-weight: 900;
}

body footer .row img {
  display: inline-block;
  height: 1.4em;
  vertical-align: top;
}

body footer nav.more-brands {
  position: relative;
  padding: 1.6rem;
  background: #e2001a;
  margin-top: 1em;
}

body footer nav.more-brands img {
  filter: brightness(0) invert(1);
  max-height: 1.5rem;
}

@media (max-width: 991.98px) {
  body footer nav.more-brands ul {
    display: flex;
    flex-direction: column;
  }

  body footer nav.more-brands ul .card-img-top {
    width: auto;
  }
}

@media (max-width: 767.98px) {
  body footer nav.more-brands li {
    display: block;
    width: 100%;
  }
}

body footer nav.pre-footer {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

@media (max-width: 767.98px) {
  body footer nav.pre-footer {
    margin-bottom: 1rem;
  }
}

body footer nav.footer-nav {
  margin-top: 1em;
  text-align: center;
}

@media (min-width: 768px) {
  body footer nav.footer-nav {
    margin-top: 0;
    text-align: right;
  }

  body footer nav.footer-nav ul.inline-nav li:last-of-type {
    padding-right: 0;
  }
}

body footer nav.footer-nav ul li a,
body footer nav.more-brands ul li a {
  color: rgba(255,255,255,.75);
  font-weight: 500;
  text-decoration: none;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
}

body footer nav.footer-nav ul li a:hover,
body footer nav.more-brands ul li a:hover {
  text-decoration: none;
  color: #fff;
}

body footer nav.footer-nav ul.inline-nav li,
body footer nav.more-brands ul.inline-nav li {
  padding: .5rem 1rem;
  display: inline-block;
}

@media (max-width: 991.98px) {
  body footer .copy {
    text-align: left;
  }
}

@media (max-width: 767.98px) {
  body footer .copy {
    text-align: center;
  }
}

body footer .social {
  margin-top: 1rem;
}

body footer .social .icons {
  float: right;
  display: flex;
}

@media (max-width: 991.98px) {
  body footer .social .icons.text-right {
    text-align: center !important;
  }

  body footer .social .icons ul.inline-nav li {
    padding: .5em;
  }
}

body footer .social a {
  color: #fff;
  text-decoration: none;
}

body footer .social a:hover {
  color: rgba(255,255,255,.75);
}

body footer .pre-footer li {
  line-height: 2em;
  display: flex;
  align-items: center;
}

body footer .pre-footer li i {
  padding-right: 1em;
}

body footer .pre-footer a {
  line-height: 2em;
  text-decoration: none;
  color: #fff;
}

body footer .pre-footer a:hover {
  color: rgba(255,255,255,.75);
}

@media (max-width: 991.98px) {
  body footer .pre-footer>div {
    margin-bottom: 2rem;
  }
}

@media (max-width: 991.98px) {
  body footer .language-switch {
    display: none;
  }
}

body footer .language-switch li {
  color: rgba(255,255,255,.5);
  padding: 0;
}

body footer .language-switch a li {
  color: #fff;
}

body footer .language-switch a.active li,
body footer .language-switch a:hover li {
  color: rgba(255,255,255,.5);
}

.icon-list .icon-container {
  text-align: center;
  font-size: 30px;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767.98px) {
  .video iframe {
    padding-bottom: 1rem;
  }
}

.tablecontainer {
  overflow-x: hidden;
}

#event-table-search-input {
  width: 250px;
  height: 40px;
  font-size: 14px;
}

@media (max-width: 991.98px) {
  #event-table-search-input {
    display: none;
  }
}

tbody tr.odd>td {
  background-color: rgba(0,0,0,0) !important;
}

tbody tr:hover.odd>td {
  background-color: #d9d9d9 !important;
}

table.all-events-table {
  border-spacing: 1px;
  border-collapse: separate;
  font-size: 15px;
}

table.all-events-table .tablesorter-filter-row {
  display: none;
}

table.all-events-table .event-table-href {
  cursor: pointer;
  text-align: center;
}

table.all-events-table .event-table-href a {
  color: #404040;
}

table.all-events-table .event-table-date {
  font-weight: bold;
}

table.all-events-table th {
  background-color: #dbdbdb;
  font-size: 13px;
}

table.all-events-table th.datetime {
  width: 200px;
}

table.all-events-table th.tablesorter-header {
  background-image: url(/build/assets/img/sort.png);
  background-position: center right 1.25rem;
  background-color: #dbdbdb;
  background-size: 10px;
  padding: 1.25rem;
}

table.all-events-table th.tablesorter-headerAsc {
  background-image: url(/build/assets/img/sort_down.png) !important;
}

table.all-events-table th.tablesorter-headerDesc {
  background-image: url(/build/assets/img/sort_up.png) !important;
}

table.all-events-table th.sorter-false {
  background-image: unset;
}

table.all-events-table tr {
  height: 60px;
}

table.all-events-table tr.as-link {
  cursor: pointer;
}

table.all-events-table tr td {
  vertical-align: middle;
  padding: 1.25rem !important;
}

table.all-events-table tr td.as-link {
  cursor: pointer;
}

table.all-events-table tr td img.product-logo {
  height: 14px;
}

@media (max-width: 991.98px) {
  table.all-events-table {
    display: none;
  }
}

table {
  max-width: 100%;
  width: 100%;
}

table th {
  border: 2px solid #f3f3f3;
  border-bottom: 0;
  background-color: #e1e1e1;
  padding: 1.6rem;
}

table td {
  border: 2px solid #f3f3f3;
  background-color: #fff;
  padding: 1.6rem;
}

.btn-xls-export {
  margin-left: .2rem;
}

.redactor-content .expand {
  height: 100%;
  width: 100%;
}

.redactor-content .expand i {
  float: right;
  background-color: #e64155;
  padding: 1rem;
  color: #fff;
}

.redactor-content .expand svg {
  background-color: #e64155;
  color: #fff;
  height: 40px;
  padding: 5px;
  right: 0;
  width: 40px;
}

.redactor-content .table-responsive.expandable {
  position: relative;
}

.redactor-content .table-responsive.expandable table {
  margin-bottom: unset;
}

section.table .expand,
div.table .expand {
  height: 100%;
  width: 100%;
}

section.table .expand i,
div.table .expand i {
  float: right;
  background-color: #e64155;
  padding: 1rem;
  color: #fff;
}

section.table .expand svg,
div.table .expand svg {
  background-color: #e64155;
  color: #fff;
  height: 40px;
  padding: 5px;
  right: 0;
  width: 40px;
}

section.table .fa-expand-arrows,
div.table .fa-expand-arrows {
  cursor: pointer;
}

section.table .table-responsive.expandable,
div.table .table-responsive.expandable {
  cursor: unset !important;
  position: relative;
}

section.table .table-responsive.expandable table,
div.table .table-responsive.expandable table {
  cursor: unset !important;
  margin-bottom: unset;
}

section.table .table-responsive.expandable:hover,
div.table .table-responsive.expandable:hover {
  cursor: pointer;
}

section.table .table-responsive.expandable:hover .expand,
div.table .table-responsive.expandable:hover .expand {
  display: block;
}

table.events-overview {
  border-spacing: 0 10px;
  border-collapse: separate;
}

table.events-overview .event-radio-td input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

table.events-overview .event-radio-td img {
  display: inline-block;
  padding: 0px;
  height: 30px;
  width: 30px;
  background: none;
  border: 1px solid #666;
}

table.events-overview .event-radio-td input:checked+img {
  background: url(../img/check_box.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;
}

@media (max-width: 767.98px) {
  table.events-overview tr {
    display: flex;
    flex-direction: column;
  }

  table.events-overview tr td,
  table.events-overview tr th {
    padding: 10px;
    text-align: left !important;
  }
}

table.events-overview tr th {
  background-color: #e1e1e1;
  border-top: none;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  vertical-align: middle;
}

@media (min-width: 576px) {
  table.events-overview tr th {
    font-size: 12px;
    padding: 10px 50px;
  }
}

table.events-overview tr th span {
  display: block;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

table.events-overview tr td {
  background-color: #fff;
  border-top: none;
  vertical-align: middle;
}

@media (min-width: 576px) {
  table.events-overview tr td {
    padding: 20px;
  }
}

@media (max-width: 767.98px) {
  table.events-overview tr td {
    font-size: 1rem;
  }
}

table.events-overview tr td strong {
  display: block;
}

table.events-overview tr td span.dot {
  display: block;
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  line-height: 20px;
}

table.events-overview tr td span.dot.green {
  color: #6fcf97;
}

table.events-overview tr td span.dot.yellow {
  color: #f2c94c;
}

table.events-overview tr td span.dot.red {
  color: #eb5757;
}

table.events-overview tr select {
  background: url(../img/angle-down.svg) no-repeat right center #fff;
  border: 0;
  outline: 0;
  padding-right: 15px;
  -webkit-appearance: none;
}

table.events-overview tr select:hover {
  cursor: pointer;
}

table.subscriptions-options {
  border-spacing: 0 10px;
  border-collapse: separate;
  width: 100%;
}

table.subscriptions-options.discount th {
  background-color: #e2001a;
  color: #fff;
  text-indent: 8px;
  padding: .8rem;
}

table.subscriptions-options.discount tr td {
  border-top: 2px solid #e2001a;
  border-bottom: 2px solid #e2001a;
}

table.subscriptions-options.discount tr td:first-of-type {
  border-left: 2px solid #e2001a;
}

table.subscriptions-options.discount tr td:last-of-type {
  border-right: 2px solid #e2001a;
}

table.subscriptions-options.discount .price span {
  color: #e2001a;
  font-weight: 600;
  text-decoration: line-through;
  display: block;
}

@media (max-width: 991.98px) {
  table.subscriptions-options.discount .price span {
    font-size: 2rem;
  }
}

table.subscriptions-options tr td {
  background-color: #fff;
  padding: 1.6rem;
}

table.subscriptions-options tr td .form-check-label {
  margin-bottom: .6666666667rem;
  padding-left: 2.24rem;
}

table.subscriptions-options tr span {
  display: block;
}

table.subscriptions-options tr strong {
  display: block;
}

table.subscriptions-options tr .price span {
  font-size: 3rem;
  font-weight: 600;
}

@media (max-width: 991.98px) {
  table.subscriptions-options tr .price span {
    font-size: 2rem;
  }
}

table.subscriptions-options tr select {
  background: url(../img/angle-down.svg) no-repeat 90% center #fff;
  background-size: 12px;
  border-radius: 0;
  border: 1px solid #f3f3f3;
  outline: 0;
  padding: .8rem 3rem .8rem .8rem;
  -webkit-appearance: none;
}

.modal.show:hover {
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .modal.show {
    pointer-events: none;
  }
}

.modal.show.show {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  padding: 50px !important;
}

.modal.show.show .closer svg {
  color: #fff;
  float: right;
  height: 40px;
  width: 40px;
}

.category-hero-left:after,
.category-hero-right:after {
  content: "";
  display: table;
  clear: both;
}

.category-hero-left .row,
.category-hero-right .row {
  margin-bottom: 1.3333333333rem;
}

@media (max-width: 991.98px) {
  .category-hero-left .row,
  .category-hero-right .row {
    margin-bottom: 0;
  }
}

.category-hero-left .row a,
.category-hero-right .row a {
  color: #3f3f3f;
  text-decoration: none;
}

.category-hero-left .row a .card,
.category-hero-right .row a .card {
  border-radius: 0;
  border: none;
  height: 100%;
  position: relative;
}

.category-hero-left .row a .card .video-head,
.category-hero-right .row a .card .video-head {
  position: relative;
}

.category-hero-left .row a .card img,
.category-hero-right .row a .card img {
  height: auto;
  width: 100%;
}

.category-hero-left .row a .card .card-footer,
.category-hero-right .row a .card .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  color: #949494;
}

.category-hero-left .row a .card .card-footer .date,
.category-hero-right .row a .card .card-footer .date {
  float: left;
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card .card-footer .date,
  .category-hero-right .row a .card .card-footer .date {
    float: none;
  }
}

@media (max-width: 767.98px) {
  .category-hero-left .row a .card .card-footer .date,
  .category-hero-right .row a .card .card-footer .date {
    float: left;
  }
}

.category-hero-left .row a .card .card-footer .comments,
.category-hero-right .row a .card .card-footer .comments {
  float: right;
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card .card-footer .comments,
  .category-hero-right .row a .card .card-footer .comments {
    float: none;
  }
}

@media (max-width: 767.98px) {
  .category-hero-left .row a .card .card-footer .comments,
  .category-hero-right .row a .card .card-footer .comments {
    float: right;
  }
}

.category-hero-left .row a .card.hero,
.category-hero-right .row a .card.hero {
  height: calc(100% - 1.2rem);
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card.hero,
  .category-hero-right .row a .card.hero {
    height: auto;
  }
}

.category-hero-left .row a .card.hero img,
.category-hero-right .row a .card.hero img {
  height: 662px;
}

@media (max-width: 1199.98px) {
  .category-hero-left .row a .card.hero img,
  .category-hero-right .row a .card.hero img {
    height: 595px;
  }
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card.hero img,
  .category-hero-right .row a .card.hero img {
    height: 510px;
  }
}

@media (max-width: 767.98px) {
  .category-hero-left .row a .card.hero img,
  .category-hero-right .row a .card.hero img {
    height: auto;
  }
}

body>.alert {
  margin-bottom: 0;
}

.alert {
  border-radius: 0;
  border: none;
  padding: 1rem;
  margin-bottom: 0;
}

.alert p:last-of-type {
  margin-bottom: 0;
}

.alert.alert-success {
  color: #0c5460;
  background-color: #6fcf97;
}

.alert.alert-success-form-submit {
  padding: 50px;
  border-bottom: 5px solid #e2001a;
  font-size: 20px;
}

.alert.alert-success-form-submit p span {
  font-size: 25px;
}

.alert.alert-warning {
  color: #937300;
  background-color: #ffc800;
}

.alert.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
}

.alert.alert-danger {
  color: #fff;
  background-color: #e64155;
}

.alert.alert-danger-form-submit {
  padding: 50px;
  font-size: 20px;
}

dl {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

dl dt,
dl dd {
  padding: 1.6rem;
  margin: 0 0 .2em;
}

dl dt {
  background-color: rgba(63,63,63,.1);
}

dl dd {
  background-color: #fff;
}

dl dd code {
  color: #3f3f3f;
}

.image-text-overlay {
  position: relative;
}

.image-text-overlay .copyright-text {
  position: absolute;
  display: block;
  color: #fff;
  transform: rotate(-90deg) translate(100%, 0%);
  right: 1em;
  transform-origin: right;
  font-size: .6em;
  padding: .25em;
  bottom: 0;
}

@media (max-width: 991.98px) {
  .image-text-overlay .copyright-text {
    bottom: 0rem;
  }
}

.figcaption {
  font-size: .7em;
}

.accordion .card {
  border: none;
  background: rgba(0,0,0,0);
  width: 100%;
}

.accordion .card .card-header {
  background-color: #fff;
  border-radius: 0;
  border-bottom: 0;
  margin-bottom: 1rem;
}

.accordion .card .card-header:hover {
  cursor: pointer;
}

.accordion .card .card-header:hover h5 {
  color: #e64155;
}

.accordion .card .card-header h5 {
  margin-bottom: 0;
}

.accordion .card .card-header h5:active {
  background: none;
  border: none;
  box-shadow: none !important;
}

.accordion .card .card-header h5:before {
  content: url("../img/plus.svg");
  padding-right: .8rem;
  top: .25rem;
}

.accordion .card .card-header.active h5:before {
  content: url("../img/minus.svg");
}

.accordion .card img.img-fluid {
  margin-bottom: 1rem;
}

.accordion .card .card-employee {
  position: relative;
}

.accordion .card .card-employee .card-body {
  background-color: #fff;
  padding: 1.6rem;
}

.accordion .card .card-employee .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  color: #949494;
}

.accordion .card .card-employee .card-footer img {
  width: 20px;
  margin-right: .6666666667rem;
}

.accordion .card .card-apply {
  background-color: #e2001a;
  background-image: url("../img/person-placeholder-icon.svg");
  background-position: -1rem -4rem;
  background-repeat: no-repeat;
  background-size: 50%;
  height: calc(100% - 2rem);
  position: relative;
}

@media (max-width: 767.98px) {
  .accordion .card .card-apply {
    height: 100%;
  }
}

.accordion .card .card-apply .card-body {
  position: absolute;
  bottom: 0;
  padding: 1.6rem;
}

@media (max-width: 767.98px) {
  .accordion .card .card-apply .card-body {
    position: inherit;
  }
}

.accordion .card .card-apply .card-body p,
.accordion .card .card-apply .card-body h4 {
  color: #fff;
}

.accordion .card .card-apply .card-body .text-muted {
  color: #fff !important;
}

.accordion .card .show {
  margin-bottom: 1rem;
}

.accordion .flip .card {
  margin-bottom: 2rem;
}

.accordion .flip .card .face .inner {
  min-height: 376px;
}

.accordion .flip .card .face.front {
  background-color: #fff;
}

.accordion .flip .card .face.back {
  background-color: #949494;
  color: #fff;
}

.accordion .flip .card .face.back .inner {
  padding: 1.6rem;
}

.accordion .flip .card .face.back .inner .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  color: #949494;
}

.accordion .flip .card .face.back .inner .card-footer img {
  width: 20px;
  margin-right: .6666666667rem;
}

.accordion .flip .card .face.back .inner a:hover {
  color: #3f3f3f;
  cursor: pointer;
}

.btn {
  font-size: 16px;
  font-size: 1.6rem;
  border-radius: 0;
  border: 0;
  outline: 0;
  padding: .35em .8em;
  background-color: rgba(0,0,0,0);
  text-decoration: none;
}

@media (max-width: 575.98px) {
  .btn {
    white-space: normal;
  }
}

.btn.btn-lg {
  font-size: 1.2em;
  padding: .5em 1em;
}

.btn.btn-default {
  font-size: 14px;
  font-size: 1.4rem;
  color: #3f3f3f;
  text-transform: uppercase;
  padding: .25em .5em;
  margin: .5em -0.25em;
  letter-spacing: .1em;
}

.btn.btn-default:hover,
.btn.btn-default:active,
.btn.btn-default:focus,
.btn.btn-default:active:focus {
  color: #e64155;
}

@media (max-width: 991.98px) {
  .btn.btn-default {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media (max-width: 575.98px) {
  .btn.btn-default {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.btn.btn-default-neg {
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  padding: .25em .5em;
  margin: .5em -0.25em;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.btn.btn-default-neg:hover,
.btn.btn-default-neg:active,
.btn.btn-default-neg:focus,
.btn.btn-default-neg:active:focus {
  color: #f3f3f3;
}

@media (max-width: 991.98px) {
  .btn.btn-default-neg {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

@media (max-width: 575.98px) {
  .btn.btn-default-neg {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.btn.btn-primary {
  color: #3f3f3f;
  padding: 0;
}

.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus,
.btn.btn-primary:active:focus {
  color: #e64155;
  background-color: rgba(0,0,0,0);
}

.btn.btn-secondary {
  color: #fff;
  background-color: #e2001a;
}

.btn.btn-secondary:hover,
.btn.btn-secondary:active,
.btn.btn-secondary:focus,
.btn.btn-secondary:active:focus {
  background-color: #ec6e7d;
  color: #fff;
}

.btn.btn-tertiary {
  color: #fff;
  background-color: #e64155;
}

.btn.btn-tertiary:hover,
.btn.btn-tertiary:active,
.btn.btn-tertiary:focus,
.btn.btn-tertiary:active:focus {
  background-color: #ec6e7d;
  color: #fff;
}

.btn.btn-success {
  color: #fff;
  background-color: #404040;
}

.btn.btn-success:hover,
.btn.btn-success:active,
.btn.btn-success:focus,
.btn.btn-success:active:focus {
  background-color: #5a5a5a;
  color: #fff;
}

.btn:focus {
  box-shadow: none;
}

.btn:active {
  box-shadow: 0 0 0 .1em rgba(226,0,26,.9) !important;
}

.btn.btn-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  cursor: pointer;
}

ul.inline-nav {
  margin: 0;
  padding: 0;
}

ul.inline-nav li {
  list-style: none;
  margin: 0;
  display: inline;
  padding: 1rem;
}

body header .termine-uebersicht {
  background-color: #888;
  color: #fff;
}

body header .termine-uebersicht:hover {
  color: unset;
}

body header .navbar-brand img {
  height: 2.4rem;
  width: auto;
}

@media (max-width: 575.98px) {
  body header .navbar-brand img {
    height: 1.8rem;
  }
}

@media screen and (-ms-high-contrast: active),(-ms-high-contrast: none) {
  body header .navbar-brand img {
    width: 100%;
  }
}

body header #loginBox h2 {
  font-size: 2em;
}

body header #loginBox form {
  margin: 2rem 0;
}

body header #loginBox form .form-control {
  padding: .5em 1em;
  margin-bottom: 1rem;
  height: 40px;
}

body header #loginBox form .input-group-append {
  height: 40px;
}

body header #loginBox form .input-group-append .input-group-text {
  border: none;
}

body header #loginBox form a {
  margin-left: 1rem;
}

body header #loginBox form .btn-secondary {
  width: 50%;
}

body header #loginBox form .form-check {
  margin-top: 1rem;
}

body header #loginBox .register .btn-secondary {
  width: 100%;
}

body header label[for=search] {
  margin: 0;
}

body header label[for=search]:hover {
  cursor: pointer;
}

body header #searchBox {
  background-color: #e64155;
  color: rgba(255,255,255,.5);
}

body header #searchBox form .input-group-text {
  background-color: rgba(0,0,0,0);
  color: rgba(255,255,255,.5);
  border: 0;
  font-size: 1.6em;
  padding-left: 0;
}

body header #searchBox form input.form-control {
  background-color: rgba(0,0,0,0);
  color: #fff;
}

body header #searchBox form input.form-control:active,
body header #searchBox form input.form-control:focus {
  box-shadow: none;
}

body header #searchBox form input.form-control::placeholder {
  color: rgba(255,255,255,.5);
}

body header #searchBox form #searchSubmit:hover {
  cursor: pointer;
}

body header>nav.navbar {
  background-color: #fff;
  padding: 1.6rem;
  font-size: 14px;
  font-size: 1.4rem;
}

body header>nav.navbar ul.navbar-nav li a {
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
}

body header>nav.navbar ul.navbar-nav li a.nav-link {
  color: #3f3f3f;
  text-decoration: none;
  padding: 1rem;
  font-size: 1.6rem;
}

body header>nav.navbar ul.navbar-nav li a.nav-link:hover,
body header>nav.navbar ul.navbar-nav li a.nav-link:active,
body header>nav.navbar ul.navbar-nav li a.nav-link:focus,
body header>nav.navbar ul.navbar-nav li a.nav-link:active:focus {
  color: #545454;
}

body header>nav.navbar ul.navbar-nav li a.nav-link.dropdown-toggle:after {
  width: 1.5em;
  height: 1em;
  margin: 0;
  line-height: 1;
  vertical-align: -0.1em;
  border: 0;
  background-image: url("../img/angle-down.svg");
  background-position: center;
  background-repeat: no-repeat;
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu {
  font-size: 1em;
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu.show {
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu a.dropdown-item {
  text-decoration: none;
  padding: 1.6rem;
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu a.dropdown-item.hover,
body header>nav.navbar ul.navbar-nav li .dropdown-menu a.dropdown-item:hover {
  background-color: #e1e1e1;
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu a.dropdown-item.active,
body header>nav.navbar ul.navbar-nav li .dropdown-menu a.dropdown-item:active {
  background-color: #e2001a;
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu a.dropdown-item.active:hover {
  background-color: #ec6e7d;
}

body header>nav.navbar ul.navbar-nav li .dropdown-menu .dropdown-divider {
  display: none;
}

body header>nav.navbar .navbar-toggler {
  border: none;
  padding: 1.6rem;
}

body header>nav.navbar .navbar-collapse.collapse {
  margin-right: 16px;
}

@media (min-width: 992px) {
  body header>nav.navbar .navbar-collapse.collapse {
    order: 1;
  }
}

@media (max-width: 767.98px) {
  body header>nav.navbar .container-fluid {
    justify-content: flex-end;
  }
}

@media (min-width: 992px) {
  body header>nav.navbar .navbar-wrapper {
    order: 2;
  }
}

body header .bg-light {
  background-color: #fff !important;
}

.breadcrumb {
  padding: 4rem 0rem .75rem 0rem;
  background-color: rgba(0,0,0,0);
}

@media (max-width: 767.98px) {
  .breadcrumb {
    padding: 1rem 0 .75rem;
  }
}

.breadcrumb .breadcrumb-item a {
  color: #444;
}

.breadcrumb .breadcrumb-item.active a {
  color: #e5e5e5;
}

.content-support .breadcrubs li {
  color: #6c757d;
}

.isni-ui {
  margin-bottom: 3rem;
}

form .form-control {
  font-size: 16px;
  font-size: 1.6rem;
  border-radius: 0;
  border: none;
  padding: .5em 1em;
  height: auto;
}

form .btn.btn-secondary {
  width: 100%;
}

form a.active {
  color: #e2001a;
}

form a.active .box {
  border: 2px solid #e2001a;
}

form a .box {
  background-color: #fff;
  padding: 2rem;
}

form a span {
  float: left;
  margin-right: 1.6rem;
  font-size: 3.6rem;
  margin-top: -0.1em;
}

form p {
  margin: 2rem 0;
}

form .form-check .form-check-label {
  margin-bottom: 1rem;
  padding-left: .8rem;
}

form .form-check p {
  margin-top: 0px;
  margin-bottom: 0px;
}

form select {
  background: url(../img/angle-down.svg) no-repeat 95% center #fff !important;
  background-size: 12px;
  border-radius: 0;
  border: 1px solid #f3f3f3;
  outline: 0;
  padding: .7rem;
  -webkit-appearance: none;
}

@-moz-document url-prefix()  {
  form select {
    background: none;
  }
}

form abbr {
  text-decoration: none !important;
  cursor: default !important;
}

input#preferredKitten {
  display: none;
}

.registration-form-deactivated {
  width: 100%;
}

.form-group.recaptcha iframe {
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

#specialUrlRedirect {
  margin: 0;
}

.flip {
  -webkit-perspective: 800px;
  perspective: 800px;
  position: relative;
}

.flip:hover .card {
  -moz-transform: rotatey(-180deg);
  -webkit-transform: rotatey(-180deg);
  transform: rotatey(-180deg);
}

.flip .card {
  height: 100%;
  -webkit-transform-style: preserve-3d;
  -webkit-transition: .5s;
  transform-style: preserve-3d;
  transition: .5s;
  transition-delay: .25s;
}

.flip .card .face {
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip .card .front {
  position: absolute;
  width: 100%;
}

.flip .card .back {
  -moz-transform: rotatey(-180deg);
  -webkit-transform: rotatey(-180deg);
  transform: rotatey(-180deg);
}

.flip .card .inner {
  position: relative;
  margin: 0;
}

.flip .card .inner .abs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 1em;
}

#modelle-overview a:focus,
#modelle-overview a:hover {
  text-decoration: none;
}

@media all and (-ms-high-contrast: none),(-ms-high-contrast: active) {
  .flip .card .face {
    transform-style: preserve-3d;
    transition: .5s;
    transition-delay: .25s;
  }

  .flip .card .face.back {
    visibility: hidden;
  }

  .flip:hover .card {
    transform: rotatey(0deg);
  }

  .flip:hover .card .face.front {
    transform: rotatey(-180deg);
    visibility: hidden;
  }

  .flip:hover .card .face.back {
    transform: rotatey(0deg);
    visibility: visible;
  }
}

@media all and (min-width: 0\0 )and (min-resolution: 0.001dpcm) {
  .flip .card .face.back {
    visibility: hidden;
  }

  .flip:hover .card .face.front {
    visibility: hidden;
  }

  .flip:hover .card .face.back {
    visibility: visible;
  }
}

#intro-section .owl-dots {
  display: none;
}

#intro-section .single-item {
  height: 600px;
  margin-bottom: 2rem;
  background-size: cover;
  background-position: center;
  display: block;
  width: 100%;
}

.rich-text:after {
  content: "";
  display: table;
  clear: both;
}

.rich-text img {
  max-width: 100%;
  height: auto !important;
  margin: 0 !important;
  padding-right: 2rem;
  padding-left: 0;
}

.subscriptions-options {
  background-color: #fff;
  padding: 1.6rem 0;
  margin: 0 0 2rem;
}

.subscriptions-options strong {
  display: block;
  margin-bottom: 1em;
}

.subscriptions-options h4 {
  background-color: #e2001a;
  color: #fff;
  text-indent: 8px;
  padding: .8rem;
  display: block;
  width: 100%;
}

@media (max-width: 575.98px) {
  .subscriptions-options h4 {
    text-indent: 0;
  }
}

.subscriptions-options h4 i {
  font-size: 14px;
  font-weight: lighter;
}

.subscriptions-options .price span {
  font-size: 3rem;
  font-weight: 600;
  display: block;
}

@media (max-width: 991.98px) {
  .subscriptions-options .price span {
    font-size: 2rem;
  }
}

.subscriptions-options .form-check-label {
  margin-bottom: .6666666667rem;
  padding-left: 2.4rem;
}

.subscriptions-options .form-check-label span {
  display: block;
}

.subscriptions-options .form-check-label strong {
  display: block;
}

.subscriptions-options .form-check-input {
  margin-left: 0;
}

.subscriptions-options.discount {
  border: 2px solid #e2001a;
  padding: 0;
}

.subscriptions-options.discount .price span {
  color: #e2001a;
  font-size: 3rem;
  font-weight: 600;
  text-decoration: line-through;
  display: block;
}

@media (max-width: 991.98px) {
  .subscriptions-options.discount .price span {
    font-size: 2rem;
  }
}

.subscriptions-options select {
  background: url(../img/angle-down.svg) no-repeat 90% center #fff;
  background-size: 12px;
  border-radius: 0;
  border: 1px solid #f3f3f3;
  outline: 0;
  padding: .8rem 3rem .8rem .8rem;
  -webkit-appearance: none;
}

.subscriptions-options #new-calculated-cost {
  color: #000;
  text-decoration: none;
  margin-top: -32px;
}

#search-page-heading {
  background: #fff;
  padding: 15px;
  margin-top: 40px;
}

.search-page-result-box {
  background: #fff;
  padding: 23px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.search-page-result-box a {
  overflow-wrap: break-word;
}

.search-page-result-box img {
  display: none;
}

.search-page-result-box .search-thumbnail {
  display: block;
  width: 100%;
}

.slider {
  color: #fff;
  margin-bottom: 0;
  opacity: 1;
}

@media (max-width: 1199.98px) {
  .slider .default-slide-3 {
    opacity: .85;
  }
}

@media (max-width: 1199.98px) {
  .slider div.default-slide-3 {
    margin-bottom: -15rem;
    background-color: #e2001a;
    color: #fff;
    padding: 2rem;
    min-height: 20rem;
    margin-left: 15px;
    margin-right: 15px;
  }

  .slider div.default-slide-3 h1,
  .slider div.default-slide-3 h2 {
    font-size: 25px;
  }

  .slider div.default-slide-3 a {
    color: #fff;
  }
}

.slider .row {
  position: relative;
}

@media (max-width: 1199.98px) {
  .slider .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.slider .row .textbox {
  width: 100%;
  opacity: .85;
  background-color: #e2001a;
  color: #fff;
  position: absolute;
  min-height: 10rem;
  padding: 3rem;
}

.slider .row .textbox.protrude-textbox {
  margin-bottom: -6rem;
}

@media (max-width: 1199.98px) {
  .slider .row .textbox {
    margin-bottom: -19rem;
  }
}

.slider .row .textbox .box-logo {
  width: auto;
  padding-bottom: 1.5rem;
  max-width: 90%;
  filter: brightness(0) invert(1);
}

@media (max-width: 1199.98px) {
  .slider .row .textbox {
    position: inherit;
    padding: 2rem;
    margin-bottom: -19rem;
  }
}

.slider .row .textbox a,
.slider .row .textbox .btn.btn-primary {
  color: #fff;
}

.slider .row .textbox .box-label {
  position: absolute;
  right: 0;
  background-color: #444;
  padding: .5rem;
  color: #fff;
  top: 1rem;
  font-size: 1.4rem;
}

.slider .row .textbox-new .col-xl-6 {
  max-width: 100%;
  padding: 2rem;
}

@media (max-width: 1199.98px) {
  .slider .row .textbox-new {
    width: 100%;
    opacity: .85;
    color: #fff;
    position: absolute;
    min-height: 20rem;
    padding: 3rem;
  }

  .slider .row .textbox-new .col-xl-6 {
    background-color: #e2001a;
    color: #fff;
    max-width: 100%;
  }

  .slider .row .textbox-new .col-xl-6 h1,
  .slider .row .textbox-new .col-xl-6 h2 {
    font-size: 25px;
    color: #fff;
  }

  .slider .row .textbox-new .col-xl-6 a {
    color: #fff;
  }

  .slider .row .textbox-new .box-logo {
    width: 6rem;
    padding-bottom: 1.5rem;
  }
}

@media (max-width: 1199.98px)and (max-width: 1199.98px) {
  .slider .row .textbox-new {
    position: inherit;
    padding: 2rem;
    margin-bottom: -17rem;
  }
}

@media (max-width: 1199.98px) {
  .slider .row .textbox-new a,
  .slider .row .textbox-new .btn.btn-primary {
    color: #fff;
  }

  .slider .row .textbox-new .box-label {
    position: absolute;
    right: 0;
    background-color: #444;
    padding: .5rem;
    color: #fff;
    top: 1rem;
    font-size: 1.4rem;
  }
}

.slider .row h3 {
  margin-bottom: 4rem;
}

@media (max-width: 1199.98px) {
  .slider .row h3 {
    margin-bottom: 0;
  }
}

.slider .owl-carousel {
  z-index: 0;
}

.slider .owl-carousel .owl-stage {
  display: flex;
  min-height: 100%;
}

.slider .owl-carousel .owl-stage .owl-item {
  display: flex;
  min-height: 100%;
}

.slider .owl-carousel .owl-stage .owl-item .default-slide-2 {
  background-position: 0 100% !important;
  background-size: contain !important;
  background-repeat: no-repeat;
}

.slider .owl-carousel .owl-stage .owl-item .default-slide-2.overlay:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: unset !important;
}

.slider .owl-carousel .owl-stage .owl-item .item {
  margin-bottom: 3rem;
  min-height: 60vh;
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
  background-color: #e2001a;
  background-size: cover;
}

.slider .owl-carousel .owl-stage .owl-item .item.protrude-box {
  margin-bottom: 6rem;
}

@media (max-width: 1199.98px) {
  .slider .owl-carousel .owl-stage .owl-item .item {
    min-height: 40vh;
    margin-bottom: 12rem;
  }
}

@media (max-width: 575.98px) {
  .slider .owl-carousel .owl-stage .owl-item .item {
    margin-bottom: 22rem;
  }
}

@media (max-width: 333px) {
  .slider .owl-carousel .owl-stage .owl-item .item {
    margin-bottom: 45rem;
  }
}

@media (max-width: 1199.98px) {
  .slider .owl-carousel .owl-stage .owl-item .item .container {
    padding: 0;
  }
}

@media (max-width: 1199.98px) {
  .slider .owl-carousel .owl-stage .owl-item .item .container .col-xl-6 {
    height: 200px;
  }
}

.slider .owl-carousel .owl-stage .owl-item .item.top {
  background-position: top;
}

.slider .owl-carousel .owl-stage .owl-item .item.center {
  background-position: center;
}

.slider .owl-carousel .owl-stage .owl-item .item.bottom {
  background-position: bottom;
}

.slider .owl-carousel .owl-stage .owl-item .item.overlay:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(63, 63, 63, 0.5));
  mix-blend-mode: normal;
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide {
  position: relative;
  align-items: center;
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) 180%);
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide .container,
.slider .owl-carousel .owl-stage .owl-item .item.finderslide .row {
  height: 100%;
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide .container .img,
.slider .owl-carousel .owl-stage .owl-item .item.finderslide .row .img {
  padding-bottom: 0;
}

@media (min-width: 576px) {
  .slider .owl-carousel .owl-stage .owl-item .item.finderslide .container .img img,
  .slider .owl-carousel .owl-stage .owl-item .item.finderslide .row .img img {
    bottom: -3rem;
    max-height: 100%;
    position: absolute;
    width: auto;
  }
}

@media (max-width: 1199.98px) {
  .slider .owl-carousel .owl-stage .owl-item .item.finderslide .container .img img,
  .slider .owl-carousel .owl-stage .owl-item .item.finderslide .row .img img {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide .container .text,
.slider .owl-carousel .owl-stage .owl-item .item.finderslide .row .text {
  align-self: center;
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide .container .text div,
.slider .owl-carousel .owl-stage .owl-item .item.finderslide .row .text div {
  font-size: 3rem;
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide.light,
.slider .owl-carousel .owl-stage .owl-item .item.finderslide.light a.btn {
  color: #fff;
}

.slider .owl-carousel .owl-stage .owl-item .item.finderslide.dark,
.slider .owl-carousel .owl-stage .owl-item .item.finderslide.dark a.btn {
  color: #3f3f3f;
}

@media (max-width: 1199.98px) {
  .slider .owl-carousel .owl-stage .owl-item .item .btn {
    text-align: left;
  }
}

.slider .owl-carousel .owl-nav {
  padding: 0;
  background-color: #9acd32;
}

.slider .owl-carousel .owl-nav [class*=owl-]:hover {
  background: rgba(0,0,0,0);
}

@media (max-width: 1199.98px) {
  .slider .owl-carousel .owl-nav {
    display: none;
  }
}

.slider .owl-carousel .owl-nav .owl-prev,
.slider .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  height: 40px;
  width: 40px;
}

.slider .owl-carousel .owl-nav .owl-prev {
  right: 96%;
  background-image: url("../img/pfeil-links.svg");
  background-color: rgba(0,0,0,0);
}

@media (max-width: 1199.98px) {
  .slider .owl-carousel .owl-nav .owl-prev {
    right: 93%;
  }
}

@media (max-width: 1199.98px) {
  .slider .owl-carousel .owl-nav .owl-prev {
    display: none;
  }
}

.slider .owl-carousel .owl-nav .owl-next {
  left: 96%;
  background-image: url("../img/pfeil-rechts.svg");
  background-color: rgba(0,0,0,0);
}

@media (max-width: 1199.98px) {
  .slider .owl-carousel .owl-nav .owl-next {
    left: 93%;
  }
}

@media (max-width: 1199.98px) {
  .slider .owl-carousel .owl-nav .owl-next {
    display: none;
  }
}

.slider .owl-carousel .owl-dots {
  position: absolute;
  bottom: 9rem;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1199.98px) {
  .slider .owl-carousel .owl-dots {
    display: none;
  }
}

.slider .owl-carousel .owl-dots.disabled {
  display: block;
}

@media (max-width: 1199.98px) {
  .slider .owl-carousel .owl-dots.disabled {
    display: none;
  }
}

.slider .owl-carousel .owl-dots .owl-dot.active span {
  background-color: #fff;
}

.slider .owl-carousel .owl-dots .owl-dot:focus {
  outline: 0;
}

.slider .owl-carousel .owl-dots .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

@media (max-width: 1199.98px) {
  .slider .owl-carousel .owl-dots .owl-dot span {
    margin: 2rem 1rem;
  }
}

.slider .owl-carousel .owl-dots .owl-dot span:hover {
  background-color: #fff;
}

.slider.highlighted img.catlogo {
  filter: invert(100%) brightness(1000%);
  max-width: 200px;
}

.team-container .card-block {
  padding: 10px;
  min-height: 160px;
}

.team-container .grid-item {
  float: left;
  padding: 0;
  background: #fff;
  margin-bottom: 20px;
  width: calc((100% - 50px)/3);
}

.team-container .grid-item img.product-logo {
  height: 20px;
}

@media (max-width: 768px) {
  .team-container .grid-item {
    width: calc((100% - 40px)/2);
  }
}

@media (max-width: 600px) {
  .team-container .grid-item {
    width: calc(100% - 20px);
  }
}

.team-container .grid-item .card-block p.card-text-fixed-height {
  height: 12rem;
  overflow: hidden;
}

.team-container .grid-item .card-block a.team-member-email {
  text-decoration: none;
}

.team-container .grid-item .text-more-info {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

section.profile-card .image-wrapper {
  padding: 0;
}

section.profile-card .image-wrapper img {
  height: auto;
  width: 100%;
}

section.profile-card .profile-wrapper {
  background-color: #fff;
  margin-left: 20px;
  margin-right: -21px;
}

@media (max-width: 767.98px) {
  section.profile-card .profile-wrapper {
    margin-left: unset;
    margin-right: unset;
  }
}

section.profile-card .profile-wrapper.unset-left-margin {
  margin-left: unset;
  margin-right: unset;
}

.team-add-image-copyright {
  position: relative;
}

.team-add-image-copyright .image-copyright {
  position: absolute;
  transform: rotate(-90deg) translate(100%);
  right: 1em;
  transform-origin: right;
  font-size: .8em;
  bottom: 0;
  color: #fff;
}

div.product-description {
  padding-bottom: 40px;
}

div.product-description .product-description-left-section {
  background-color: #fff;
  padding: 20px;
}

div.product-description .product-description-left-section img {
  padding-bottom: 18px;
  max-width: 150px;
}

div.product-description .product-description-left-section a {
  padding: 9px;
  margin-top: 20px;
  display: block;
  text-align: center;
  color: #fff;
  text-decoration: none;
  background-color: #e2001a;
}

div.product-description .product-description-left-section a :hover {
  background-color: #e64155;
}

div.product-description .product-description-right-section {
  padding-left: 40px;
}

@media (max-width: 767.98px) {
  div.product-description .product-description-right-section {
    padding-left: 15px;
    padding-top: 20px;
  }
}

a.dynamic-generated-button {
  padding: 9px;
  display: inline-block;
  text-align: center;
  color: #fff;
  min-width: 200px;
  text-decoration: none;
  background-color: #e2001a;
}

a.dynamic-generated-button :hover {
  background-color: #e64155;
}

form div.form-group button[type=submit] {
  display: block;
  width: 100%;
  padding: 9PX;
  background-color: #e2001a;
  color: #fff;
  margin-top: 25px;
  margin-bottom: 25px;
  position: relative;
}

form div.form-group button[type=submit].freeform-processing {
  justify-content: center;
}

form div.form-group button[type=submit]::before {
  position: absolute;
  left: 10px;
}

form div.form-group button[type=submit]:hover {
  background-color: #e64155;
}

.cc-revoke {
  display: none;
}

.cc-window {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 5000;
  background-color: rgba(64,64,64,.7);
}

.cc-window a {
  color: #3f3f3f;
}

.cc-window.cc-invisible {
  display: none;
}

.cc-window .container {
  background-color: #fff;
  position: relative;
  width: auto;
  display: flex;
  padding: 4rem;
  color: #3f3f3f;
  border: #e2001a 1px solid;
  max-width: 40%;
}

@media (max-width: 991.98px) {
  .cc-window .container {
    max-width: 70%;
  }
}

@media (max-width: 767.98px) {
  .cc-window .container {
    max-width: 95%;
    padding: 1rem;
    padding-top: 3rem;
  }
}

.cc-window .cc-compliance {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.cc-window .cc-deny {
  display: block;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 1rem;
  font-size: 1.4rem;
  text-decoration: none;
  color: #3f3f3f;
}

.cc-window .cc-deny:hover {
  color: #007bff;
}

body#body-datenschutz .cc-window {
  display: none;
}

.ekko-lightbox .modal-dialog {
  flex: 100% !important;
  margin-left: 0;
  margin-right: 0;
}

*:focus {
  outline: none;
}

.disclaimer {
  background: rgba(0,0,0,.0941176471);
  padding: .25em 1.25em;
  margin-bottom: 1em;
}

.meta-nav {
  padding-top: 3rem;
  padding-bottom: 4rem;
}

@media (max-width: 991.98px) {
  .meta-nav {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
}

.meta-nav .col {
  display: flex;
}

@media (max-width: 991.98px) {
  .meta-nav .col {
    flex-basis: auto;
    margin-bottom: 20px;
    max-width: 50%;
  }
}

@media (max-width: 575.98px) {
  .meta-nav .col {
    max-width: 100%;
  }
}

.meta-nav .col h3 {
  font-size: 20px;
  font-size: 2rem;
  color: #fff;
  margin-bottom: 0;
  text-align: center;
}

.meta-nav .col .box {
  background-color: #e2001a;
  padding: 2.6rem 2.4rem 2.6rem 2.4rem;
  width: 100%;
}

.meta-nav .col .box i {
  padding-right: 1rem;
}

.meta-nav .col a {
  display: block;
  color: #fff;
  text-decoration: none;
}

.meta-nav-buchjournal {
  padding-top: 3rem;
  padding-bottom: 4rem;
}

@media (max-width: 991.98px) {
  .meta-nav-buchjournal {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
}

.meta-nav-buchjournal .col {
  display: flex;
  margin-bottom: 2.4rem;
  min-width: 30%;
}

@media (max-width: 991.98px) {
  .meta-nav-buchjournal .col {
    flex-basis: auto;
    margin-bottom: 2.4rem;
    max-width: 100%;
  }
}

.meta-nav-buchjournal .col h3 {
  font-size: 20px;
  font-size: 2rem;
  color: #fff;
  margin-bottom: 0;
  text-align: center;
}

.meta-nav-buchjournal .col .box {
  background-color: #e2001a;
  padding: 2.6rem 2.4rem 2.6rem 2.4rem;
  width: 100%;
}

.meta-nav-buchjournal .col .box i {
  padding-right: 1rem;
}

.meta-nav-buchjournal .col a {
  display: block;
  color: #fff;
  text-decoration: none;
}

.events-row {
  margin-bottom: 4rem;
}

.events-row:after {
  content: "";
  display: table;
  clear: both;
}

.events-row .wrapper {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

@media (max-width: 991.98px) {
  .events-row .wrapper {
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
  }
}

.events-row .wrapper .item {
  height: 100%;
  min-height: 300px;
  background: #fff;
  border: none;
  padding: 1.6rem;
  position: relative;
}

@media (max-width: 991.98px) {
  .events-row .wrapper .item {
    min-height: 220px;
  }
}

.events-row .wrapper .item .text-muted {
  position: absolute;
  bottom: 3rem;
}

@media (max-width: 991.98px) {
  .events-row .wrapper .item .text-muted {
    bottom: 1.6rem;
  }
}

.events-row .wrapper .item.trust-small {
  background-color: #e2001a;
  background-image: url("../img/quotations.svg");
  background-position: -1rem -4rem;
  background-repeat: no-repeat;
  background-size: 50%;
  color: #fff;
  position: relative;
  margin: -1.6rem;
}

.events-row .wrapper .item.trust-small .text-muted {
  color: #fff !important;
}

.events-row h3 {
  margin-bottom: 1.6rem;
}

.events-row h4 {
  margin-bottom: 2.4rem;
}

.events-row a {
  color: #3f3f3f;
  text-decoration: none;
}

.partner {
  margin-top: 6rem;
  margin-bottom: 6rem;
  overflow: hidden;
}

@media (max-width: 767px) {
  .partner {
    margin-bottom: 2rem;
  }
}

@media (max-width: 767px) {
  .partner .container {
    max-height: 110vw;
  }
}

.partner .card {
  border-radius: 0;
  border: none;
  display: flex;
  margin-bottom: 2rem;
}

.partner .card-body {
  padding: 1rem;
}

.partner .card-body .card-title {
  margin-left: 3px;
}

@media (max-width: 991.98px) {
  .partner .card-body .btn {
    letter-spacing: normal;
  }
}

@media (max-width: 767.98px) {
  .partner .card-body .btn {
    letter-spacing: .1em;
  }
}

@media (max-width: 991.98px) {
  .partner .card-body .btn i {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .partner .card-body .btn i {
    display: inline;
  }
}

.partner h3 {
  margin-bottom: 1.6rem;
}

.partner .img-wrapper {
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 8rem;
}

@media (min-width: 576px) {
  .partner .img-wrapper {
    height: 10rem;
  }
}

.partner .img-wrapper img {
  width: auto;
  max-height: 46px;
  max-width: 95%;
}

.partner .img-wrapper a {
  text-decoration: none;
}

.partner .img-wrapper p {
  font-weight: bold;
  font-size: 18px;
  color: #3f3f3f;
  margin: 0 auto;
  text-align: center;
}

#clientexpander {
  background-color: #e2001a;
  color: #fff;
  display: none;
  height: 35px;
  padding: 0;
}

@media (max-width: 767px) {
  #clientexpander {
    display: block;
    margin: 0 auto 6rem auto;
    width: calc(100% - 2.6rem);
  }
}

#clientexpander.gradient:before {
  background: #fff;
  background: -moz-linear-gradient(0deg, rgb(255, 255, 255) 20%, rgba(255, 255, 255, 0) 50%);
  background: -webkit-linear-gradient(0deg, rgb(255, 255, 255) 20%, rgba(255, 255, 255, 0) 50%);
  background: linear-gradient(0deg, rgb(255, 255, 255) 20%, rgba(255, 255, 255, 0) 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
  content: "";
  height: 100px;
  position: absolute;
  left: 0;
  top: -103px;
  width: 100%;
}

#clientexpander i {
  font-size: 2rem;
  font-weight: 100;
  line-height: 35px;
}

.hint {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.hint i {
  font-size: 5rem;
}

.hint i.fa-arrow-right {
  font-size: 2rem;
}

@media (max-width: 767.98px) {
  .hint i.fa-arrow-right {
    font-size: 1.5rem;
  }
}

.hint .tipbox {
  background-color: #e2001a;
  padding: 1.6rem;
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
}

@media (max-width: 767.98px) {
  .hint .tipbox {
    min-height: 25rem;
  }

  .hint .tipbox .float-right {
    float: none !important;
  }

  .hint .tipbox .col-md-9 {
    display: flex;
  }
}

.hint strong {
  overflow: hidden;
  vertical-align: middle;
  position: relative;
  top: 1px;
}

.hint span {
  padding-right: 1rem;
  float: left;
  font-size: 2rem;
}

.featured .row,
.latestpress .row {
  margin-bottom: 1.3333333333rem;
}

@media (max-width: 767.98px) {
  .featured .row .col-md-6:first-of-type,
  .latestpress .row .col-md-6:first-of-type {
    margin-bottom: 3rem;
  }
}

.featured h3,
.latestpress h3 {
  margin-bottom: 1.6rem;
}

@media (max-width: 767.98px) {
  .featured .news,
  .featured .press,
  .latestpress .news,
  .latestpress .press {
    margin-bottom: 6rem;
  }

  .featured .news>div:last-of-type,
  .featured .press>div:last-of-type,
  .latestpress .news>div:last-of-type,
  .latestpress .press>div:last-of-type {
    margin-bottom: 2rem !important;
  }
}

.featured .news a,
.featured .press a,
.latestpress .news a,
.latestpress .press a {
  color: #3f3f3f;
  text-decoration: none;
}

.featured .news .card,
.featured .press .card,
.latestpress .news .card,
.latestpress .press .card {
  border-radius: 0;
  border: none;
  height: 100%;
  position: relative;
  display: inline-block;
}

.featured .news .card .product-logo,
.featured .press .card .product-logo,
.latestpress .news .card .product-logo,
.latestpress .press .card .product-logo {
  height: 15px;
  width: auto;
  background-color: rgba(0,0,0,0);
  margin-bottom: 10px;
}

.featured .news .card h4,
.featured .press .card h4,
.latestpress .news .card h4,
.latestpress .press .card h4 {
  margin-bottom: 3rem;
}

.featured .news .card img,
.featured .press .card img,
.latestpress .news .card img,
.latestpress .press .card img {
  height: auto;
  width: 100%;
  background-color: #545454;
}

.featured .news .card .card-footer,
.featured .press .card .card-footer,
.latestpress .news .card .card-footer,
.latestpress .press .card .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  color: #545454;
  position: absolute;
  bottom: 1.25rem;
}

.featured .news .card.bgimg,
.featured .press .card.bgimg,
.latestpress .news .card.bgimg,
.latestpress .press .card.bgimg {
  color: #fff;
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),url("../img/platzhalter.png");
  background-size: cover;
}

.featured .news .card.bgimg h4,
.featured .press .card.bgimg h4,
.latestpress .news .card.bgimg h4,
.latestpress .press .card.bgimg h4 {
  margin: 0 3rem 3rem 0;
  position: absolute;
  bottom: 1.6rem;
}

@media (max-width: 991.98px) {
  .featured .news .card.bgimg h4,
  .featured .press .card.bgimg h4,
  .latestpress .news .card.bgimg h4,
  .latestpress .press .card.bgimg h4 {
    position: inherit;
  }
}

.featured .news .card.bgimg .text-muted,
.featured .press .card.bgimg .text-muted,
.latestpress .news .card.bgimg .text-muted,
.latestpress .press .card.bgimg .text-muted {
  color: #fff !important;
}

.featured .events,
.latestpress .events {
  background-color: #3f3f3f;
  padding: 3rem;
  margin-bottom: 2rem;
}

@media (max-width: 991.98px) {
  .featured .events,
  .latestpress .events {
    padding: 1.6rem;
    min-height: 0;
  }
}

.featured .events a,
.latestpress .events a {
  text-decoration: none;
  border-bottom: 1px solid #545454;
  display: block;
}

.featured .events a:last-of-type,
.latestpress .events a:last-of-type {
  border-bottom: none;
  margin-bottom: .5rem;
}

.featured .events a .card,
.latestpress .events a .card {
  background-color: #3f3f3f;
  color: #fff;
  border-radius: 0;
  border: none;
  padding-bottom: 1rem;
}

.featured .events a .card h4,
.latestpress .events a .card h4 {
  margin-bottom: 0;
}

.featured .events a .card .event-logo,
.latestpress .events a .card .event-logo {
  margin-bottom: 1.5rem;
  height: 15px;
  filter: brightness(0) invert(1);
  height: 2rem;
}

.featured .events a .card .card-body,
.latestpress .events a .card .card-body {
  padding: 2rem 0;
}

@media (max-width: 991.98px) {
  .featured .events a .card .card-body,
  .latestpress .events a .card .card-body {
    padding: 1rem 0;
  }
}

.featured .events a .card .date-info,
.latestpress .events a .card .date-info {
  overflow: hidden;
}

.featured .events a span,
.latestpress .events a span {
  padding-right: 2rem;
  font-size: 50px;
  font-size: 5rem;
  float: left;
  position: relative;
  margin-top: -2.1rem;
}

@media (max-width: 991.98px) {
  .featured .events a span,
  .latestpress .events a span {
    display: none;
  }
}

.featured .events a .text-muted,
.latestpress .events a .text-muted {
  color: #fff !important;
}

.featured .events a small,
.latestpress .events a small {
  font-size: 1.7rem;
}

.trust .owl-two .owl-stage-outer .owl-stage .owl-item {
  opacity: .6;
}

.trust .owl-two .owl-stage-outer .owl-stage .center {
  zoom: normal !important;
  opacity: 1;
}

.trust .owl-two .owl-stage-outer .owl-stage .center .item {
  zoom: 1.09;
}

@media (max-width: 575.98px) {
  .trust .owl-two .owl-stage-outer .owl-stage .center {
    zoom: 0;
  }
}

.trust .owl-carousel {
  z-index: 0;
}

.trust .owl-carousel .owl-stage {
  display: flex;
  align-items: flex-end;
}

.trust .owl-carousel .item {
  min-height: 25vw;
  align-items: stretch;
  display: flex;
  margin-right: 16px;
  width: 100%;
}

@media (max-width: 1199.98px) {
  .trust .owl-carousel .item {
    min-height: 35vw;
  }
}

@media (max-width: 991.98px) {
  .trust .owl-carousel .item {
    min-height: 20vw;
  }
}

.trust .owl-carousel .item .container {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  background-size: cover;
  color: #fff;
  position: relative;
  padding: 6rem;
}

@media (max-width: 991.98px) {
  .trust .owl-carousel .item .container {
    padding: 2rem;
  }
}

.trust .owl-carousel .item .container .text-holder {
  position: absolute;
}

@media (max-width: 991.98px) {
  .trust .owl-carousel .item .container .text-holder {
    position: inherit;
  }
}

.trust .owl-carousel .item blockquote {
  padding: 0 6rem 6rem 0;
}

@media (max-width: 767.98px) {
  .trust .owl-carousel .item blockquote {
    padding: 0 2rem 2rem 0;
  }
}

.trust .owl-carousel .item blockquote q {
  font-size: 2.4rem;
}

@media (max-width: 767.98px) {
  .trust .owl-carousel .item blockquote q {
    text-shadow: 0 4px 2px rgba(0,0,0,.1);
  }
}

.trust .owl-carousel .item blockquote cite {
  font-style: normal;
}

.trust .owl-carousel .item blockquote footer {
  background-color: rgba(0,0,0,0);
  padding: 0;
  border-top: 0;
  color: #fff;
}

.trust .owl-carousel .owl-nav {
  margin: 0;
  padding: 0;
}

@media (max-width: 991.98px) {
  .trust .owl-carousel .owl-nav {
    display: none;
  }
}

.trust .owl-carousel .owl-nav.disabled {
  display: inline;
}

.trust .owl-carousel .owl-nav .owl-prev,
.trust .owl-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  height: 40px;
  width: 40px;
}

.trust .owl-carousel .owl-nav .owl-prev {
  right: 96%;
  background-image: url("../img/pfeil-links.svg");
  background-color: rgba(0,0,0,0);
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}

.trust .owl-carousel .owl-nav .owl-next {
  left: 96%;
  background-image: url("../img/pfeil-rechts.svg");
  background-color: rgba(0,0,0,0);
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
}

.trust .owl-carousel .owl-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateY(-50%);
}

@media (max-width: 767.98px) {
  .trust .owl-carousel .owl-dots {
    position: static;
    display: none;
  }
}

.trust .owl-carousel .owl-dots.disabled {
  display: block;
}

.trust .owl-carousel .owl-dots .owl-dot.active span {
  background-color: #fff;
}

.trust .owl-carousel .owl-dots .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

@media (max-width: 767.98px) {
  .trust .owl-carousel .owl-dots .owl-dot span {
    margin: 2rem 1rem;
  }
}

.trust .owl-carousel .owl-dots .owl-dot span:hover {
  background-color: #fff;
}

.trust-signle {
  padding-left: 15px;
}

.trust-signle .container {
  min-height: 30vw;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  background-size: cover;
  color: #fff;
  position: relative;
  padding: 6rem;
}

@media (max-width: 767.98px) {
  .trust-signle .container {
    min-height: 35vw;
    padding: 2rem;
  }
}

@media (max-width: 991.98px) {
  .trust-signle .container q {
    font-size: 2.4rem;
    text-shadow: 0 4px 2px rgba(0,0,0,.1);
  }
}

.trust-signle .container .text-holder {
  position: absolute;
  top: 2rem;
  display: block;
  overflow: auto;
  bottom: 6rem;
}

@media (max-width: 991.98px) {
  .trust-signle .container .text-holder {
    position: inherit;
    bottom: 0;
  }
}

.trust-signle .container .text-holder footer {
  height: auto;
}

.trust-signle .container footer {
  background-color: rgba(0,0,0,0);
  padding: 0;
}

.trust-signle .container cite {
  font-style: normal;
}

.testimonial-new .i {
  color: #fff !important;
}

.testimonial-new .item .img-wrapper {
  background-size: cover;
}

.testimonial-new .item .img-wrapper img {
  display: block;
  object-position: top;
  visibility: hidden;
}

.testimonial-new .item .testimonial-text {
  color: #fff;
  padding: 2rem;
  overflow: auto;
  width: 100%;
  background-color: rgba(226,0,26,.8);
  height: 20rem;
  opacity: .9;
}

@media (min-width: 768px) {
  .testimonial-new .item .testimonial-text {
    position: absolute;
    bottom: 0px;
    background: none;
    height: auto;
    overflow: auto;
    width: 50%;
    min-height: unset;
    left: unset;
    left: 3rem;
    background-color: rgba(226,0,26,.8);
  }
}

.testimonial-new .item .testimonial-text img {
  width: auto;
  display: unset;
  padding-bottom: 2rem;
  filter: brightness(0) invert(1);
}

.testimonial-new .item .testimonial-text q {
  font-size: 2.6rem;
}

.testimonial-new .item .testimonial-text p {
  font-size: 1.7rem;
}

.testimonial-new .item.overlay:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(63, 63, 63, 0.5));
  mix-blend-mode: normal;
}

@media (max-width: 767.98px) {
  .testimonial-new .item.overlay:before {
    content: none;
  }
}

.testimonial-text-image .item .img-wrapper {
  background-size: cover;
}

.testimonial-text-image .item .img-wrapper img {
  display: block;
  height: 30vh;
  object-position: top;
  visibility: hidden;
}

@media (min-width: 768px) {
  .testimonial-text-image .item .img-wrapper img {
    height: 50vh;
  }
}

.testimonial-text-image .item .testimonial-text {
  background-color: #3f3f3f;
  color: #fff;
  padding: 2rem;
  font-style: italic;
  height: 30rem;
  overflow: auto;
}

@media (min-width: 768px) {
  .testimonial-text-image .item .testimonial-text {
    position: absolute;
    bottom: 0px;
    background: none;
    height: auto;
    overflow: auto;
  }
}

.testimonial-text-image .item .testimonial-text q {
  font-size: 2.6rem;
}

.testimonial-text-image .item .testimonial-text p {
  font-size: 1.7rem;
}

.testimonial-text-image .item.overlay:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(63, 63, 63, 0.5));
  mix-blend-mode: normal;
}

@media (max-width: 767.98px) {
  .testimonial-text-image .item.overlay:before {
    content: none;
  }
}

.testimonial-text-image .owl-three .owl-next {
  bottom: 0;
  font-size: 3rem;
  right: 0;
  top: 0;
  position: absolute;
  width: 40px;
}

.testimonial-text-image .owl-three .owl-next:hover,
.testimonial-text-image .owl-three .owl-next:active {
  background: none;
}

.testimonial-text-image .owl-three .owl-next:focus {
  outline: 0;
}

.testimonial-text-image .owl-three .owl-prev {
  bottom: 0;
  font-size: 3rem;
  left: 0;
  top: 0;
  position: absolute;
  width: 40px;
}

.testimonial-text-image .owl-three .owl-prev:hover,
.testimonial-text-image .owl-three .owl-prev:active {
  background: none;
}

.testimonial-text-image .owl-three .owl-prev:focus {
  outline: 0;
}

.testimonial-text-image .owl-three .owl-dots {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767.98px) {
  .testimonial-text-image .owl-three .owl-dots {
    position: static;
    display: none;
  }
}

.testimonial-text-image .owl-three .owl-dots.disabled {
  display: block;
}

.testimonial-text-image .owl-three .owl-dots .owl-dot.active span {
  background-color: #fff;
}

.testimonial-text-image .owl-three .owl-dots .owl-dot:focus {
  outline: 0;
}

.testimonial-text-image .owl-three .owl-dots .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

@media (max-width: 767.98px) {
  .testimonial-text-image .owl-three .owl-dots .owl-dot span {
    margin: 2rem 1rem;
  }
}

.testimonial-text-image .owl-three .owl-dots .owl-dot span:hover {
  background-color: #fff;
}

.testimonial-text-image .owl-three .owl-next {
  bottom: 0;
  font-size: 3rem;
  right: 0;
  top: 0;
  position: absolute;
  width: 40px;
}

.testimonial-text-image .owl-three .owl-next:hover,
.testimonial-text-image .owl-three .owl-next:active {
  background: none;
}

.testimonial-text-image .owl-three .owl-next:focus {
  outline: 0;
}

.testimonial-text-image .owl-three .owl-prev {
  bottom: 0;
  font-size: 3rem;
  left: 0;
  top: 0;
  position: absolute;
  width: 40px;
}

.testimonial-text-image .owl-three .owl-prev:hover,
.testimonial-text-image .owl-three .owl-prev:active {
  background: none;
}

.testimonial-text-image .owl-three .owl-prev:focus {
  outline: 0;
}

.testimonial-text-image .owl-three .owl-dots {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767.98px) {
  .testimonial-text-image .owl-three .owl-dots {
    position: static;
    display: none;
  }
}

.testimonial-text-image .owl-three .owl-dots.disabled {
  display: block;
}

.testimonial-text-image .owl-three .owl-dots .owl-dot.active span {
  background-color: #fff;
}

.testimonial-text-image .owl-three .owl-dots .owl-dot:focus {
  outline: 0;
}

.testimonial-text-image .owl-three .owl-dots .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

@media (max-width: 767.98px) {
  .testimonial-text-image .owl-three .owl-dots .owl-dot span {
    margin: 2rem 1rem;
  }
}

.testimonial-text-image .owl-three .owl-dots .owl-dot span:hover {
  background-color: #fff;
}

.owl-four .owl-next,
.owl-three .owl-next {
  bottom: 0;
  font-size: 3rem;
  right: 0;
  top: 0;
  position: absolute;
  width: 40px;
}

.owl-four .owl-next:hover,
.owl-four .owl-next:active,
.owl-three .owl-next:hover,
.owl-three .owl-next:active {
  background: none;
}

.owl-four .owl-next:focus,
.owl-three .owl-next:focus {
  outline: 0;
}

.owl-four .owl-prev,
.owl-three .owl-prev {
  bottom: 0;
  font-size: 3rem;
  left: 0;
  top: 0;
  position: absolute;
  width: 40px;
}

.owl-four .owl-prev:hover,
.owl-four .owl-prev:active,
.owl-three .owl-prev:hover,
.owl-three .owl-prev:active {
  background: none;
}

.owl-four .owl-prev:focus,
.owl-three .owl-prev:focus {
  outline: 0;
}

.owl-four .fa-arrow-right,
.owl-three .fa-arrow-right {
  font-size: 30px;
  margin-right: 20px;
}

.owl-four .fa-arrow-left,
.owl-three .fa-arrow-left {
  font-size: 30px;
  margin-left: 20px;
}

.owl-four .owl-nav button,
.owl-three .owl-nav button {
  background-color: unset !important;
  color: #3f3f3f !important;
}

.owl-four .owl-nav button:hover,
.owl-three .owl-nav button:hover {
  background-color: unset !important;
}

.owl-four .owl-dots {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 767.98px) {
  .owl-four .owl-dots {
    position: static;
    display: none;
  }
}

.owl-four .owl-dots.disabled {
  display: block;
}

.owl-four .owl-dots .owl-dot.active span {
  background-color: #fff;
}

.owl-four .owl-dots .owl-dot:focus {
  outline: 0;
}

.owl-four .owl-dots .owl-dot span {
  border-radius: 0;
  background-color: rgba(0,0,0,0);
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin: .5rem;
}

@media (max-width: 767.98px) {
  .owl-four .owl-dots .owl-dot span {
    margin: 2rem 1rem;
  }
}

.owl-four .owl-dots .owl-dot span:hover {
  background-color: #fff;
}

.owl-four .owl-item {
  background: #fff;
}

.owl-four .owl-item .new-testimonial {
  margin: 0;
  padding: 0;
}

.owl-four .owl-item .new-testimonial .col-testimonial-content {
  padding: 30px;
  padding-right: 60px;
}

.latest:after {
  content: "";
  display: table;
  clear: both;
}

.latest h3.line {
  margin-top: 4rem;
}

.latest .card-footer-link {
  float: right;
}

@media (max-width: 991.98px) {
  .latest .tablecontainer .search-input-container {
    display: none;
  }
}

.latest .tablecontainer .hidden {
  display: none !important;
}

@media (max-width: 991.98px) {
  .latest .tablecontainer .item.mobile-event {
    padding-left: 0;
    padding-right: 0;
  }
}

.latest .item {
  margin-bottom: 4rem;
}

@media (max-width: 991.98px) {
  .latest .item.event-box {
    display: none;
  }
}

.latest .item a {
  color: #3f3f3f;
  text-decoration: none;
}

.latest .item .card {
  border-radius: 0;
  border: none;
  height: 100%;
}

.latest .item .card .product-logo {
  height: auto;
  width: auto;
  margin: 10px 0px;
}

.latest .item img {
  height: 100%;
  width: 100%;
}

.latest .item .card-footer {
  padding: 0 0 .75rem 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  margin: 2rem 0;
  color: #949494;
}

.latest .item .card-footer .date {
  float: left;
}

.latest .item .card-footer .comments {
  float: right;
}

@media (max-width: 991.98px) {
  .latest .item .card-footer .comments {
    float: left;
    margin-right: 1rem;
  }
}

.latest .item.mobile-event {
  display: none;
}

@media (max-width: 991.98px) {
  .latest .item.mobile-event {
    display: block;
  }

  .latest .item.mobile-event .card {
    width: 100%;
    height: auto;
  }

  .latest .item.mobile-event .badge {
    font-size: 12px;
    padding: 7px;
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    margin-right: .5rem;
    margin-bottom: .5rem;
  }

  .latest .item.mobile-event .fa-external-link {
    float: right;
  }

  .latest .item.mobile-event .card-footer {
    margin-bottom: 0;
    margin-top: .5rem;
  }

  .latest .item.mobile-event h5 {
    font-weight: normal;
  }

  .latest .item.mobile-event .logo {
    margin-bottom: 2rem;
  }
}

.latest .grid-item {
  float: left;
  margin-bottom: 2rem;
  width: calc((100% - 40px)/3);
}

@media (max-width: 768px) {
  .latest .grid-item {
    width: calc((100% - 40px)/2);
  }
}

@media (max-width: 600px) {
  .latest .grid-item {
    width: calc((100% - 20px)/2);
  }
}

.latest .grid-item a {
  color: #3f3f3f;
  text-decoration: none;
}

.latest .grid-item .card {
  border-radius: 0;
  position: relative;
  border: none;
}

.latest .grid-item img {
  height: 100%;
  width: 100%;
}

.latest .grid-item .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  margin: 2rem 0;
  color: #949494;
}

.latest .grid-item .card-footer .date {
  float: left;
}

.latest .grid-item .card-footer .comments {
  float: right;
}

@media (max-width: 991.98px) {
  .latest .grid-item .card-footer .comments {
    float: left;
    margin-right: 1rem;
  }
}

.latest .grid-item .badge {
  position: absolute;
  top: 16px;
  right: 0;
  padding: 1rem;
  border-radius: 0;
  z-index: 1;
}

.latest .grid-item .badge.badge-webinars {
  background-color: #e3001b;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.latest .grid-item .badge.badge-seminars {
  background-color: #eb5757;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.latest .grid-item .badge.badge-ontour {
  background-color: #404040;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.latest .grid-item .badge.badge-other {
  background-color: #949494;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.latest .grid-item .badge.badge-tag {
  background-color: #3f3f3f;
  font-weight: 500;
  color: #fff;
  top: 51px;
}

.latest.latest-products .card-body {
  display: inline-block;
}

.badge {
  position: absolute;
  top: 16px;
  right: 0;
  padding: 1rem;
  border-radius: 0;
  z-index: 1;
}

.badge i {
  margin-right: .5rem;
}

.badge.badge-webinars {
  background-color: #e3001b;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.badge.badge-seminars {
  background-color: #eb5757;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.badge.badge-ontour {
  background-color: #404040;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.badge.badge-other {
  background-color: #949494;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
}

.badge.badge-tag {
  background-color: #3f3f3f;
  font-weight: 500;
  color: #fff;
  top: 51px;
}

.btn-group-event-filter {
  background-color: #e3001b;
  height: 40px;
  margin-right: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 200px;
}

.btn-group-event-filter .disabled {
  opacity: 1;
}

.btn-group-event-filter .btn:disabled {
  opacity: 1;
}

@media (max-width: 991.98px) {
  .btn-group-event-filter {
    width: 100%;
  }
}

.btn-group-event-filter button {
  color: #fff;
  font-weight: bold;
}

.btn-group-event-filter button:hover {
  color: #fff;
}

.btn-group-event-filter button:after {
  display: none;
}

.btn-group-event-filter button .fa-filter {
  float: right;
  margin-top: 6px;
  font-size: 14px;
}

.btn-group-event-filter .btn-filter-name {
  width: 200px;
  text-align: left;
}

@media (max-width: 991.98px) {
  .btn-group-event-filter .btn-filter-name {
    width: 85%;
  }
}

.btn-group-event-filter .dropdown-toggle {
  width: 35px;
  background-color: #eb5757;
}

.btn-group-event-filter .dropdown-toggle:hover {
  background-color: #e3001b;
}

.btn-group-event-filter .dropdown-menu {
  transform: translate3d(0px, 38px, 0px) !important;
  width: 235px;
}

@media (max-width: 991.98px) {
  .btn-group-event-filter .dropdown-menu {
    width: 100%;
  }
}

.btn-group-event-filter .dropdown-menu li {
  height: auto;
  min-height: 30px;
  padding-left: 5px;
  font-size: 14px;
  padding-bottom: 10px;
}

.btn-group-event-filter .dropdown-menu li a {
  display: flex;
  text-decoration: none;
  color: #000;
}

.btn-group-event-filter .dropdown-menu li a input {
  margin-top: 3px;
}

.btn-group-event-filter .caret-x {
  display: none;
}

#myTable {
  margin-bottom: 200px;
}

.text-image-half strong {
  margin-bottom: 1rem;
  display: inline-block;
}

.text-image-half small {
  margin-top: .8rem;
  display: inline-block;
}

.image-gallery .image-text-overlay a {
  text-decoration: none;
  color: #3f3f3f;
}

.image-gallery .image-text-overlay a .gallery-image-text {
  display: block;
  margin-top: 1rem;
  margin-bottom: -0.5rem;
}

.image-gallery .gallery-image-copyright {
  position: absolute;
  transform: rotate(-90deg) translate(100%, 0%);
  right: 1em;
  transform-origin: right;
}

.image-gallery .item {
  margin-bottom: 3rem;
}

.lightbox .lb-container .lb-nav a.lb-prev {
  background: url("../img/pfeil-links.svg") 2% 48% no-repeat;
}

.lightbox .lb-container .lb-nav a.lb-prev:hover,
.lightbox .lb-container .lb-nav a.lb-prev:focus,
.lightbox .lb-container .lb-nav a.lb-prev:active,
.lightbox .lb-container .lb-nav a.lb-prev:visited {
  background: url("../img/pfeil-links.svg") 2% 48% no-repeat;
}

.lightbox .lb-container .lb-nav a.lb-next {
  background: url("../img/pfeil-rechts.svg") 98% 48% no-repeat;
}

.lightbox .lb-container .lb-nav a.lb-next:hover,
.lightbox .lb-container .lb-nav a.lb-next:focus,
.lightbox .lb-container .lb-nav a.lb-next:active,
.lightbox .lb-container .lb-nav a.lb-next:visited {
  background: url("../img/pfeil-rechts.svg") 98% 48% no-repeat;
}

.lightbox .lb-container .lb-image {
  border: none;
  border-radius: 0;
}

.lightbox .lb-dataContainer .lb-data .lb-details .lb-caption {
  font-size: 1.6rem;
  line-height: 1.6;
  font-weight: 500;
  color: #fff;
}

.lightbox .lb-dataContainer .lb-data .lb-details .lb-number {
  color: #fff;
}

.lightbox .lb-dataContainer .lb-closeContainer a {
  background: url("../img/close-icon.svg") 100% 0 no-repeat;
  width: 25px;
  height: 25px;
}

.lightboxOverlay {
  background-color: #3f3f3f;
  opacity: .9;
}

.downloads:after {
  content: "";
  display: table;
  clear: both;
}

.downloads .box {
  background-color: #fff;
  width: 24%;
  margin: .5rem;
  float: left;
  min-height: 106px;
}

@media (min-width: 992px)and (max-width: 1199px) {
  .downloads .box {
    width: 23.9%;
  }
}

@media (max-width: 991px) {
  .downloads .box {
    min-height: 84px;
    width: 48.5%;
  }
}

@media (max-width: 991.98px) {
  .downloads .box {
    min-height: 0;
    width: 100%;
  }
}

.downloads a {
  color: #3f3f3f;
  padding: 2rem;
  text-decoration: none;
  display: inline-block;
}

.downloads span {
  float: left;
  margin-right: 1rem;
}

.downloads p {
  margin-bottom: 0;
  overflow: hidden;
}

.event-detail-intro {
  margin-top: 3rem;
}

.event-detail-intro:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 767.98px) {
  .event-detail-intro h3.line {
    display: block;
  }
}

.event-detail-intro section {
  display: grid;
}

@media (max-width: 767.98px) {
  .event-detail-intro section {
    display: block;
  }
}

.event-detail-intro .topics-goals {
  float: left;
}

.event-detail-intro ul p {
  color: #3f3f3f;
  margin-bottom: 0;
}

.event-detail-intro .events {
  background-color: #fff;
  margin-bottom: 2rem;
  padding-bottom: 3rem;
  color: #3f3f3f;
}

.event-detail-intro .events .event-card-1 {
  background-color: #dadada;
  padding: 0rem 3rem;
}

@media (max-width: 767.98px) {
  .event-detail-intro .events .event-card-1 {
    padding: 0rem 2rem;
  }
}

.event-detail-intro .events .event-card-2 {
  background-color: #ececec;
  padding: 0rem 3rem;
}

.event-detail-intro .events .event-card-3 {
  background-color: #dadada;
  padding: 0rem 3rem;
}

.event-detail-intro .events .event-speaker {
  margin-left: 27px;
}

.event-detail-intro .events .event-group {
  margin-left: 27px;
  margin-top: 8px;
}

.event-detail-intro .events span.event-location {
  margin-left: 23px;
  display: inline-block;
  margin-top: -24px;
}

.event-detail-intro .events .event-detail-container {
  padding: 2rem 3rem 1rem 3rem;
}

@media (max-width: 767.98px) {
  .event-detail-intro .events .event-detail-container {
    padding: 2rem 2rem 1rem 2rem;
  }
}

.event-detail-intro .events .event-detail-container i.fa-users-class {
  font-size: 14.5px;
}

.event-detail-intro .events .event-detail-container>* {
  margin-bottom: 2rem;
}

.event-detail-intro .events .event-detail-container i.fa-user-friends {
  font-size: 1.45rem;
}

.event-detail-intro .events .event-date-time {
  font-size: 18px;
}

.event-detail-intro .events .event-date-time p {
  font-weight: bold;
}

.event-detail-intro .events .event-date-time p i {
  margin-right: 8px;
}

.event-detail-intro .events .event-location-container p i {
  margin-right: 8px;
  min-width: 18px;
}

.event-detail-intro .events .event-link-intern {
  text-align: center;
  display: block;
  float: unset;
  background-color: #eca247;
  color: #fff;
  text-decoration: none !important;
  padding: 12px !important;
  cursor: pointer;
  margin: 0rem 3rem;
}

.event-detail-intro .events .event-link-intern a {
  color: #fff;
}

.event-detail-intro .events .product-logo {
  height: 25px;
  margin: 3rem;
}

@media (max-width: 767.98px) {
  .event-detail-intro .events .product-logo {
    margin: 3rem 2rem;
  }
}

.event-detail-intro .events hr {
  border-bottom: 1px solid #545454;
}

.event-detail-intro .events hr:last-of-type {
  display: none;
}

.event-detail-intro .events h3:not(:first-child) {
  margin-top: 2rem;
}

.event-detail-intro .events .card {
  color: #3f3f3f;
  border-radius: 0;
  border: none;
  display: flex;
}

.event-detail-intro .events .card h4 {
  margin-bottom: 0;
}

.event-detail-intro .events .card .card-body {
  padding: 2rem 0;
  margin-bottom: -1rem;
}

.event-detail-intro .events .card .ptop0 {
  padding-top: 0px;
}

.event-detail-intro .events .card img {
  margin-right: 1rem;
  float: left;
  position: relative;
  border-radius: 50%;
  filter: grayscale(100%);
  width: 70px;
  height: 70px;
  object-fit: cover;
}

.event-detail-intro .events .card .text-muted {
  color: #3f3f3f !important;
}

.event-detail-intro .events .card small {
  font-size: 1.7rem;
}

.event-side-overview-container {
  display: inline-block;
  float: right;
  width: 35%;
  padding: 0px 0px 2rem 2rem;
}

@media (max-width: 991.98px) {
  .event-side-overview-container {
    width: 45%;
  }
}

@media (max-width: 767.98px) {
  .event-side-overview-container {
    width: 100%;
    padding: 1rem 0rem;
  }
}

.share:after {
  content: "";
  display: table;
  clear: both;
}

.share .share-options {
  float: right;
}

.share strong {
  display: inline;
}

.share img {
  height: 24px;
  margin: .5rem;
}

.share img.last-of-type:before {
  content: "|";
}

.latestpress .press .card {
  width: 100%;
}

.latestpress .press .card .card-header {
  background: #fff;
  border: none;
}

.latestpress .press .card .card-header .right {
  float: right;
}

.latestpress .press .card .card-footer {
  padding: .75rem 1.25rem;
  background-color: #fff;
  border: none;
  color: #000;
  position: static;
}

.category-hero-left:after,
.category-hero-right:after {
  content: "";
  display: table;
  clear: both;
}

.category-hero-left .row,
.category-hero-right .row {
  margin-bottom: 1.3333333333rem;
}

@media (max-width: 991.98px) {
  .category-hero-left .row,
  .category-hero-right .row {
    margin-bottom: 0;
  }
}

.category-hero-left .row a,
.category-hero-right .row a {
  color: #3f3f3f;
  text-decoration: none;
}

.category-hero-left .row a .card,
.category-hero-right .row a .card {
  border-radius: 0;
  border: none;
  height: 100%;
  position: relative;
}

.category-hero-left .row a .card .video-head,
.category-hero-right .row a .card .video-head {
  position: relative;
}

.category-hero-left .row a .card img,
.category-hero-right .row a .card img {
  height: auto;
  width: 100%;
}

.category-hero-left .row a .card .card-footer,
.category-hero-right .row a .card .card-footer {
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-top: 0;
  color: #949494;
}

.category-hero-left .row a .card .card-footer .date,
.category-hero-right .row a .card .card-footer .date {
  float: left;
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card .card-footer .date,
  .category-hero-right .row a .card .card-footer .date {
    float: none;
  }
}

@media (max-width: 767.98px) {
  .category-hero-left .row a .card .card-footer .date,
  .category-hero-right .row a .card .card-footer .date {
    float: left;
  }
}

.category-hero-left .row a .card .card-footer .comments,
.category-hero-right .row a .card .card-footer .comments {
  float: right;
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card .card-footer .comments,
  .category-hero-right .row a .card .card-footer .comments {
    float: none;
  }
}

@media (max-width: 767.98px) {
  .category-hero-left .row a .card .card-footer .comments,
  .category-hero-right .row a .card .card-footer .comments {
    float: right;
  }
}

.category-hero-left .row a .card.hero,
.category-hero-right .row a .card.hero {
  height: calc(100% - 1.2rem);
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card.hero,
  .category-hero-right .row a .card.hero {
    height: auto;
  }
}

.category-hero-left .row a .card.hero img,
.category-hero-right .row a .card.hero img {
  height: 662px;
}

@media (max-width: 1199.98px) {
  .category-hero-left .row a .card.hero img,
  .category-hero-right .row a .card.hero img {
    height: 595px;
  }
}

@media (max-width: 991.98px) {
  .category-hero-left .row a .card.hero img,
  .category-hero-right .row a .card.hero img {
    height: 510px;
  }
}

@media (max-width: 767.98px) {
  .category-hero-left .row a .card.hero img,
  .category-hero-right .row a .card.hero img {
    height: auto;
  }
}

main div.content-support h1,
main div.content-support h2,
main div.content-support h3 {
  margin-top: 1em;
}

main div.content-support img {
  max-width: 100%;
}

main div.content-support .alert {
  padding: 2rem;
  margin: 2rem 0;
}

.help-content {
  margin-left: 20%;
  min-height: calc(100vh - 20px);
  position: relative;
}

@media (max-width: 991.98px) {
  .help-content {
    margin-left: 0;
  }
}

nav#support-in-page-nav {
  text-align: center;
  margin: 2rem 10px 2rem 0;
  min-height: 30rem;
  position: relative;
}

@media (max-width: 991.98px) {
  nav#support-in-page-nav {
    display: none;
  }
}

nav#support-in-page-nav:before {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-image: linear-gradient(to bottom, rgba(63, 63, 63, 0.3), rgba(63, 63, 63, 0));
}

nav#support-in-page-nav a.list-group-item {
  color: #3f3f3f;
  text-decoration: none;
  text-align: right;
  background-color: rgba(0,0,0,0);
  border: 0;
  border-color: #e2001a;
  -webkit-transition: all ease .3s;
  -moz-transition: all ease .3s;
  -ms-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
}

nav#support-in-page-nav a.list-group-item.active {
  border-right: 1rem solid #e2001a;
}

nav#support-in-page-nav a.list-group-item:hover {
  background-color: rgba(226,0,26,.2);
}

nav#support-in-page-nav a.list-group-item.subheading {
  font-size: .8em;
  color: rgba(63,63,63,.8);
}

nav#support-in-page-nav a.list-group-item.subheading.active {
  border-right: .5rem solid #e2001a;
}

#hilfe-content .sticky-top {
  position: -webkit-sticky;
  top: 10px;
}

.side-navigation {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 20%;
  overflow: scroll;
}

@media (max-width: 991.98px) {
  .side-navigation {
    width: 80%;
    z-index: 100;
    left: -80%;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
  }

  .side-navigation.open {
    left: 0;
  }
}

div.help-side-navigation {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
}

div.help-side-navigation .searchbox {
  background-color: #fff;
  border: 1px solid #404040;
  color: #404040;
  margin: 15px;
}

div.help-side-navigation .searchbox form .input-group-text {
  background-color: rgba(0,0,0,0);
  color: #404040;
  border: 0;
  font-size: 1em;
}

div.help-side-navigation .searchbox form input.form-control {
  background-color: rgba(0,0,0,0);
  padding-left: 0;
  padding-right: .75rem;
  color: #404040;
}

@media (max-width: 1199.98px) {
  div.help-side-navigation .searchbox form input.form-control {
    font-size: .75em;
  }
}

div.help-side-navigation .searchbox form input.form-control:active,
div.help-side-navigation .searchbox form input.form-control:focus {
  box-shadow: none;
}

div.help-side-navigation .searchbox form input.form-control::placeholder {
  color: rgba(64,64,64,.5);
}

div.help-side-navigation .searchbox form #pressSearchSubmit:hover {
  cursor: pointer;
}

div.help-side-navigation span.parent-marker {
  background-color: #404040;
  color: #fff;
  float: right;
  padding: 0 5px 3px 5px;
  margin-top: -35px;
  margin-right: 1px;
}

div.help-side-navigation span.parent-marker.toggle {
  padding: 0 7px 3px 7px;
}

div.help-side-navigation span.parent-marker:hover {
  cursor: pointer;
}

div.help-side-navigation .nav-ul-depth-1 {
  padding-left: 0;
  background: #fff;
}

div.help-side-navigation .nav-ul-depth-1 .nav-li-depth-1 {
  list-style: none;
}

div.help-side-navigation .nav-ul-depth-1 .nav-li-depth-1 .nav-href-depth-1 {
  color: #404040;
  text-decoration: none;
  font-weight: normal;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
}

div.help-side-navigation .nav-ul-depth-1 .nav-li-depth-1 .active {
  background: #ccc;
}

div.help-side-navigation .nav-ul-depth-2 {
  background: #dcdcdc;
  padding-left: 0;
}

div.help-side-navigation .nav-ul-depth-2 .nav-li-depth-2 {
  list-style: none;
}

div.help-side-navigation .nav-ul-depth-2 .nav-li-depth-2 .nav-href-depth-2 {
  text-decoration: none;
  color: #404040;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}

div.help-side-navigation .nav-ul-depth-2 .nav-li-depth-2 .active {
  background: #ccc;
}

div.help-side-navigation .nav-ul-depth-3 {
  background: #606060;
  padding-left: 0;
}

div.help-side-navigation .nav-ul-depth-3 .nav-li-depth-3 {
  list-style: none;
}

div.help-side-navigation .nav-ul-depth-3 .nav-li-depth-3 .nav-href-depth-3 {
  text-decoration: none;
  color: #404040;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 35px;
}

div.help-side-navigation .nav-ul-depth-3 .nav-li-depth-3 .active {
  background: #ccc;
}

div.help-side-navigation .nav-ul-depth-4 {
  background: #484848;
  display: block;
  padding-left: 0;
}

div.help-side-navigation .nav-ul-depth-4 .nav-li-depth-4 {
  list-style: none;
}

div.help-side-navigation .nav-ul-depth-4 .nav-li-depth-4 .nav-href-depth-4 {
  text-decoration: none;
  color: #404040;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
}

div.help-side-navigation .nav-ul-depth-4 .nav-li-depth-4 .active {
  background: #14aa96;
}

.sidenav {
  background-color: #545454;
  padding: 2.4rem;
  width: 350px;
  height: 100vh;
}

.sidenav a {
  color: #fff;
  text-decoration: none;
}

.sidenav a:hover {
  color: rgba(255,255,255,.5);
}

.sidenav a span {
  display: block;
}

.sidenav .searchbox {
  background-color: #949494;
  border: 1px solid rgba(255,255,255,.5);
  color: rgba(255,255,255,.5);
  margin-bottom: 2.4rem;
}

.sidenav .searchbox form .input-group-text {
  background-color: rgba(0,0,0,0);
  color: rgba(255,255,255,.5);
  border: 0;
  font-size: 1.6em;
  padding: 1rem 0 1rem 1.6rem;
}

@media (max-width: 991.98px) {
  .sidenav .searchbox form .input-group-text {
    font-size: 1.2em;
    padding: 1rem 0 1rem 1.2rem;
  }
}

@media (max-width: 991.98px) {
  .sidenav .searchbox form .input-group-prepend {
    font-size: 1.2em;
  }
}

.sidenav .searchbox form input.form-control {
  background-color: rgba(0,0,0,0);
  color: #fff;
}

.sidenav .searchbox form input.form-control:active,
.sidenav .searchbox form input.form-control:focus {
  box-shadow: none;
}

.sidenav .searchbox form input.form-control::placeholder {
  color: rgba(255,255,255,.5);
}

.sidenav .dd-item {
  padding: 1.6rem 0;
}

.sidenav .dd-item.visited {
  background-color: #3f3f3f;
  margin-left: -2.4rem;
  margin-right: -2.4rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.sidenav ul {
  font-weight: 600;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidenav ul.topics ul {
  list-style-type: none;
  font-weight: 500;
}

.sidenav ul.topics li.parent ul,
.sidenav ul.topics>li.active ul {
  display: block;
}

.sidenav ul.topics li.parent ul>li,
.sidenav ul.topics>li.active ul>li {
  padding: 1.0666666667rem 1.6rem;
}

.sidenav ul.topics li.parent ul li.active,
.sidenav ul.topics>li.active ul li.active {
  padding: 0;
}

.sidenav ul.topics li.parent ul li.active>a,
.sidenav ul.topics>li.active ul li.active>a {
  background: #e2001a;
  display: block;
  padding: 1.0666666667rem 1.6rem;
  position: relative;
}

.sidenav ul.topics li.parent ul li.active>a svg,
.sidenav ul.topics>li.active ul li.active>a svg {
  position: absolute;
  right: 1rem;
  top: 1.2rem;
}

#show-sidenav {
  z-index: 10;
  position: fixed;
  left: 0;
  top: 74%;
  display: block;
  padding: 1em;
  background-color: #888;
  display: block;
}

@media (min-width: 768px) {
  #show-sidenav {
    display: none;
  }
}

.help-content header>nav.navbar ul.navbar-nav li a.nav-link {
  white-space: nowrap;
}

.help-content .navbar-wrapper {
  white-space: nowrap;
}

@media (max-width: 1200px) {
  .help-content header nav.navbar.navbar-expand-lg .navbar-toggler {
    display: inline-block;
  }
}

.support-overview,
.content-support {
  margin-bottom: 20px;
  padding: 0 0 0 20px;
}

.support-overview .press-tile,
.content-support .press-tile {
  margin-top: -30px;
}

.support-overview .row,
.content-support .row {
  margin: 0;
}

.support-overview .row .col-md-6.press-overview-container,
.support-overview .row .col-md-12.press-overview-container,
.content-support .row .col-md-6.press-overview-container,
.content-support .row .col-md-12.press-overview-container {
  margin-bottom: 15px;
}

.support-overview .row .col-md-6.press-tile,
.support-overview .row .col-md-12.press-tile,
.content-support .row .col-md-6.press-tile,
.content-support .row .col-md-12.press-tile {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (max-width: 767.98px) {
  .support-overview .row .col-md-6.press-tile .press-thumbnail-container,
  .support-overview .row .col-md-12.press-tile .press-thumbnail-container,
  .content-support .row .col-md-6.press-tile .press-thumbnail-container,
  .content-support .row .col-md-12.press-tile .press-thumbnail-container {
    display: none;
  }
}

.support-overview .row .col-md-6.press-tile .press-thumbnail-container .background,
.support-overview .row .col-md-12.press-tile .press-thumbnail-container .background,
.content-support .row .col-md-6.press-tile .press-thumbnail-container .background,
.content-support .row .col-md-12.press-tile .press-thumbnail-container .background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.support-overview .row .col-md-6.press-tile .fal,
.support-overview .row .col-md-12.press-tile .fal,
.content-support .row .col-md-6.press-tile .fal,
.content-support .row .col-md-12.press-tile .fal {
  color: #e2001a;
}

.support-overview .row .col-md-6.press-tile .svg-fix,
.support-overview .row .col-md-12.press-tile .svg-fix,
.content-support .row .col-md-6.press-tile .svg-fix,
.content-support .row .col-md-12.press-tile .svg-fix {
  width: 200px;
  height: 200px;
  background: #efefef;
  padding: 20px;
}

@media (max-width: 991.98px) {
  .support-overview .row .col-md-6.press-tile .svg-fix,
  .support-overview .row .col-md-12.press-tile .svg-fix,
  .content-support .row .col-md-6.press-tile .svg-fix,
  .content-support .row .col-md-12.press-tile .svg-fix {
    height: 150px;
  }
}

.support-overview .row .col-md-6 .item-old,
.support-overview .row .col-md-12 .item-old,
.content-support .row .col-md-6 .item-old,
.content-support .row .col-md-12 .item-old {
  background-color: #fff;
  border: none;
  border-radius: 0;
  color: #3f3f3f;
  display: block;
  height: 100%;
  text-decoration: none;
  padding: 2.4rem;
}

.support-overview .row .col-md-6 .item,
.support-overview .row .col-md-12 .item,
.content-support .row .col-md-6 .item,
.content-support .row .col-md-12 .item {
  background-color: #e2001a;
  background-position: -2rem -6rem;
  background-repeat: no-repeat;
  background-size: 30%;
  border: none;
  border-radius: 0;
  color: #fff;
  display: block;
  height: 100%;
  text-decoration: none;
  min-height: 215px;
  padding: 3rem;
}

.support-overview .row .col-md-6 .item.buchhandel,
.support-overview .row .col-md-12 .item.buchhandel,
.content-support .row .col-md-6 .item.buchhandel,
.content-support .row .col-md-12 .item.buchhandel {
  background-image: url("../img/hilfe-buchhandel-icon.svg");
}

.support-overview .row .col-md-6 .item.empfehlungen,
.support-overview .row .col-md-12 .item.empfehlungen,
.content-support .row .col-md-6 .item.empfehlungen,
.content-support .row .col-md-12 .item.empfehlungen {
  background-image: url("../img/hilfe-empfehlungen-icon.svg");
}

.support-overview .row .col-md-6 .item.datenanlieferung,
.support-overview .row .col-md-12 .item.datenanlieferung,
.content-support .row .col-md-6 .item.datenanlieferung,
.content-support .row .col-md-12 .item.datenanlieferung {
  background-image: url("../img/hilfe-datenanlieferung-icon.svg");
}

.support-overview .row .col-md-6 .item.datenbezug,
.support-overview .row .col-md-12 .item.datenbezug,
.content-support .row .col-md-6 .item.datenbezug,
.content-support .row .col-md-12 .item.datenbezug {
  background-image: url("../img/hilfe-datenbezug-icon.svg");
}

.support-overview .row .col-md-6 .item.zis,
.support-overview .row .col-md-12 .item.zis,
.content-support .row .col-md-6 .item.zis,
.content-support .row .col-md-12 .item.zis {
  background-image: url("../img/hilfe-zis-icon.svg");
}

.support-overview .row .col-md-6 .item.neu,
.support-overview .row .col-md-12 .item.neu,
.content-support .row .col-md-6 .item.neu,
.content-support .row .col-md-12 .item.neu {
  background-image: url("../img/hilfe-neu-icon.svg");
}

.support-overview .row .col-md-6 .item.colorbg,
.support-overview .row .col-md-12 .item.colorbg,
.content-support .row .col-md-6 .item.colorbg,
.content-support .row .col-md-12 .item.colorbg {
  background-color: #e1e1e1;
  background-image: url("../img/hilfe-kontakt-icon.svg");
  color: #3f3f3f;
}

@media (max-width: 767.98px) {
  .support-overview .row .col-md-6 .item,
  .support-overview .row .col-md-12 .item,
  .content-support .row .col-md-6 .item,
  .content-support .row .col-md-12 .item {
    height: auto;
    min-height: 0;
  }
}

.support-overview .row .col-md-6 .item h4,
.support-overview .row .col-md-12 .item h4,
.content-support .row .col-md-6 .item h4,
.content-support .row .col-md-12 .item h4 {
  margin-bottom: 2rem;
}

.support-overview .row .col-md-6 .item h2,
.support-overview .row .col-md-12 .item h2,
.content-support .row .col-md-6 .item h2,
.content-support .row .col-md-12 .item h2 {
  margin-top: 0;
}

.support-overview .row .col-md-6.press-tile .item,
.support-overview .row .col-md-12.press-tile .item,
.content-support .row .col-md-6.press-tile .item,
.content-support .row .col-md-12.press-tile .item {
  padding: 0;
}

.support-overview .row .col-md-6.press-tile .item .container,
.support-overview .row .col-md-12.press-tile .item .container,
.content-support .row .col-md-6.press-tile .item .container,
.content-support .row .col-md-12.press-tile .item .container {
  height: 100%;
  padding: 0;
}

.support-overview .row .col-md-6.press-tile .item .container .row,
.support-overview .row .col-md-12.press-tile .item .container .row,
.content-support .row .col-md-6.press-tile .item .container .row,
.content-support .row .col-md-12.press-tile .item .container .row {
  height: 100%;
}

.support-overview .row .col-md-6.press-tile .item .container .row>div:first-of-type,
.support-overview .row .col-md-12.press-tile .item .container .row>div:first-of-type,
.content-support .row .col-md-6.press-tile .item .container .row>div:first-of-type,
.content-support .row .col-md-12.press-tile .item .container .row>div:first-of-type {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}

.support-overview .row .col-md-6.press-tile .item .container .row>div:last-of-type,
.support-overview .row .col-md-12.press-tile .item .container .row>div:last-of-type,
.content-support .row .col-md-6.press-tile .item .container .row>div:last-of-type,
.content-support .row .col-md-12.press-tile .item .container .row>div:last-of-type {
  padding: 2.4rem 2.4rem 2.4rem calc(2.4rem + 15px);
}

.support-overview .row .col-md-6.contact .item,
.support-overview .row .col-md-12.contact .item,
.content-support .row .col-md-6.contact .item,
.content-support .row .col-md-12.contact .item {
  padding: 0;
}

.support-overview .row .col-md-6.contact .item .container,
.support-overview .row .col-md-12.contact .item .container,
.content-support .row .col-md-6.contact .item .container,
.content-support .row .col-md-12.contact .item .container {
  height: 100%;
  padding: 0;
}

.support-overview .row .col-md-6.contact .item .container .row,
.support-overview .row .col-md-12.contact .item .container .row,
.content-support .row .col-md-6.contact .item .container .row,
.content-support .row .col-md-12.contact .item .container .row {
  height: 100%;
}

.support-overview .row .col-md-6.contact .item .container .row>div:first-of-type,
.support-overview .row .col-md-12.contact .item .container .row>div:first-of-type,
.content-support .row .col-md-6.contact .item .container .row>div:first-of-type,
.content-support .row .col-md-12.contact .item .container .row>div:first-of-type {
  padding: 2.4rem 2.4rem 2.4rem calc(2.4rem + 15px);
}

.support-overview .row .col-md-6.contact .item .container .row>div:last-of-type,
.support-overview .row .col-md-12.contact .item .container .row>div:last-of-type,
.content-support .row .col-md-6.contact .item .container .row>div:last-of-type,
.content-support .row .col-md-12.contact .item .container .row>div:last-of-type {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
}

.support-overview .row .col-md-6.contact .item .container .row .text-more-info,
.support-overview .row .col-md-12.contact .item .container .row .text-more-info,
.content-support .row .col-md-6.contact .item .container .row .text-more-info,
.content-support .row .col-md-12.contact .item .container .row .text-more-info {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.content-support:not(.support-overview) .row .col-md-6 .item,
.content-support:not(.support-overview) .row .col-md-12 .item {
  background-image: none;
}

.content-support .hilfe-description-list .redactor-content {
  line-height: 18px;
  font-size: 14px;
  background-color: #fff;
  margin-bottom: -8px;
  padding: 0;
}

.content-support .hilfe-description-list .redactor-content p {
  margin: 0;
}

section.humans {
  margin-top: 3rem;
}

section.humans .row>div {
  margin-bottom: 4rem;
}

section.humans .card {
  height: 100%;
  border: none;
  border-radius: 0;
}

section.humans img {
  object-fit: cover;
}

section.humans .more h2,
section.humans .more h3,
section.humans .more h4,
section.humans .more h5 {
  font-size: 20px;
  font-size: 2rem;
}

html#boersenvereinsgruppe {
  min-height: 100%;
}

html#boersenvereinsgruppe body {
  min-height: 100%;
  background-color: #fff;
  margin: 0;
  font-family: "Graphik-Web",sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #404040;
}

@media (max-width: 991.98px) {
  html#boersenvereinsgruppe body {
    margin: 0;
  }
}

html#boersenvereinsgruppe body section {
  margin-bottom: 0;
}

html#boersenvereinsgruppe #wrap-main {
  min-height: 100%;
}

html#boersenvereinsgruppe main {
  overflow: auto;
  overflow-x: hidden;
}

html#boersenvereinsgruppe .help-content main {
  overflow: unset;
}

html#boersenvereinsgruppe body:before {
  content: "";
  height: 100%;
  float: left;
  width: 0;
  margin-top: -32767px;
}

html#boersenvereinsgruppe #help-body {
  margin-bottom: 0px;
}

html#boersenvereinsgruppe .slide-item {
  position: relative;
  height: 45vh;
}

html#boersenvereinsgruppe .overlay-red {
  position: absolute;
  inset: 0;
  background-color: rgba(85,0,10,.5215686275);
}

html#boersenvereinsgruppe .slide-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
  max-width: min(85%,600px);
  color: #fff;
}

html#boersenvereinsgruppe .jp-hero-slider-title {
  hyphens: manual;
  font-size: 52px;
  font-size: 5.2rem;
  margin: 0;
  margin-bottom: 1rem;
}

html#boersenvereinsgruppe .carousel-indicators {
  position: absolute;
  bottom: 10%;
  left: 10%;
  list-style: none;
  display: flex;
  z-index: 15;
  gap: 1rem;
  padding-left: 0;
  margin: 0;
  right: unset;
}

html#boersenvereinsgruppe .carousel-indicators li {
  box-sizing: content-box;
  flex: none;
  border: 1px solid #fff;
  width: 1rem;
  height: 1rem;
  margin-right: 0;
  margin-left: 0;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(0,0,0,0);
  background-clip: padding-box;
  opacity: .5;
  transition: opacity .6s ease;
}

html#boersenvereinsgruppe .carousel-indicators .active {
  background-color: #fff;
}

@media (min-width: 640px) {
  html#boersenvereinsgruppe .jp-hero-slider-title {
    margin-bottom: 0;
  }
}

html#boersenvereinsgruppe .navigation-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
}

html#boersenvereinsgruppe .navigation-logo {
  width: 12rem;
}

html#boersenvereinsgruppe .navigation-items {
  display: flex;
  align-items: center;
  gap: 2rem;
  color: #404040;
}

html#boersenvereinsgruppe .navigation-link-items {
  display: flex;
  align-items: center;
  gap: 2rem;
}

html#boersenvereinsgruppe a {
  text-decoration: none;
  color: #404040;
  cursor: pointer;
}

html#boersenvereinsgruppe .dropdown-menu {
  top: .5rem !important;
}

html#boersenvereinsgruppe .dropdown-item {
  font-size: 1.7rem;
  margin-bottom: .5rem;
}

html#boersenvereinsgruppe .navigation-search-icon-con {
  background-color: #e3001b;
  display: flex;
  padding: 1rem;
  cursor: pointer;
}

html#boersenvereinsgruppe .navigation-lang-picker {
  display: flex;
  gap: .42rem;
}

html#boersenvereinsgruppe .navigation-lang-picker-active {
  font-weight: 900;
}

html#boersenvereinsgruppe .navigation-mobile-nav-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

html#boersenvereinsgruppe .navigation-mobile-nav-item {
  margin-bottom: 2rem;
}

html#boersenvereinsgruppe .show-mobile-nav-items-btn {
  border: 1px solid #e3001b;
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .navigation-logo {
    width: unset;
  }

  html#boersenvereinsgruppe .navigation-search-icon-con {
    padding: 1.33rem;
  }
}

html#boersenvereinsgruppe .jp-categories {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  list-style: none;
}

html#boersenvereinsgruppe .jp-categories-title {
  font-size: 4rem;
  margin-bottom: 3.33rem;
  text-align: center;
}

html#boersenvereinsgruppe .jp-category-image {
  filter: brightness(0.8);
}

html#boersenvereinsgruppe .jp-category {
  position: relative;
  aspect-ratio: .85/1;
  border-bottom: 12px solid #e3001b;
  display: flex;
}

html#boersenvereinsgruppe .jp-category-title {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
}

@media (min-width: 640px) {
  html#boersenvereinsgruppe .jp-categories {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .jp-categories {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  html#boersenvereinsgruppe .jp-categories-title {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  html#boersenvereinsgruppe .jp-categories {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

html#boersenvereinsgruppe .separator-con {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

html#boersenvereinsgruppe .separator-title {
  margin-bottom: .5rem;
  flex-shrink: 0;
  max-width: 100%;
}

html#boersenvereinsgruppe .red-separator {
  width: 100%;
  height: 3px;
  background-color: #e3001b;
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .separator-title {
    font-size: 2.66rem;
  }
}

html#boersenvereinsgruppe .cs-con {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

html#boersenvereinsgruppe .cs-content-con {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 3rem;
  width: 100%;
}

html#boersenvereinsgruppe .cs-title {
  margin: 0;
}

html#boersenvereinsgruppe .cs-freetext ul {
  list-style: disc;
}

html#boersenvereinsgruppe .cs-social-icons {
  display: flex;
  gap: 2rem;
}

html#boersenvereinsgruppe .cs-image-con {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

html#boersenvereinsgruppe .cs-image-con img {
  object-fit: contain;
  max-width: 100%;
  height: 100%;
}

html#boersenvereinsgruppe .cs-image-con p {
  margin: 0;
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .cs-con {
    flex-direction: row;
    flex-flow: var(--flexFlow);
    gap: 4rem;
  }

  html#boersenvereinsgruppe .cs-content-con {
    margin-bottom: 0;
  }

  html#boersenvereinsgruppe .cs-title {
    margin-bottom: 1rem;
  }
}

html#boersenvereinsgruppe .jb-link-con {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  fill: red;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  padding-right: 30px;
}

html#boersenvereinsgruppe .jb-link-con::after {
  position: absolute;
  content: "";
  width: 25px;
  height: 25px;
  right: 0;
  background-image: url("../../../web/bvg/ctaArrow.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

html#boersenvereinsgruppe .jb-link-con:hover,
html#boersenvereinsgruppe .jb-link-con:focus,
html#boersenvereinsgruppe .jb-link-con:active {
  color: #404040;
  text-decoration: underline #404040;
}

html#boersenvereinsgruppe .jp-images-con {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5rem;
}

html#boersenvereinsgruppe .jp-con {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

html#boersenvereinsgruppe .jp-job-cards {
  display: grid;
  gap: 2rem;
}

@media (min-width: 640px) {
  html#boersenvereinsgruppe .jp-job-cards {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  html#boersenvereinsgruppe .jp-job-cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

html#boersenvereinsgruppe .jobcard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2.5rem 1.5rem;
  border-bottom: 12px solid #e3001b;
  background-color: #f3f3f3;
}

html#boersenvereinsgruppe .jobcard-title {
  font-weight: 500;
  font-size: 2.1rem;
}

html#boersenvereinsgruppe .jobcard-state {
  color: #e3001b;
}

html#boersenvereinsgruppe .testimonial-card {
  position: relative;
}

html#boersenvereinsgruppe .testimonial-image {
  display: block;
}

html#boersenvereinsgruppe .testimonial-image .fill-picture {
  position: relative;
  aspect-ratio: 1/1.1;
  max-width: 340px;
  margin-bottom: -4rem;
}

html#boersenvereinsgruppe .testimonial-content-con {
  background-color: #f3f3f3;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 6rem;
}

html#boersenvereinsgruppe .testimonial-testimonial {
  font-weight: 600;
}

html#boersenvereinsgruppe .testimonial-employee {
  display: flex;
  flex-direction: column;
}

html#boersenvereinsgruppe .testimonial-employee-name {
  font-weight: 600;
}

html#boersenvereinsgruppe .testimonials-prev-btn,
html#boersenvereinsgruppe .testimonials-next-btn {
  display: none;
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .testimonial-image {
    display: initial;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 33;
    width: calc(6% + 4rem);
    max-height: 80%;
    aspect-ratio: 1/1.1;
  }

  html#boersenvereinsgruppe .testimonial-content-con {
    position: relative;
    margin-left: auto;
    width: 94%;
    padding: 3rem 6rem;
  }

  html#boersenvereinsgruppe .testimonials-prev-btn,
  html#boersenvereinsgruppe .testimonials-next-btn {
    display: initial;
    width: fit-content;
  }

  html#boersenvereinsgruppe .testimonials-prev-btn {
    left: -5rem;
    transform: rotate(180deg);
  }

  html#boersenvereinsgruppe .testimonials-next-btn {
    right: -5rem;
  }
}

html#boersenvereinsgruppe .bvg-footer {
  margin-bottom: 0;
}

html#boersenvereinsgruppe .bvg-footer a {
  color: #fff;
}

html#boersenvereinsgruppe .bvg-footer-chat {
  position: absolute;
  top: -10rem;
  right: 0;
}

html#boersenvereinsgruppe .job-con {
  display: flex;
  flex-direction: column;
  gap: 6rem;
}

html#boersenvereinsgruppe .job-title {
  margin-bottom: 4rem;
}

html#boersenvereinsgruppe .job-properties {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

html#boersenvereinsgruppe .job-divider {
  width: 100%;
  height: 1px;
  background-color: #404040;
}

html#boersenvereinsgruppe .job-property {
  margin: 0;
  font-weight: 600;
}

html#boersenvereinsgruppe .job-description-con {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

html#boersenvereinsgruppe .job-link {
  text-transform: uppercase;
  color: #e3001b;
}

@media (min-width: 1024px) {
  html#boersenvereinsgruppe .job-header {
    width: 80%;
  }
}

html#boersenvereinsgruppe .jobs-search-con {
  background-color: #f3f3f3;
  padding: .5rem 1rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}

html#boersenvereinsgruppe .jobs-search-input {
  border: none;
  background-color: rgba(0,0,0,0);
  width: 100%;
}

html#boersenvereinsgruppe .jobs-filter-con {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 6rem;
}

html#boersenvereinsgruppe .jobs-filter-con .dropdown-toggle {
  background-color: #404040;
  border: none;
  color: #fff;
  font-weight: 100;
  padding: .2rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

html#boersenvereinsgruppe .jobs-filter-con .dropdown-toggle::after {
  display: none;
}

html#boersenvereinsgruppe .jobs-filter-con .dropdown-menu {
  background-color: #404040;
  color: #fff;
  font-weight: 100;
  padding: 0 1.5rem;
  width: 100%;
  margin: 0;
  border: none;
  border-radius: 0;
  top: unset !important;
  height: fit-content;
  transform: none !important;
}

html#boersenvereinsgruppe .dropdown-menu-divider {
  width: 100%;
  height: 1px;
  background-color: #fff;
  margin-top: .2rem;
  margin-bottom: .5rem;
  opacity: 20%;
}

html#boersenvereinsgruppe .jobs-filter-con .dropdown-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: .5rem 0;
  cursor: pointer;
  border-right: 2px solid rgba(0,0,0,0);
}

html#boersenvereinsgruppe .jobs-filter-con .dropdown-item:hover,
html#boersenvereinsgruppe .jobs-filter-con .dropdown-item:focus,
html#boersenvereinsgruppe .jobs-filter-con .dropdown-item:active {
  background-color: rgba(0,0,0,0);
  border-color: #fff;
}

html#boersenvereinsgruppe .jobs-filter-con .dropdown-item input[type=checkbox] {
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(0,0,0,0);
  margin: 0;
  width: 2rem;
  aspect-ratio: 1/1;
  border: .2rem solid #fff;
  border-radius: 99rem;
  display: grid;
  place-content: center;
}

html#boersenvereinsgruppe .jobs-filter-con .dropdown-item input[type=checkbox]::before {
  content: "";
  width: 0rem;
  aspect-ratio: 1/1;
  border-radius: 99rem;
  background-color: #fff;
}

html#boersenvereinsgruppe .jobs-filter-con .dropdown-item input[type=checkbox]:checked::before {
  width: 1rem;
}

html#boersenvereinsgruppe .jobs-filter-con .dropdown-item label {
  margin-bottom: 0;
  color: #fff;
  width: 100%;
  cursor: pointer;
}

html#boersenvereinsgruppe #jobs {
  display: grid;
  gap: 2rem;
}

@media (min-width: 640px) {
  html#boersenvereinsgruppe .jobs-filter-con {
    grid-template-columns: 1fr 1fr 1fr;
  }

  html#boersenvereinsgruppe #jobs {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .jobs-filter-con {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  html#boersenvereinsgruppe #jobs {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1280px) {
  html#boersenvereinsgruppe .jobs-filter-con {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

html#boersenvereinsgruppe .jp-benefits-con {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
}

html#boersenvereinsgruppe .jp-benefit-item {
  background-color: #f3f3f3;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  gap: 2rem;
}

html#boersenvereinsgruppe .jp-benefit-item p {
  margin: 0;
  text-align: center;
  font-weight: 600;
}

@media (min-width: 640px) {
  html#boersenvereinsgruppe .jp-benefits-con {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .jp-benefits-con {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  html#boersenvereinsgruppe .jp-benefits-con {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  html#boersenvereinsgruppe .jp-benefit-item {
    justify-content: start;
    padding-top: 5rem;
    gap: 3rem;
  }
}

@media (min-width: 1536px) {
  html#boersenvereinsgruppe .jp-benefits-con {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

html#boersenvereinsgruppe .accordion .card .card-header {
  background-color: #f3f3f3;
  margin: 0;
  padding: 0;
}

html#boersenvereinsgruppe .jp-brands-con .card-header button,
html#boersenvereinsgruppe .collapsible-con .card-header button {
  width: 100%;
  display: flex;
  gap: 2rem;
  text-align: left;
  justify-content: space-between;
  align-content: center;
  border: none;
  color: #404040;
  background-color: #f3f3f3;
  padding: 1.5rem 1rem;
}

html#boersenvereinsgruppe .card-header button p {
  font-weight: 600;
}

html#boersenvereinsgruppe .jp-brands-arrow {
  transform: rotate(-90deg);
}

html#boersenvereinsgruppe .collapsed .jp-brands-arrow {
  transform: rotate(90deg);
}

html#boersenvereinsgruppe .accordion .card .show {
  margin: 0;
}

html#boersenvereinsgruppe .jp-brands-con .card-body {
  padding: 0;
  display: grid;
  gap: 4rem;
  padding: 3rem 0;
}

html#boersenvereinsgruppe .jp-brands-info-con {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

html#boersenvereinsgruppe .jp-brands-social-media-con {
  display: flex;
  gap: 2rem;
}

html#boersenvereinsgruppe .jp-brands-social-media {
  position: relative;
  width: 3.5rem;
  aspect-ratio: 1;
}

html#boersenvereinsgruppe .jp-brands-logo {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  grid-row-start: 1;
  position: relative;
}

html#boersenvereinsgruppe .jp-brands-logo .fill-picture {
  position: relative !important;
}

html#boersenvereinsgruppe .image-contain {
  object-fit: contain !important;
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .jp-brands-con .card-body {
    gap: 4rem;
    grid-template-columns: 6fr 1fr;
  }

  html#boersenvereinsgruppe .jp-brands-logo {
    grid-row-start: 1;
    grid-column: 2/3;
  }

  html#boersenvereinsgruppe .jp-brands-logo .fill-picture {
    position: absolute !important;
  }
}

html#boersenvereinsgruppe .jp-contact-hero-con {
  position: relative;
  min-height: 250px;
  color: #fff;
  padding: 3rem;
}

html#boersenvereinsgruppe .jp-contact-hero-con h1 {
  margin: 0;
}

html#boersenvereinsgruppe .jp-contact-hero-con a {
  color: #fff;
  align-self: flex-start;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  fill: red;
  text-transform: uppercase;
  font-weight: 500;
}

html#boersenvereinsgruppe .jp-contact-hero-con a:hover,
html#boersenvereinsgruppe .jp-contact-hero-con a:focus,
html#boersenvereinsgruppe .jp-contact-hero-con a:active {
  color: #404040;
  text-decoration: underline #404040;
}

html#boersenvereinsgruppe .jp-contact-hero-bg-img {
  position: absolute;
  inset: 0;
}

html#boersenvereinsgruppe .jp-contact-hero-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 4rem;
  flex-wrap: wrap;
}

html#boersenvereinsgruppe .jp-social-media-con {
  transform: translateX(-0.8rem);
}

html#boersenvereinsgruppe .jp-social-media-con svg {
  transform: scale(0.6);
}

@media (min-width: 1024px) {
  html#boersenvereinsgruppe .jp-contact-hero-con {
    padding: 4rem;
  }
}

html#boersenvereinsgruppe .jp-contact-con {
  display: grid;
  gap: 3rem;
}

html#boersenvereinsgruppe .jp-contact-info {
  padding: 3rem;
  background-color: #e3001b;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: fit-content;
  width: 100%;
}

html#boersenvereinsgruppe .jp-contact-info p {
  margin: 0;
}

html#boersenvereinsgruppe .jp-contact-form input,
html#boersenvereinsgruppe .jp-contact-form textarea {
  background-color: #f3f3f3;
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .jp-contact-con {
    grid-template-columns: 3fr 1.33fr;
  }
}

html#boersenvereinsgruppe .jp-hero-con {
  position: relative;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

html#boersenvereinsgruppe .jp-hero-image-con {
  position: absolute;
  inset: 0;
}

html#boersenvereinsgruppe .jp-hero-content {
  position: relative;
  margin-left: 10vw;
}

html#boersenvereinsgruppe .jp-hero-title,
html#boersenvereinsgruppe .jp-hero-subtitle {
  margin: 0;
  color: #fff;
}

html#boersenvereinsgruppe .jp-news-preview-con {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

html#boersenvereinsgruppe .jp-news-cards {
  display: grid;
  gap: 3rem;
}

html#boersenvereinsgruppe .jp-news-article-card {
  position: relative;
  aspect-ratio: 1/1.2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

html#boersenvereinsgruppe .jp-news-article-previewimage {
  position: absolute;
  inset: 0;
}

html#boersenvereinsgruppe .jp-news-article-content {
  position: relative;
  color: #fff;
  text-align: center;
  padding: 2rem;
  background: linear-gradient(179.75deg, rgba(30, 32, 37, 0.13) 0.1%, rgba(30, 32, 37, 0.4) 28.74%, rgba(30, 32, 37, 0.75) 50.52%, #1E2025 99.78%);
}

html#boersenvereinsgruppe .jp-news-article-content p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

html#boersenvereinsgruppe .jp-news-article-content span {
  font-size: 1.5rem;
  opacity: .85;
}

@media (min-width: 640px) {
  html#boersenvereinsgruppe .jp-news-cards {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .jp-news-cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

html#boersenvereinsgruppe .jp-events-preview-con {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}

html#boersenvereinsgruppe .jp-events-mobile {
  display: grid;
}

html#boersenvereinsgruppe .jp-event-mobile-card {
  position: relative;
  padding-bottom: 3rem;
}

html#boersenvereinsgruppe .jp-events-desktop {
  display: none;
}

html#boersenvereinsgruppe .jp-events-preview-con p {
  margin: 0;
}

html#boersenvereinsgruppe .jp-events-preview-con .card {
  display: flex;
  flex-direction: row;
  overflow: visible !important;
  position: unset !important;
}

html#boersenvereinsgruppe .jp-events-preview-con .card-header {
  padding-bottom: 3rem !important;
  background-color: rgba(0,0,0,0) !important;
  width: 233px;
  position: relative;
  opacity: .5;
}

html#boersenvereinsgruppe .jp-events-preview-con .card-header.active {
  opacity: 1;
}

html#boersenvereinsgruppe .jp-event-indicator-con {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 1rem;
}

html#boersenvereinsgruppe .jp-event-indicator-circle {
  width: .6rem;
  aspect-ratio: 1/1;
  border-radius: 99rem;
  background-color: #404040;
  position: absolute;
  top: 0;
}

html#boersenvereinsgruppe .active .jp-event-indicator-circle {
  background-color: #e3001b;
}

html#boersenvereinsgruppe .jp-event-indicator-line {
  position: absolute;
  top: .6rem;
  bottom: 0;
  width: 2px;
  background-color: #f3f3f3;
}

html#boersenvereinsgruppe .jp-event-card-btn {
  background-color: rgba(0,0,0,0);
  border: none;
}

html#boersenvereinsgruppe .jp-event-card-btn-content-con {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: left;
  padding-left: 2.5rem;
}

html#boersenvereinsgruppe .jp-event-card-btn-content-header {
  display: flex;
  gap: 1rem;
}

html#boersenvereinsgruppe .active .jp-event-card-btn-content-header {
  color: #e3001b;
}

html#boersenvereinsgruppe .jp-event-card-subtitle {
  font-weight: 400 !important;
}

html#boersenvereinsgruppe .jp-events-preview-con .collapse {
  height: fit-content !important;
  position: absolute !important;
  top: 0 !important;
  left: 280px !important;
  right: 2rem !important;
}

html#boersenvereinsgruppe .jp-events-preview-con .collapsing {
  position: absolute !important;
  top: 0 !important;
  left: 280px !important;
  right: 2rem !important;
}

html#boersenvereinsgruppe .jp-events-preview-con .card-body {
  padding: 0;
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

html#boersenvereinsgruppe .jp-event-card-tile {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  padding: 2rem;
  aspect-ratio: 1/1;
  text-align: center;
}

html#boersenvereinsgruppe .jp-event-card-tile p {
  font-weight: 700;
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .jp-events-mobile {
    display: none;
  }

  html#boersenvereinsgruppe .jp-events-desktop {
    display: initial;
  }
}

@media (min-width: 1024px) {
  html#boersenvereinsgruppe .jp-events-preview-con .card-body {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1280px) {
  html#boersenvereinsgruppe .jp-events-preview-con .card-body {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

html#boersenvereinsgruppe #events {
  display: grid;
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe #events {
    grid-template-columns: 1fr 1fr;
  }
}

html#boersenvereinsgruppe .jp-event-con {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

html#boersenvereinsgruppe .jp-event-tiles {
  display: grid;
  gap: 2rem;
}

@media (min-width: 640px) {
  html#boersenvereinsgruppe .jp-event-tiles {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .jp-event-tiles {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  html#boersenvereinsgruppe .jp-event-tiles {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

html#boersenvereinsgruppe #news {
  display: grid;
  gap: 2rem;
  margin-top: 6rem;
}

@media (min-width: 640px) {
  html#boersenvereinsgruppe #news {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe #news {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1024px) {
  html#boersenvereinsgruppe #news {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

html#boersenvereinsgruppe .jp-news-article-con {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

html#boersenvereinsgruppe .jp-news-article-header {
  text-align: center;
}

html#boersenvereinsgruppe .jp-news-article-img {
  position: relative;
  height: 52vh;
}

html#boersenvereinsgruppe .container {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

html#boersenvereinsgruppe .hide {
  display: none;
}

html#boersenvereinsgruppe .list-style-none {
  list-style: none;
}

html#boersenvereinsgruppe .relative {
  position: relative;
}

html#boersenvereinsgruppe .border-black {
  border: 1px solid #000;
}

html#boersenvereinsgruppe .bold {
  font-weight: 700;
}

html#boersenvereinsgruppe .fill-picture {
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  object-position: center;
  object-fit: cover;
}

html#boersenvereinsgruppe .my {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
}

html#boersenvereinsgruppe .mt {
  margin-top: 6.5rem;
}

html#boersenvereinsgruppe .mb {
  margin-bottom: 6.5rem;
}

html#boersenvereinsgruppe .mb-0 {
  margin-bottom: 0;
}

html#boersenvereinsgruppe .jp-mb-4 {
  margin-bottom: 4rem;
}

html#boersenvereinsgruppe .flex {
  display: flex;
}

html#boersenvereinsgruppe .flex-col {
  display: flex;
  flex-direction: column;
}

html#boersenvereinsgruppe .flex-col-content-center {
  display: flex;
  flex-direction: column;
  align-content: center;
}

html#boersenvereinsgruppe .flex-between {
  display: flex;
  justify-content: space-between;
}

html#boersenvereinsgruppe .flex-items-center {
  display: flex;
  align-items: center;
}

html#boersenvereinsgruppe .wrap {
  flex-wrap: wrap;
}

html#boersenvereinsgruppe .gap-05 {
  gap: .5rem;
}

html#boersenvereinsgruppe .gap {
  gap: 1rem;
}

html#boersenvereinsgruppe .gap-2 {
  gap: 2rem;
}

html#boersenvereinsgruppe .gap-3 {
  gap: 3rem;
}

html#boersenvereinsgruppe .gap-4 {
  gap: 4rem;
}

html#boersenvereinsgruppe .h-2 {
  height: 2rem;
}

html#boersenvereinsgruppe .h-42vh {
  height: 42vh;
}

html#boersenvereinsgruppe .jp-copyright {
  position: absolute;
  transform: rotate(-90deg) translate(100%, 0%);
  right: 1em;
  transform-origin: right;
  font-size: .8em;
  bottom: 0;
  color: #fff;
  z-index: 99;
}

html#boersenvereinsgruppe figure {
  position: relative;
}

html#boersenvereinsgruppe figcaption {
  position: absolute;
  transform: rotate(-90deg) translate(100%, 0%);
  right: 1em;
  transform-origin: right;
  font-size: .8em;
  bottom: 1.4rem;
  color: #fff;
  z-index: 99;
}

html#boersenvereinsgruppe figcaption::before {
  position: absolute;
  content: "©";
  left: -1.4rem;
}

html#boersenvereinsgruppe .bvg-freetext {
  overflow: hidden;
}

html#boersenvereinsgruppe .bvg-freetext h1,
html#boersenvereinsgruppe .bvg-freetext h2 {
  margin: 0;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .bvg-freetext h1,
  html#boersenvereinsgruppe .bvg-freetext h2 {
    margin-bottom: 3rem;
  }
}

html#boersenvereinsgruppe .bvg-freetext img {
  width: 100%;
}

html#boersenvereinsgruppe .cs-freetext img {
  width: 100%;
}

html#boersenvereinsgruppe .bvg-freetext figure {
  position: relative;
}

html#boersenvereinsgruppe .cs-freetext figure {
  position: relative;
}

html#boersenvereinsgruppe .hover-scale-down:hover {
  transform: scale(0.96);
}

html#boersenvereinsgruppe .hover-scale-up-or-ring {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

html#boersenvereinsgruppe .hover-scale-up-or-ring:hover {
  transform: scale(1.02);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

@media (prefers-reduced-motion) {
  html#boersenvereinsgruppe .hover-scale-up-or-ring:hover {
    transform: none;
    box-shadow: 0 0 0 4px #e3001b;
  }
}

@media (min-width: 640px) {
  html#boersenvereinsgruppe .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  html#boersenvereinsgruppe .container {
    max-width: 768px;
    padding: 0;
  }

  html#boersenvereinsgruppe .md-hide {
    display: none;
  }

  html#boersenvereinsgruppe .md-unhide {
    display: flex;
  }
}

@media (min-width: 1024px) {
  html#boersenvereinsgruppe .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  html#boersenvereinsgruppe .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  html#boersenvereinsgruppe .container {
    max-width: 1536px;
  }
}

html#boersenvereinsgruppe .jp-richtext ol,
html#boersenvereinsgruppe .jp-richtext ul {
  list-style-position: inside;
}

html#boersenvereinsgruppe .jp-richtext a:not(.jb-link-con) {
  text-decoration: underline;
  color: #e2001a;
}

html#boersenvereinsgruppe .jp-richtext a:not(.jb-link-con):hover {
  color: #e64155;
}

html#boersenvereinsgruppe #testimonialCarousel .carousel-indicators {
  margin: 0;
  margin-top: 2rem;
  position: relative;
  list-style: none;
  display: flex;
  z-index: 15;
  gap: 1rem;
  padding-left: 0;
  right: unset;
  left: 0;
}

html#boersenvereinsgruppe #testimonialCarousel .carousel-indicators li {
  box-sizing: content-box;
  flex: none;
  border: 1px solid #e2001a;
  width: 1rem;
  height: 1rem;
  margin-right: 0;
  margin-left: 0;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(0,0,0,0);
  background-clip: padding-box;
  opacity: 1;
  transition: opacity .6s ease;
}

html#boersenvereinsgruppe #testimonialCarousel .carousel-indicators .active {
  background-color: #e2001a;
}

