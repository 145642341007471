html#boersenvereinsgruppe

  min-height: 100%

  $grey: #404040
  $light-grey: #F3F3F3
  $white: #fff
  $red: #E3001B

  $sm: 640px
  $md: 768px
  $lg: 1024px
  $xl: 1280px
  $xxl: 1536px 

  // reset/override inheritance
  body
    min-height: 100%
    background-color: $white
    margin: 0
    font-family: 'Graphik-Web', sans-serif
    -webkit-font-smoothing: antialiased
    color: $grey
    @include media-breakpoint-down(md)
      margin: 0

    section
      margin-bottom: 0

  #wrap-main
    min-height: 100%

  main
    overflow: auto
    overflow-x: hidden

  .help-content
    main
      overflow: unset

  body:before
    content: ""
    height: 100%
    float: left
    width: 0
    margin-top: -32767px

  #help-body
    margin-bottom: 0px

  // imports

  @import 'heroSlider'
  @import 'navigation'
  @import 'categories'
  @import 'separator'
  @import 'contentSection'
  @import 'link'
  @import 'images'
  @import 'jobsPreview'
  @import 'jobcard'
  @import 'testimonials'
  @import 'footer'

  @import 'jobDetail'
  @import 'jobsOverview'

  @import 'benefits'

  @import 'brands'

  @import 'contactHero'

  @import 'contact'

  @import 'hero'

  @import 'newsPreview'

  @import 'eventsPreview'

  @import 'events'
  @import 'event'

  @import 'news'
  @import 'newsArticle'

  // util

  .container
    width: 100%
    padding-left: 1rem
    padding-right: 1rem

  .hide
    display: none

  .list-style-none
    list-style: none

  .relative
    position: relative

  .border-black
    border: 1px solid black

  .bold
    font-weight: 700

  .fill-picture
    display: block
    margin: auto
    width: 100%
    height: 100%
    position: absolute
    object-position: center
    object-fit: cover

  .my
    margin-top: 6.5rem
    margin-bottom: 6.5rem

  .mt
    margin-top: 6.5rem

  .mb
    margin-bottom: 6.5rem

  .mb-0
    margin-bottom: 0

  .jp-mb-4
    margin-bottom: 4rem

  .flex
    display: flex

  .flex-col
    display: flex
    flex-direction: column

  .flex-col-content-center
    display: flex
    flex-direction: column
    align-content: center

  .flex-between
    display: flex
    justify-content: space-between

  .flex-items-center
    display: flex
    align-items: center

  .wrap
    flex-wrap: wrap

  .gap-05
    gap: .5rem

  .gap
    gap: 1rem

  .gap-2
    gap: 2rem

  .gap-3
    gap: 3rem

  .gap-4
    gap: 4rem

  .h-2
    height: 2rem

  .h-42vh
    height: 42vh

  .jp-copyright
    position: absolute
    transform: rotate(-90deg) translate(100%, 0%)
    right: 1em
    transform-origin: right
    font-size: .8em
    bottom: 0
    color: white
    z-index: 99

  figure
    position: relative
    
  figcaption
    position: absolute
    transform: rotate(-90deg) translate(100%, 0%)
    right: 1em
    transform-origin: right
    font-size: .8em
    bottom: 1.4rem
    color: white
    z-index: 99

    &::before
      position: absolute
      content: 'Â©'
      left: -1.4rem

  .bvg-freetext
    overflow: hidden

  .bvg-freetext h1,
  .bvg-freetext h2
    margin: 0
    margin-bottom: 2rem

  @media (min-width: $md)
    .bvg-freetext h1,
    .bvg-freetext h2
      margin-bottom: 3rem

  .bvg-freetext img
    width: 100%

  .cs-freetext img
    width: 100%

  .bvg-freetext figure
    position: relative

  .cs-freetext figure
    position: relative

  .hover-scale-down:hover
    transform: scale(0.96)

  .hover-scale-up-or-ring
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1)

  .hover-scale-up-or-ring:hover
    transform: scale(1.02)
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1)

  @media (prefers-reduced-motion)
    .hover-scale-up-or-ring:hover
      transform: none
      box-shadow: 0 0 0 4px $red

  @media (min-width: $sm)
    .container
      max-width: 640px

  @media (min-width: $md)
    .container
      max-width: 768px
      padding: 0

    .md-hide
      display: none

    .md-unhide
      display: flex

  @media (min-width: $lg)
    .container
      max-width: 1024px

  @media (min-width: $xl)
    .container
      max-width: 1280px

  @media (min-width: $xxl)
    .container
      max-width: 1536px

  .jp-richtext
    ol, ul 
      list-style-position: inside
    a:not(.jb-link-con)
      text-decoration: underline
      color: #e2001a
      &:hover
        color: #e64155

html#boersenvereinsgruppe
  #testimonialCarousel
    .carousel-indicators
      margin: 0
      margin-top: 2rem
      position: relative
      list-style: none
      display: flex
      z-index: 15
      gap: 1rem
      padding-left: 0
      right: unset
      left: 0

    .carousel-indicators li
        box-sizing: content-box
        flex: none
        border: 1px solid $red
        width: 1rem
        height: 1rem
        margin-right: 0
        margin-left: 0
        text-indent: -999px
        cursor: pointer
        background-color: transparent
        background-clip: padding-box
        opacity: 1
        transition: opacity 0.6s ease

    .carousel-indicators .active
        background-color: $red