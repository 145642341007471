.tablecontainer
  overflow-x: hidden

#event-table-search-input
  width: 250px
  height: 40px
  font-size: 14px
  @include media-breakpoint-down(md)
    display: none

tbody tr
  &.odd>td
    background-color: transparent !important
  &:hover
    &.odd>td
      background-color: #d9d9d9 !important

table.all-events-table
  border-spacing: 1px
  border-collapse: separate
  font-size: 15px



  .tablesorter-filter-row
    display: none

  .event-table-href
    cursor: pointer
    text-align: center
    a
      color: #404040
  .event-table-date
    font-weight: bold

  th
    background-color: #DBDBDB
    font-size: 13px

  th.datetime
    width: 200px
    
  th.tablesorter-header
    background-image: url(/build/assets/img/sort.png)
    background-position: center right 1.25rem
    background-color: #DBDBDB
    background-size: 10px
    padding: 1.25rem

  th.tablesorter-headerAsc
    background-image: url(/build/assets/img/sort_down.png) !important


  th.tablesorter-headerDesc
    background-image: url(/build/assets/img/sort_up.png) !important

  th.sorter-false
    background-image: unset

  tr
    height: 60px
    &.as-link
      cursor: pointer
    td
      vertical-align: middle
      padding: 1.25rem !important
      &.as-link
        cursor: pointer
      img.product-logo
        height: 14px
  @include media-breakpoint-down(md)
    display: none
