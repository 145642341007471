.latestpress
  .press
    .card
      width: 100%
      .card-header
        background: white
        border: none
        .right
          float: right       
      .card-footer
        padding: 0.75rem 1.25rem
        background-color: white
        border: none
        color: black
        position: static
