$margin: 2rem
$padding: 1.6rem

html
  min-height: 100%
  body
    min-height: 100%
    background-color: $lightgrey
    margin: $margin
    @include media-breakpoint-down(md)
      margin: $margin / 2

    section
      margin-bottom: $margin * 1.5

#wrap-main
  min-height: 100%

main
  overflow: auto

.help-content
  main
    overflow: unset

body:before
  content: ""
  height: 100%
  float: left
  width: 0
  margin-top: -32767px

#help-body
  margin-bottom: 0px
