.hint
  margin-top: 6rem
  margin-bottom: 6rem
  i
    font-size: 5rem
    &.fa-arrow-right
      font-size: 2rem
    @include media-breakpoint-down(sm)
      &.fa-arrow-right
        font-size: 1.5rem
  .tipbox
    background-color: #e2001a
    padding: $padding
    color: $white
    margin: 0
    display: flex
    align-items: center
    @include media-breakpoint-down(sm)
      min-height: 25rem

      .float-right
        float: none !important
      .col-md-9
        display: flex

  strong
    overflow: hidden
    vertical-align: middle
    position: relative
    top: 1px

  span
    padding-right: 1rem
    float: left
    font-size: 2rem
