.jp-benefits-con
  display: grid
  gap: 2rem
  grid-template-columns: 1fr

.jp-benefit-item
  background-color: $light-grey
  aspect-ratio: 1 / 1
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 2.5rem
  gap: 2rem

.jp-benefit-item p
  margin: 0
  text-align: center
  font-weight: 600

@media (min-width: $sm)
  .jp-benefits-con
    grid-template-columns: 1fr 1fr

@media (min-width: $md)
  .jp-benefits-con
    grid-template-columns: 1fr 1fr 1fr

@media (min-width: $lg)
  .jp-benefits-con
    grid-template-columns: 1fr 1fr 1fr 1fr

  .jp-benefit-item
    justify-content: start
    padding-top: 5rem
    gap: 3rem

@media (min-width: $xxl)
  .jp-benefits-con
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr
