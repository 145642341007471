body footer
  background-color: $default
  padding: 4rem
  color: $white
  position: relative
  margin-bottom: 20px
  clear: both
  p.footer-p
    margin-bottom: 0
  @include media-breakpoint-down(sm)
    height: auto
    padding: 2rem
    padding-top: 4rem

  .row
    position: relative
    .brand-text
      text-transform: uppercase
      font-weight: 900
    img
      display: inline-block
      height: 1.4em
      vertical-align: top

  nav.more-brands
    position: relative
    padding: 1.6rem
    background: $red
    margin-top: 1em

    img
      filter: brightness(0) invert(1)
      max-height: 1.5rem

    ul
      @include media-breakpoint-down(md)
        display: flex
        flex-direction: column
        .card-img-top
          width: auto

    li
      @include media-breakpoint-down(sm)
        display: block
        width: 100%

  nav.pre-footer
    margin-top: 4rem
    margin-bottom: 4rem
    @include media-breakpoint-down(sm)
      margin-bottom: 1rem
  nav.footer-nav
    margin-top: 1em
    text-align: center
    @include media-breakpoint-up(md)
      margin-top: 0
      text-align: right
      ul.inline-nav
        li:last-of-type
          padding-right: 0

  nav.footer-nav, nav.more-brands
    ul li a
      color: rgba($white,.75)
      font-weight: 500
      text-decoration: none
      +transition()
      &:hover
        text-decoration: none
        color: rgba($white,1)
    ul.inline-nav li
          padding: 0.5rem 1rem
          display: inline-block
  .copy
    @include media-breakpoint-down(md)
      text-align: left
    @include media-breakpoint-down(sm)
      text-align: center

  .social
    margin-top: 1rem
    .icons
      float: right
      display: flex
      @include media-breakpoint-down(md)
        &.text-right
          text-align: center !important
        ul.inline-nav li
          padding: 0.5em
    a
      color: $white
      text-decoration: none
      &:hover
        color: rgba($white,.75)

  .pre-footer
    li
      line-height: 2em
      display: flex
      align-items: center
      i
        padding-right: 1em
    a
      line-height: 2em
      text-decoration: none
      color: $white
      &:hover
        color: rgba($white,.75)
    & > div
      @include media-breakpoint-down(md)
        margin-bottom: 2rem

  .language-switch
    @include media-breakpoint-down(md)
      display: none
    li
      color: rgba($white,.5)
      padding: 0
    a
      li
        color: $white
      &.active, &:hover
        li
          color: rgba($white,.5)
