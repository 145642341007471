.trust

  .owl-two
    .owl-stage-outer
      .owl-stage
        .owl-item
          opacity: 0.6
        .center
          zoom: normal !important
          .item
            zoom: 1.09
          opacity: 1
          @include media-breakpoint-down(xs)
            zoom: 0

  .owl-carousel
    z-index: 0

    .owl-stage
      display: flex
      align-items: flex-end

    .item
      min-height: 25vw
      align-items: stretch
      display: flex
      margin-right: 16px
      width: 100%
      @include media-breakpoint-down(lg)
        min-height: 35vw
      @include media-breakpoint-down(md)
        min-height: 20vw

      .container
        background:
          image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5))
          size: cover
        color: $white
        position: relative
        padding: 6rem
        @include media-breakpoint-down(md)
          padding: 2rem

        .text-holder
          position: absolute
          @include media-breakpoint-down(md)
            position: inherit

      blockquote
        padding: 0 6rem 6rem 0
        @include media-breakpoint-down(sm)
          padding: 0 2rem 2rem 0

        q
          font-size: 2.4rem
          @include media-breakpoint-down(sm)
            text-shadow: 0 4px 2px rgba(0,0,0,.1)

        cite
          font-style: normal

        footer
          background-color: transparent
          padding: 0
          border-top: 0
          color: $white

    .owl-nav
      margin: 0
      padding: 0
      @include media-breakpoint-down(md)
        display: none
      &.disabled
        display: inline

      .owl-prev, .owl-next
        position: absolute
        top: 50%
        transform: translateY(-50%)
        display: block
        height: 40px
        width: 40px

      .owl-prev
        right: 96%
        background:
            image: url('../../../img/pfeil-links.svg')
            color: transparent
            size: 70%
            position: center
            repeat: no-repeat

      .owl-next
        left: 96%
        background:
          image: url('../../../img/pfeil-rechts.svg')
          color: transparent
          size: 70%
          position: center
          repeat: no-repeat

    .owl-dots
      position: absolute
      bottom: 0
      left: 50%
      transform: translateY(-50%)
      @include media-breakpoint-down(sm)
        position: static
        display: none

      &.disabled
        display: block

      .owl-dot
        &.active span
          background-color: $white

        span
          border-radius: 0
          background-color: transparent
          border: 1px solid $white
          width: 1rem
          height: 1rem
          margin: .5rem
          @include media-breakpoint-down(sm)
            margin: 2rem 1rem

          &:hover
            background-color: $white

.trust-signle
  padding-left: 15px
  .container
    min-height: 30vw
    background:
      image: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5))
      size: cover
    color: $white
    position: relative
    padding: 6rem
    @include media-breakpoint-down(sm)
      min-height: 35vw
      padding: 2rem

    q
      @include media-breakpoint-down(md)
        font-size: 2.4rem
        text-shadow: 0 4px 2px rgba(0,0,0,.1)

    .text-holder
      position: absolute
      top: 2rem
      display: block
      overflow: auto
      bottom: 6rem
      @include media-breakpoint-down(md)
        position: inherit
        bottom: 0
      footer
        height: auto

    footer
      background-color: transparent
      padding: 0

    cite
      font-style: normal

/* .trust-small
  background:
    color: $red
    image: url('../../../img/quotations.svg')
    position: -1rem -4rem
    repeat: no-repeat
    size: 50%
  color: $white
  position: relative

  .text-muted
    color: $white !important */

.testimonial-new
  .i
    color: #fff !important

  .item
    .img-wrapper
      background-size: cover
      img
        display: block
        //width: 100vw
        object-position: top
        visibility: hidden

    .testimonial-text
      color: #ffffff
      padding: 2rem
      overflow: auto
      width: 100%
      background-color: transparentize($red, .2)
      height: 20rem
      opacity: 0.9

      @media (min-width: 768px)
        position: absolute
        bottom: 0px
        background: none
        height: auto
        overflow: auto
        width: 50%
        min-height: unset
        left: unset
        left: 3rem
        background-color: transparentize($red, .2)

      img
        width: auto
        display: unset
        padding-bottom: 2rem
        filter: brightness(0) invert(1)
      q
        font-size: 2.6rem
      p
        font-size: 1.7rem

    &.overlay
      &:before
        content: ' '
        position: absolute
        left: 0
        right: 0
        bottom: 0
        top: 0
        background-image: linear-gradient(to bottom,rgba(0,0,0,.1),rgba($default,.5))
        mix-blend-mode: normal
        @include media-breakpoint-down(sm)
          content: none


.testimonial-text-image
  .item
    .img-wrapper
      background-size: cover
      img
        display: block
        //width: 100vw
        height: 30vh
        object-position: top
        visibility: hidden
        @media (min-width: 768px)
          height: 50vh

    .testimonial-text
      background-color: #3f3f3f
      color: #ffffff
      padding: 2rem
      font-style: italic
      height: 30rem
      overflow: auto

      @media (min-width: 768px)
        position: absolute
        bottom: 0px
        background: none
        height: auto
        overflow: auto

      q
        font-size: 2.6rem
      p
        font-size: 1.7rem

    &.overlay
      &:before
        content: ' '
        position: absolute
        left: 0
        right: 0
        bottom: 0
        top: 0
        background-image: linear-gradient(to bottom,rgba(0,0,0,.1),rgba($default,.5))
        mix-blend-mode: normal
        @include media-breakpoint-down(sm)
          content: none


  .owl-three
    .owl-next
      bottom: 0
      font-size: 3rem
      right: 0
      top: 0
      position: absolute
      width: 40px
      &:hover, &:active
        background: none
      &:focus
        outline: 0
    .owl-prev
      bottom: 0
      font-size: 3rem
      left: 0
      top: 0
      position: absolute
      width: 40px
      &:hover, &:active
        background: none
      &:focus
        outline: 0

    .owl-dots
      position: absolute
      bottom: 1rem
      left: 50%
      transform: translateX(-50%)
      @include media-breakpoint-down(sm)
        position: static
        display: none

      &.disabled
        display: block

      .owl-dot
        &.active span
          background-color: $white
        &:focus
          outline: 0

        span
          border-radius: 0
          background-color: transparent
          border: 1px solid $white
          width: 1rem
          height: 1rem
          margin: .5rem
          @include media-breakpoint-down(sm)
            margin: 2rem 1rem

          &:hover
            background-color: $white


  .owl-three
    .owl-next
      bottom: 0
      font-size: 3rem
      right: 0
      top: 0
      position: absolute
      width: 40px
      &:hover, &:active
        background: none
      &:focus
        outline: 0
    .owl-prev
      bottom: 0
      font-size: 3rem
      left: 0
      top: 0
      position: absolute
      width: 40px
      &:hover, &:active
        background: none
      &:focus
        outline: 0

    .owl-dots
      position: absolute
      bottom: 1rem
      left: 50%
      transform: translateX(-50%)
      @include media-breakpoint-down(sm)
        position: static
        display: none

      &.disabled
        display: block

      .owl-dot
        &.active span
          background-color: $white
        &:focus
          outline: 0

        span
          border-radius: 0
          background-color: transparent
          border: 1px solid $white
          width: 1rem
          height: 1rem
          margin: .5rem
          @include media-breakpoint-down(sm)
            margin: 2rem 1rem

          &:hover
            background-color: $white


.owl-four, .owl-three
  .owl-next
    bottom: 0
    font-size: 3rem
    right: 0
    top: 0
    position: absolute
    width: 40px
    &:hover, &:active
      background: none
    &:focus
      outline: 0
  .owl-prev
    bottom: 0
    font-size: 3rem
    left: 0
    top: 0
    position: absolute
    width: 40px
    &:hover, &:active
      background: none
    &:focus
      outline: 0
  .fa-arrow-right
    font-size: 30px
    margin-right: 20px
  .fa-arrow-left
    font-size: 30px
    margin-left: 20px
  .owl-nav
    button
      background-color: unset !important
      color: #3f3f3f !important
      &:hover
        background-color: unset !important

.owl-four
  .owl-dots
    position: absolute
    bottom: 1rem
    left: 50%
    transform: translateX(-50%)
    @include media-breakpoint-down(sm)
      position: static
      display: none

    &.disabled
      display: block

    .owl-dot
      &.active span
        background-color: $white
      &:focus
        outline: 0

      span
        border-radius: 0
        background-color: transparent
        border: 1px solid $white
        width: 1rem
        height: 1rem
        margin: .5rem
        @include media-breakpoint-down(sm)
          margin: 2rem 1rem

        &:hover
          background-color: $white




.owl-four
  .owl-item
    background: #ffffff
    .new-testimonial
      margin: 0
      padding: 0
      .col-testimonial-content
        padding: 30px
        padding-right: 60px
