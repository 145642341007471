.jp-news-preview-con
  display: flex
  flex-direction: column
  gap: 4rem

.jp-news-cards
  display: grid
  gap: 3rem

.jp-news-article-card
  position: relative
  aspect-ratio: 1 / 1.2
  display: flex
  flex-direction: column
  justify-content: flex-end

.jp-news-article-previewimage
  position: absolute
  inset: 0

.jp-news-article-content
  position: relative
  color: $white
  text-align: center
  padding: 2rem
  background: linear-gradient(179.75deg, rgba(30, 32, 37, 0.13) 0.10%, rgba(30, 32, 37, 0.40) 28.74%, rgba(30, 32, 37, 0.75) 50.52%, #1E2025 99.78%)

.jp-news-article-content p
  overflow: hidden
  display: -webkit-box
  -webkit-line-clamp: 2
  line-clamp: 2
  -webkit-box-orient: vertical

.jp-news-article-content span
  font-size: 1.5rem
  opacity: 0.85

@media (min-width: $sm)
  .jp-news-cards
    grid-template-columns: 1fr 1fr

@media (min-width: $md)
  .jp-news-cards
    grid-template-columns: 1fr 1fr 1fr