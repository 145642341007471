.events-row
  +clearfix
  margin-bottom: $margin * 2

  .wrapper
    display: grid
    align-items: start
    grid-template-columns: repeat(3, 1fr)
    grid-auto-rows: 1fr
    grid-column-gap: 20px
    grid-row-gap: 20px

    @include media-breakpoint-down(md)
      grid-template-columns: repeat(1, 1fr)
      grid-auto-rows: auto

    .item
      height: 100%
      min-height: 300px
      background: $white
      border: none
      padding: 1.6rem
      position: relative
      @include media-breakpoint-down(md)
        min-height: 220px

      .text-muted
        position: absolute
        bottom: 3rem
        @include media-breakpoint-down(md)
          bottom: 1.6rem

      &.trust-small
        background:
          color: $red
          image: url('../../../img/quotations.svg')
          position: -1rem -4rem
          repeat: no-repeat
          size: 50%
        color: $white
        position: relative
        margin: -1.6rem

        .text-muted
          color: $white !important

  h3
    margin-bottom: 1.6rem

  h4
    margin-bottom: 2.4rem

  a
    color: $default
    text-decoration: none


