main div.content-support

  h1,h2,h3
    margin-top: 1em


  img
    max-width: 100%

  .alert
    padding: $margin
    margin: $margin 0

.help-content
  margin-left: 20%
  min-height: calc(100vh - 20px)
  position: relative
  @include media-breakpoint-down(md)
    margin-left: 0


