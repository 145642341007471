.jp-news-article-con
  display: flex
  flex-direction: column
  gap: 4rem

.jp-news-article-header
  text-align: center

.jp-news-article-img
  position: relative
  height: 52vh