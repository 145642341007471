div.product-description
  padding-bottom: 40px

  .product-description-left-section
    background-color: white
    padding: 20px

    img 
      padding-bottom: 18px
      max-width: 150px

    a 
      padding: 9px
      margin-top: 20px
      display: block
      text-align: center
      color: #ffffff
      text-decoration: none
      background-color: $red
      :hover
        background-color: $lightred

  .product-description-right-section
    padding-left: 40px  
    @include media-breakpoint-down(sm)
      padding-left: 15px 
      padding-top: 20px