.slide-item
    position: relative
    height: 45vh

.overlay-red
    position: absolute
    inset: 0
    background-color: #55000A85

.slide-content
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 10%
    max-width: Min(85%, 600px)
    color: $white

.jp-hero-slider-title
    hyphens: manual
    +font-size(5.2)
    margin: 0
    margin-bottom: 1rem

.carousel-indicators
    position: absolute
    bottom: 10%
    left: 10%
    list-style: none
    display: flex
    z-index: 15
    gap: 1rem
    padding-left: 0
    margin: 0
    right: unset

.carousel-indicators li
    box-sizing: content-box
    flex: none
    border: 1px solid $white
    width: 1rem
    height: 1rem
    margin-right: 0
    margin-left: 0
    text-indent: -999px
    cursor: pointer
    background-color: transparent
    background-clip: padding-box
    opacity: .5
    transition: opacity 0.6s ease

.carousel-indicators .active
    background-color: $white

@media (min-width: $sm)
    .jp-hero-slider-title
        margin-bottom: 0
