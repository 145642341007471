.jp-categories
  display: grid
  gap: 2rem
  grid-template-columns: repeat(1, minmax(0, 1fr))
  list-style: none

.jp-categories-title
  font-size: 4rem
  margin-bottom: 3.33rem
  text-align: center

.jp-category-image
  filter: brightness(.8)

.jp-category
  position: relative
  aspect-ratio: .85 / 1
  border-bottom: 12px solid $red
  display: flex

.jp-category-title
  position: absolute
  bottom: 2rem
  left: 0
  right: 0
  text-align: center
  color: $white
  font-weight: 600
  font-size: 2rem

@media (min-width: $sm)
  .jp-categories
    grid-template-columns: repeat(2, minmax(0, 1fr))

@media (min-width: $md)
  .jp-categories
    grid-template-columns: repeat(3, minmax(0, 1fr))

  .jp-categories-title
    text-align: left

@media (min-width: $lg)
  .jp-categories
    grid-template-columns: repeat(4, minmax(0, 1fr))
