.job-con
  display: flex
  flex-direction: column
  gap: 6rem

.job-title
  margin-bottom: 4rem
  
.job-properties
  display: flex
  flex-direction: column
  gap: 1rem

.job-divider
  width: 100%
  height: 1px
  background-color: $grey

.job-property
  margin: 0
  font-weight: 600

.job-description-con
  display: flex
  flex-direction: column
  gap: 2rem


.job-link
  text-transform: uppercase
  color: $red

// .separator-con, -title, red-separator from _separator.sass

// .jp-job-cards from _jobsPreview.sass

@media (min-width: $lg)
  .job-header
    width: 80%