.jp-hero-con
  position: relative
  height: 45vh
  display: flex
  flex-direction: column
  justify-content: center

.jp-hero-image-con
  position: absolute
  inset: 0

// .overlay-red is defined in _heroSlider

.jp-hero-content
  position: relative
  margin-left: 10vw

.jp-hero-title,
.jp-hero-subtitle
  margin: 0
  color: $white