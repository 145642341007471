.event-detail-intro
  margin-top: 3rem
  +clearfix

  h3.line 
    @include media-breakpoint-down(sm)
      display: block
  section 
    display: grid
    @include media-breakpoint-down(sm)
      display: block
    
  .topics-goals
    float: left

  ul
    p
      color: $default
      margin-bottom: 0

  .events
    background-color: #ffffff
    margin-bottom: $margin
    padding-bottom: 3rem
    color: $default

    .event-card-1
      background-color: #DADADA
      padding: 0rem 3rem
      @include media-breakpoint-down(sm)
        padding: 0rem 2rem

    .event-card-2
      background-color: #ECECEC
      padding: 0rem 3rem

    .event-card-3
      background-color: #DADADA
      padding: 0rem 3rem

    .event-speaker
      margin-left: 27px

    .event-group
      margin-left: 27px
      margin-top: 8px

    span.event-location
      margin-left: 23px
      display: inline-block
      margin-top: -24px

    .event-detail-container
      padding: 2rem 3rem 1rem 3rem
      @include media-breakpoint-down(sm)
        padding: 2rem 2rem 1rem 2rem

      i.fa-users-class
        font-size: 14.5px
      & > *
        margin-bottom: 2rem
      i.fa-user-friends
        font-size: 1.45rem

    .event-date-time
      font-size: 18px
      p
        font-weight: bold
        i
          margin-right: 8px
    
    .event-location-container
      p i
        margin-right: 8px
        min-width: 18px

    .event-link-intern
      text-align: center
      display: block
      float: unset
      background-color: #eca247
      color: $white
      text-decoration: none !important
      padding: 12px !important
      cursor: pointer
      margin: 0rem 3rem

      a
        color: #ffffff

    .product-logo
      height: 25px
      margin: 3rem
      @include media-breakpoint-down(sm)
        margin: 3rem 2rem

    hr
      border-bottom: 1px solid $mediumgrey
      &:last-of-type
        display: none

    h3
      &:not(:first-child)
        margin-top: $margin

    .card
      color: $default
      border-radius: 0
      border: none
      display: flex

      h4
        margin-bottom: 0

      .card-body
        padding: 2rem 0
        margin-bottom: -1rem

      .ptop0
        padding-top: 0px

      img
        margin-right: 1rem
        float: left
        position: relative
        border-radius: 50%
        filter: grayscale(100%)
        width: 70px
        height: 70px
        object-fit: cover

      .text-muted
        color: $default!important

      small
        font-size: 1.7rem

.event-side-overview-container
  display: inline-block
  float: right
  width: 35%
  padding: 0px 0px 2rem 2rem
  @include media-breakpoint-down(md)
    width: 45%
  @include media-breakpoint-down(sm)
    width: 100%
    padding: 1rem 0rem