.icon-list
  .icon-container
    text-align: center
    +font-size(3)
    display: flex
    align-items: center
    justify-content: center

.video iframe
  @include media-breakpoint-down(sm)
    padding-bottom: 1rem