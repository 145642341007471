.jobcard
  display: flex
  flex-direction: column
  gap: 1rem
  padding: 2.5rem 1.5rem
  border-bottom: 12px solid $red
  background-color: $light-grey

.jobcard-title
  font-weight: 500
  font-size: 2.1rem

.jobcard-state
  color: $red