.separator-con
  display: flex
  align-items: center
  gap: 2rem
  margin-bottom: 2rem

.separator-title
  margin-bottom: 0.5rem
  flex-shrink: 0
  max-width: 100%
  
.red-separator
  width: 100%
  height: 3px
  background-color: $red


@media (min-width: $md)
  .separator-title
    font-size: 2.66rem