.bvg-footer
  margin-bottom: 0

.bvg-footer a
  color: $white

.bvg-footer-chat
  position: absolute
  top: -10rem
  right: 0
