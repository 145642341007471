.support-overview, .content-support
  margin-bottom: 20px
  padding: 0 0 0 20px
  .press-tile
    margin-top: -30px

  .row
    margin: 0

    .col-md-6, .col-md-12
      
      &.press-overview-container
          margin-bottom: 15px

      &.press-tile
        padding-top: 20px
        padding-bottom: 20px
        .press-thumbnail-container
          @include media-breakpoint-down(sm)  
            display: none

          .background
            display: flex
            justify-content: center
            align-items: center
            width: 100%
            height: 100%
        .fal 
          color: $red
        .svg-fix
          width: 200px
          height: 200px
          background: #efefef
          padding: 20px
          @include media-breakpoint-down(md)  
            height: 150px

      .item-old
        background:
          color: $white
        border: none
          radius: 0
        color: $default
        display: block
        height: 100%
        text-decoration: none
        padding: 2.4rem

      .item
        background:
          color: $red
          position: -2rem -6rem
          repeat: no-repeat
          size: 30%
        border: none
          radius: 0
        color: $white
        display: block
        height: 100%
        text-decoration: none
        min-height: 215px
        padding: 3rem
        &.buchhandel
          background:
            image: url('../../../img/hilfe-buchhandel-icon.svg')
        &.empfehlungen
          background:
            image: url('../../../img/hilfe-empfehlungen-icon.svg')
        &.datenanlieferung
          background:
            image: url('../../../img/hilfe-datenanlieferung-icon.svg')
        &.datenbezug
          background:
            image: url('../../../img/hilfe-datenbezug-icon.svg')
        &.zis
          background:
            image: url('../../../img/hilfe-zis-icon.svg')
        &.neu
          background:
            image: url('../../../img/hilfe-neu-icon.svg')
        &.colorbg
          background:
            color: lighten($grey, 30%)
            image: url('../../../img/hilfe-kontakt-icon.svg')
          color: $default

        @include media-breakpoint-down(sm)
          height: auto
          min-height: 0

        h4
          margin-bottom: 2rem

        h2
          margin-top: 0

      &.press-tile
        .item
          padding: 0
          .container
            height: 100%
            padding: 0
            .row
              height: 100%
              > div:first-of-type
                background:
                  position: center center
                  repeat: no-repeat
                  size: cover
                padding: 0
              > div:last-of-type
                padding: 2.4rem 2.4rem 2.4rem calc(2.4rem + 15px)

      &.contact
        .item
          padding: 0
          .container
            height: 100%
            padding: 0
            .row
              height: 100%
              > div:first-of-type
                padding: 2.4rem 2.4rem 2.4rem calc(2.4rem + 15px)
              > div:last-of-type
                background:
                  position: center center
                  repeat: no-repeat
                  size: cover
                padding: 0
              .text-more-info
                position: absolute
                right: 10px
                bottom: 10px

.content-support:not(.support-overview)
  .row
    .col-md-6, .col-md-12
      .item
        background:
          image: none