$bgColor: $white
$dimColor: $black
$dimStrength: 0.3
$containerPadding: 4rem
$color: $default
  
.cc-revoke
  display: none

.cc-window
  width: 100vw
  height: 100vh
  position: fixed
  display: flex
  justify-content: center
  align-items: center
  top: 0
  left: 0
  z-index: 5000
  background-color: transparentize($black, $dimStrength)
  a
    color: $default
  &.cc-invisible
    display: none
  .container
    background-color: $bgColor
    position: relative
    width: auto
    display: flex
    padding: $containerPadding
    color: $color
    border: $red 1px solid
    max-width: 40%
    @include media-breakpoint-down(md)
      max-width: 70%
    @include media-breakpoint-down(sm)
      max-width: 95%
      padding: 1rem
      padding-top: 3rem
  .cc-compliance
    display: flex
    flex-direction: column-reverse
    justify-content: center
    align-items: center
    margin-top: 2rem
  .cc-deny
    display: block
    cursor: pointer
    position: absolute
    right: 2rem
    top: 1rem
    font-size: 1.4rem
    text-decoration: none
    color: $default
    &:hover
      color: $primary

body#body-datenschutz .cc-window
  display: none