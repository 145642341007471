.accordion

  .card
    border: none
    background: transparent
    width: 100%

    .card-header
      background-color: $white
      border-radius: 0
      border-bottom: 0
      margin-bottom: $margin / 2

      &:hover
        cursor: pointer

        h5
          color: $lightred

      h5
        margin-bottom: 0
        &:active
          background: none
          border: none
          box-shadow: none !important

        &:before
          content: url('plus.svg')
          padding-right: $padding / 2
          top: 0.25rem

      &.active
        h5
          &:before
            content: url('minus.svg')

    img.img-fluid
      margin-bottom: $margin / 2

    a
      //text-decoration: none
      //color: $default

    .card-employee
      position: relative

      .card-body
        background-color: $white
        padding: $padding

      .card-footer
        padding: 0
        background-color: transparent
        border-top: 0
        color: $grey

        img
          width: 20px
          margin-right: $margin / 3

    .card-apply
      background:
        color: $red
        image: url('../../img/person-placeholder-icon.svg')
        position: -1rem -4rem
        repeat: no-repeat
        size: 50%
      height: calc(100% - 2rem)
      position: relative
      @include media-breakpoint-down(sm)
        height: 100%

      .card-body
        position: absolute
        bottom: 0
        padding: $padding
        @include media-breakpoint-down(sm)
          position: inherit

        p, h4
          color: $white

        .text-muted
          color: $white !important

    //th, td
      //border: none
      //padding: $padding / 2
      //background-color: transparent

    .show
      margin-bottom: $margin / 2
      //border-bottom: 4px solid $white

  .flip
    .card
      margin-bottom: $margin
      .face
        .inner
          min-height: 376px
        &.front
          background-color: $white
        &.back
          background-color: $grey
          color: $white
          .inner
            padding: 1.6rem
            .card-footer
              padding: 0
              background-color: transparent
              border-top: 0
              color: $grey
              img
                width: 20px
                margin-right: $margin / 3
            a:hover
              color: $default
              cursor: pointer
