form 
  div.form-group
    button[type=submit]
      display: block
      width: 100%
      padding: 9PX
      background-color: $red
      color: #ffffff
      margin-top: 25px
      margin-bottom: 25px
      position: relative
      &.freeform-processing
        justify-content: center
      &::before
        position: absolute
        left: 10px
      &:hover
        background-color: $lightred

