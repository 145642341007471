nav#support-in-page-nav
  @include media-breakpoint-down(md)
    display: none

  text-align: center
  margin: $margin 10px $margin 0
  min-height: 30rem
  position: relative
  &:before
    content: ' '
    position: absolute
    right: 0
    top: 0
    bottom: 0
    width: 1px
    background-image: linear-gradient(to bottom, rgba($default, .3), rgba($default, 0))

  a.list-group-item
    color: $default
    text-decoration: none
    text-align: right
    background-color: transparent
    border: 0
    border-color: $red
    +transition()
    &.active
      border-right: $margin/2 solid $red
    &:hover
      background-color: rgba($red, .2)
    &.subheading
      font-size: .8em
      color: rgba($default,.8)
      &.active
        border-right: $margin/4 solid $red

#hilfe-content
  .sticky-top
    position: -webkit-sticky
    top: 10px
