.jobs-search-con
  background-color: $light-grey
  padding: .5rem 1rem
  margin-bottom: 2rem
  display: flex
  align-items: center
  gap: 2rem
  
.jobs-search-input
  border: none
  background-color: transparent
  width: 100%

.jobs-filter-con
  display: flex
  gap: 2rem
  flex-wrap: wrap
  display: grid
  grid-template-columns: 1fr 1fr
  margin-bottom: 6rem

.jobs-filter-con .dropdown-toggle
  background-color: $grey
  border: none
  color: $white
  font-weight: 100
  padding: .2rem 1.5rem
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%

.jobs-filter-con .dropdown-toggle::after
  display: none

.jobs-filter-con .dropdown-menu
  background-color: $grey
  color: $white
  font-weight: 100
  padding: 0 1.5rem
  width: 100%
  margin: 0
  border: none
  border-radius: 0
  top: unset !important
  height: fit-content
  transform: none !important

.dropdown-menu-divider
  width: 100%
  height: 1px
  background-color: $white
  margin-top: .2rem
  margin-bottom: .5rem
  opacity: 20%

.jobs-filter-con .dropdown-item
  display: flex
  align-items: center
  gap: 1rem
  padding: .5rem 0
  cursor: pointer
  border-right: 2px solid transparent 

.jobs-filter-con .dropdown-item:hover,
.jobs-filter-con .dropdown-item:focus,
.jobs-filter-con .dropdown-item:active
  background-color: transparent
  border-color: $white

.jobs-filter-con .dropdown-item input[type="checkbox"]
  /* Add if not using autoprefixer */
  -webkit-appearance: none
  appearance: none
  /* For iOS < 15 to remove gradient background */
  background-color: transparent
  /* Not removed via appearance */
  margin: 0
  width: 2rem
  aspect-ratio: 1 / 1
  border: .2rem solid $white
  border-radius: 99rem
  display: grid
  place-content: center

.jobs-filter-con .dropdown-item input[type="checkbox"]::before
  content: ''
  width: 0rem
  aspect-ratio: 1 / 1
  border-radius: 99rem
  background-color: $white

.jobs-filter-con .dropdown-item input[type="checkbox"]:checked::before
  width: 1rem

  

.jobs-filter-con .dropdown-item label
  margin-bottom: 0
  color: $white
  width: 100%
  cursor: pointer

#jobs
  display: grid
  gap: 2rem

@media (min-width: $sm)
  .jobs-filter-con
    grid-template-columns: 1fr 1fr 1fr
  
  #jobs
    grid-template-columns: 1fr 1fr

@media (min-width: $md)
  .jobs-filter-con
    grid-template-columns: 1fr 1fr 1fr 1fr
  
  #jobs
    grid-template-columns: 1fr 1fr 1fr

@media (min-width: $xl)
  .jobs-filter-con
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr