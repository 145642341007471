.subscriptions-options
  background-color: $white
  padding: $padding 0
  margin: 0 0 $margin

  strong
    display: block
    margin-bottom: 1em

  h4
    background-color: $red
    color: $white
    text-indent: 8px
    padding: $padding / 2
    display: block
    width: 100%
    @include media-breakpoint-down(xs)
      text-indent: 0
    i
      font-size: 14px
      font-weight: lighter

  .price
    span
      font-size: 3rem
      font-weight: 600
      display: block
      @include media-breakpoint-down(md)
        font-size: 2rem
  .form-check-label
    margin-bottom: $margin / 3
    padding-left: $padding * 1.5
    span
      display: block
    strong
      display: block

  .form-check-input
    margin-left: 0

  &.discount
    border: 2px solid $red
    padding: 0
    .price
      span
        color: $red
        font-size: 3rem
        font-weight: 600
        text-decoration: line-through
        display: block
        @include media-breakpoint-down(md)
          font-size: 2rem

  select
    background: url(./img/angle-down.svg) no-repeat 90% center $white
    background-size: 12px
    border-radius: 0
    border: 1px solid $lightgrey
    outline: 0
    padding: .8rem 3rem .8rem .8rem
    -webkit-appearance: none

  #new-calculated-cost
    color: black
    text-decoration: none
    margin-top: -32px