.meta-nav
  padding-top: 3rem
  padding-bottom: 4rem
  @include media-breakpoint-down(md)
    padding-top: 4rem
    padding-bottom: 3rem
  .col
    display: flex
    @include media-breakpoint-down(md)
      flex-basis: auto
      margin-bottom: 20px
      max-width: 50%
    @include media-breakpoint-down(xs)
      max-width: 100%
    h3
      +font-size(2)
      color: $white
      margin-bottom: 0
      text-align: center

    .box
      background-color: $red
      padding: 2.6rem 2.4rem 2.6rem 2.4rem
      width: 100%
      i
        padding-right: 1rem

    a
      display: block
      color: $white
      text-decoration: none

.meta-nav-buchjournal
  padding-top: 3rem
  padding-bottom: 4rem
  @include media-breakpoint-down(md)
    padding-top: 4rem
    padding-bottom: 3rem
  .col
    display: flex
    margin-bottom: 2.4rem
    min-width: 30%
    @include media-breakpoint-down(md)
      flex-basis: auto
      margin-bottom: 2.4rem
      max-width: 100%
    h3
      +font-size(2)
      color: $white
      margin-bottom: 0
      text-align: center

    .box
      background-color: $red
      padding: 2.6rem 2.4rem 2.6rem 2.4rem
      width: 100%
      i
        padding-right: 1rem

    a
      display: block
      color: $white
      text-decoration: none
