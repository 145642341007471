.image-gallery
  .image-text-overlay
    a
      text-decoration: none
      color: #3f3f3f
      .gallery-image-text
        display: block
        margin-top: 1rem
        margin-bottom: -0.5rem
  .gallery-image-copyright
    position: absolute
    transform: rotate(-90deg) translate(100%, 0%)
    right: 1em
    transform-origin: right

  .item
    margin-bottom: $margin*1.5

.lightbox

  .lb-container

    .lb-nav

      a.lb-prev
        background: url('../../../img/pfeil-links.svg') 2% 48% no-repeat
        &:hover, &:focus, &:active, &:visited
          background: url('../../../img/pfeil-links.svg') 2% 48% no-repeat

      a.lb-next
        background: url('../../../img/pfeil-rechts.svg') 98% 48% no-repeat
        &:hover, &:focus, &:active, &:visited
          background: url('../../../img/pfeil-rechts.svg') 98% 48% no-repeat

    .lb-image
      border: none
      border-radius: 0

  .lb-dataContainer

    .lb-data

      .lb-details

        .lb-caption
          font-size: 1.6rem
          line-height: 1.6
          font-weight: 500
          color: $white

        .lb-number
          color: $white

    .lb-closeContainer

      a
        background: url('../../../img/close-icon.svg') 100% 0 no-repeat
        width: 25px
        height: 25px

.lightboxOverlay
  background-color: $default
  opacity: .9
