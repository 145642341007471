.navigation-con
  display: flex
  justify-content: space-between
  align-items: center
  padding: 2rem

.navigation-logo
  width: 12rem

.navigation-items
  display: flex
  align-items: center
  gap: 2rem
  color: $grey

.navigation-link-items
  display: flex
  align-items: center
  gap: 2rem

a
  text-decoration: none
  color: $grey
  cursor: pointer

.dropdown-menu
  top: .5rem !important

.dropdown-item
  font-size: 1.7rem
  margin-bottom: .5rem

.navigation-search-icon-con
  background-color: $red
  display: flex
  padding: 1rem
  cursor: pointer

.navigation-lang-picker
  display: flex
  gap: .42rem

.navigation-lang-picker-active
  font-weight: 900

.navigation-mobile-nav-items
  display: flex
  flex-direction: column
  align-items: center
    
.navigation-mobile-nav-item
  margin-bottom: 2rem

.show-mobile-nav-items-btn
  border: 1px solid $red

@media (min-width: $md)
  .navigation-logo
    width: unset

  .navigation-search-icon-con
    padding: 1.33rem

    