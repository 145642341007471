.accordion .card .card-header
  background-color: $light-grey
  margin: 0
  padding: 0

.jp-brands-con .card-header button,
.collapsible-con .card-header button
  width: 100%
  display: flex
  gap: 2rem
  text-align: left
  justify-content: space-between
  align-content: center
  border: none
  color: $grey
  background-color: $light-grey
  padding: 1.5rem 1rem

.card-header button p
  font-weight: 600

.jp-brands-arrow
  transform: rotate(-90deg)

.collapsed .jp-brands-arrow
  transform: rotate(90deg)

.accordion .card .show
  margin: 0

.jp-brands-con .card-body
  padding: 0
  display: grid
  gap: 4rem
  padding: 3rem 0

.jp-brands-info-con
  display: flex
  flex-direction: column
  gap: 2rem

.jp-brands-social-media-con
  display: flex
  gap: 2rem

.jp-brands-social-media
  position: relative
  width: 3.5rem
  aspect-ratio: 1

.jp-brands-logo
  max-width: 200px
  width: 100%
  margin: 0 auto
  grid-row-start: 1
  position: relative
  .fill-picture
    position: relative !important

.image-contain
  object-fit: contain !important

@media (min-width: $md)
  .jp-brands-con .card-body
    gap: 4rem
    grid-template-columns: 6fr 1fr

  .jp-brands-logo
    grid-row-start: 1
    grid-column: 2/3
    .fill-picture
      position: absolute !important
