.jp-events-preview-con
  display: flex
  flex-direction: column
  gap: 4rem
  position: relative

.jp-events-mobile
  display: grid

.jp-event-mobile-card
  position: relative
  padding-bottom: 3rem

.jp-events-desktop
  display: none

.jp-events-preview-con p
  margin: 0

.jp-events-preview-con .card
  display: flex
  flex-direction: row
  overflow: visible !important
  position: unset !important

.jp-events-preview-con .card-header
  padding-bottom: 3rem !important
  background-color: transparent !important
  width: 233px
  position: relative
  opacity: 0.5

.jp-events-preview-con .card-header.active
  opacity: 1

.jp-event-indicator-con
  position: absolute
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  top: 1rem

.jp-event-indicator-circle
  width: .6rem
  aspect-ratio: 1 / 1
  border-radius: 99rem
  background-color: $grey
  position: absolute
  top: 0

.active .jp-event-indicator-circle  
  background-color: $red

.jp-event-indicator-line
  position: absolute
  top: .6rem
  bottom: 0
  width: 2px
  background-color: $light-grey

.jp-event-card-btn
  // display: flex
  // gap: 2rem
  background-color: transparent 
  border: none

.jp-event-card-btn-content-con
  display: flex
  flex-direction: column
  gap: 1rem
  text-align: left
  padding-left: 2.5rem

.jp-event-card-btn-content-header
  display: flex
  gap: 1rem

.active .jp-event-card-btn-content-header
  color: $red


.jp-event-card-subtitle
  font-weight: 400 !important

.jp-events-preview-con .collapse
  height: fit-content !important
  position: absolute !important
  top: 0 !important
  left: 280px !important
  right: 2rem !important

.jp-events-preview-con .collapsing
  position: absolute !important
  top: 0 !important
  left: 280px !important
  right: 2rem !important

.jp-events-preview-con .card-body
  padding: 0
  display: grid
  gap: 2rem
  grid-template-columns: 1fr 1fr

.jp-event-card-tile
  background-color: $light-grey
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 2.5rem
  padding: 2rem
  aspect-ratio: 1 / 1
  text-align: center

.jp-event-card-tile p
  font-weight: 700


@media (min-width: $md)
  .jp-events-mobile
    display: none

  .jp-events-desktop
    display: initial

@media (min-width: $lg)
  .jp-events-preview-con .card-body
    grid-template-columns: 1fr 1fr 1fr

@media (min-width: $xl)
  .jp-events-preview-con .card-body
    grid-template-columns: 1fr 1fr 1fr 1fr