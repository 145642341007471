.jp-con
  display: flex
  flex-direction: column
  gap: 3rem

.jp-job-cards
  display: grid
  gap: 2rem

@media (min-width: $sm) 
  .jp-job-cards
    grid-template-columns: 1fr 1fr

@media (min-width: $lg)
  .jp-job-cards
    grid-template-columns: 1fr 1fr 1fr
