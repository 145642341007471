.latest
  +clearfix
  h3.line
    margin-top: $margin*2

  .card-footer-link
    float: right

  .tablecontainer
    .search-input-container
      @include media-breakpoint-down(md)
        display: none
    .hidden
      display: none !important
    .item
      &.mobile-event
        @include media-breakpoint-down(md)
          padding-left: 0
          padding-right: 0

  .item
    margin-bottom: $margin*2

    &.event-box
      @include media-breakpoint-down(md)
        display: none

    a
      color: $default
      text-decoration: none

    .card
      border-radius: 0
      border: none
      height: 100%

      .product-logo
        height: auto
        width: auto
        margin: 10px 0px
        
    img
      height: 100%
      width: 100%

    .card-footer
      padding: 0 0 .75rem 0
      background-color: transparent
      border-top: 0
      margin: $margin 0
      color: $grey
      

      .date
        float: left

      .comments
        float: right
        @include media-breakpoint-down(md)
          float: left
          margin-right: 1rem

    &.mobile-event
      display: none
      @include media-breakpoint-down(md)
        display: block
        .card
          width: 100%
          height: auto
        .badge
          font-size: 12px
          padding: 7px
          position: relative
          top: auto
          right: auto
          left: auto
          bottom: auto
          margin-right: .5rem
          margin-bottom: .5rem
        .fa-external-link
          float: right
        .card-footer
          margin-bottom: 0
          margin-top: .5rem
        h5
          font-weight: normal
        .logo
          margin-bottom: 2rem

  .grid-item
    float: left
    margin-bottom: $margin
    width: calc((100% - 40px) / 3)
    @media (max-width: 768px)
      width: calc((100% - 40px) / 2)
    @media (max-width: 600px)
      width: calc((100% - 20px) / 2)

    a
      color: $default
      text-decoration: none

    .card
      border-radius: 0
      position: relative
      border: none

    img
      height: 100%
      width: 100%

    .card-footer
      padding: 0
      background-color: transparent
      border-top: 0
      margin: $margin 0
      color: $grey

      .date
        float: left

      .comments
        float: right
        @include media-breakpoint-down(md)
          float: left
          margin-right: 1rem


    .badge
      position: absolute
      top: 16px
      right: 0
      padding: 1rem
      border-radius: 0
      z-index: 1

      &.badge-webinars
        background-color: $rednew
        text-transform: uppercase
        font-weight: 500
        color: $white
        

      &.badge-seminars
        background-color: $trafficlightred
        text-transform: uppercase
        font-weight: 500
        color: $white
        

      &.badge-ontour
        background-color: $black
        text-transform: uppercase
        font-weight: 500
        color: $white
        

      &.badge-other
        background-color: $grey
        text-transform: uppercase
        font-weight: 500
        color: $white
        

      &.badge-tag
        background-color: $default
        font-weight: 500
        color: $white
        top: 51px



.latest.latest-products 
  .card-body 
    display: inline-block     

.badge
  position: absolute
  top: 16px
  right: 0
  padding: 1rem
  border-radius: 0
  z-index: 1

  i 
    margin-right: .5rem

  &.badge-webinars
    background-color: $rednew
    text-transform: uppercase
    font-weight: 500
    color: $white
    

  &.badge-seminars
    background-color: $trafficlightred
    text-transform: uppercase
    font-weight: 500
    color: $white
    

  &.badge-ontour
    background-color: $black
    text-transform: uppercase
    font-weight: 500
    color: $white
    

  &.badge-other
    background-color: $grey
    text-transform: uppercase
    font-weight: 500
    color: $white
    

  &.badge-tag
    background-color: $default
    font-weight: 500
    color: $white
    top: 51px


.btn-group-event-filter
  .disabled
    opacity: 1
  .btn:disabled
    opacity: 1  
  background-color: $rednew
  height: 40px
  margin-right: 15px
  margin-bottom: 10px
  margin-top: 10px
  width: 200px
  @include media-breakpoint-down(md)
    width: 100%
  button
    color: #ffffff 
    font-weight: bold
    &:hover
      color:#ffffff 
    &:after
      display: none
    .fa-filter
      float: right
      margin-top: 6px 
      font-size: 14px
  .btn-filter-name
    width: 200px
    text-align: left   
    @include media-breakpoint-down(md)
      width: 85%
  .dropdown-toggle
    width: 35px
    background-color: #EB5757
    &:hover
      background-color: $rednew  
  .dropdown-menu
    transform: translate3d(0px,38px,0px) !important   
    width: 235px
    @include media-breakpoint-down(md)  
      width: 100%
    li 
      height: auto
      min-height: 30px
      padding-left: 5px
      font-size: 14px
      padding-bottom: 10px
      a
        display: flex 
        text-decoration: none
        color: #000

        input
          margin-top: 3px 
  .caret-x 
    display: none

#myTable
  margin-bottom: 200px
