.jb-link-con
  display: inline-flex
  align-items: center
  gap: 1rem
  width: fit-content
  fill: red
  text-transform: uppercase
  font-weight: 500
  position: relative
  padding-right: 30px

  &::after
    position: absolute
    content: ''
    width: 25px
    height: 25px
    right: 0
    background-image: url('bvg/ctaArrow.svg')
    background-position: right
    background-repeat: no-repeat
    background-size: contain

.jb-link-con:hover,
.jb-link-con:focus,
.jb-link-con:active
  color: $grey
  text-decoration: underline $grey
