$white: white

// 50 Shades of your mom
$default: #3F3F3F
$mediumgrey: #545454
$grey: #949494
$darklightgrey: #cccccc
$mediumlightgrey: #dcdcdc
$lightgrey: #F3F3F3

$black: #404040

$lightgreen: #48c0b1
$green: #14AA96
$darkgreen: #0C6054
$red: #E2001A

$rednew: #E3001B
$lightrednew : #e2001a

$lightred: #E64155
$yellow: #FFC800
$darkyellow: #937300
$orange: #FF8C00
$lightblue: #00A5E1
$darkblue: #005AB4

$turquoisish: #d1ecf1
$darkturqoisish: #0c5460

$trafficlightgreen: #6FCF97
$trafficlightyellow: #F2C94C
$trafficlightred: #EB5757