.jp-contact-hero-con
  position: relative
  min-height: 250px
  color: $white
  padding: 3rem

.jp-contact-hero-con h1
  margin: 0

.jp-contact-hero-con a
  color: $white
  align-self: flex-start
  display: inline-flex
  align-items: center
  gap: 1rem
  width: fit-content
  fill: red
  text-transform: uppercase
  font-weight: 500

.jp-contact-hero-con a:hover,
.jp-contact-hero-con a:focus,
.jp-contact-hero-con a:active
  color: $grey
  text-decoration: underline $grey

.jp-contact-hero-bg-img
  position: absolute
  inset: 0

.jp-contact-hero-content
  position: relative
  display: flex
  justify-content: space-between
  gap: 4rem
  flex-wrap: wrap

.jp-social-media-con
  transform: translateX(-0.8rem)

.jp-social-media-con svg
  transform: scale(0.6)

@media (min-width: $lg)
  .jp-contact-hero-con
    padding: 4rem
