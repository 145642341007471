.partner
  margin-top: 6rem
  margin-bottom: 6rem
  overflow: hidden
  @media (max-width: 767px)
    margin-bottom: 2rem

  .container
    @media (max-width: 767px)
      max-height: 110vw

  .card
    border-radius: 0
    border: none
    display: flex
    margin-bottom: 2rem

  .card-body
    padding: 1rem
    .card-title
      margin-left: 3px
    .btn
      @include media-breakpoint-down(md)
        letter-spacing: normal
      @include media-breakpoint-down(sm)
        letter-spacing: .1em
      i
        @include media-breakpoint-down(md)
          display: none
        @include media-breakpoint-down(sm)
          display: inline

  h3
    margin-bottom: 1.6rem

  .img-wrapper
    margin: 0 auto
    align-items: center
    display: flex
    flex-direction: row
    justify-content: center
    height: 8rem
    @include media-breakpoint-up(sm)
      height: 10rem

    img
      width: auto
      max-height: 46px
      max-width: 95%
    a
      text-decoration: none
    p
      font-weight: bold
      font-size: 18px
      color: #3f3f3f
      margin: 0 auto
      text-align: center


#clientexpander
  background-color: $red
  color: white
  display: none
  height: 35px
  padding: 0
  @media (max-width: 767px)
    display: block
    margin: 0 auto 6rem auto
    width: calc(100% - 2.6rem)
  &.gradient
    &:before
      background: rgb(255,255,255)
      background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 50%)
      background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 50%)
      background: linear-gradient(0deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 50%)
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1)
      content: ''
      height: 100px
      position: absolute
      left: 0
      top: -103px
      width: 100%
  i
    font-size: 2rem
    font-weight: 100
    line-height: 35px
