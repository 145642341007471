.jp-event-con
  display: flex
  flex-direction: column
  gap: 4rem
  
.jp-event-tiles
  display: grid
  gap: 2rem


@media (min-width: $sm)
  .jp-event-tiles
    grid-template-columns: 1fr 1fr

@media (min-width: $md)
  .jp-event-tiles
    grid-template-columns: 1fr 1fr 1fr

@media (min-width: $lg)
  .jp-event-tiles
    grid-template-columns: 1fr 1fr 1fr 1fr